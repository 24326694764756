import { MODULE_IDS } from "../../constants/enums";

import { APP_STORE_MANAGEMENT_ROUTE } from "./Store.constants";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { lazy } from "react";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";

const StoreListTable = Loadable(
  lazy(() =>
    import("./StoreDetailsList").then(({ StoreDetailsList }) => ({
      default: StoreDetailsList,
    })),
  ),
);
const ManageSingleSingleStore = Loadable(
  lazy(() =>
    import("./ManageSingleStoreDetails").then(
      ({ ManageSingleStoreDetails }) => ({
        default: ManageSingleStoreDetails,
      }),
    ),
  ),
);

export const storeManagementRoutes = [
  {
    path: APP_STORE_MANAGEMENT_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.STORE]}>
        <StoreListTable />
      </AuthorizedRoute>
    ),
  },
  // {
  //   path: `${APP_STORE_MANAGEMENT_ROUTE}/manage`,
  //   exact: true,
  //   element: (
  //     <AuthorizedRoute moduleIds={[MODULE_IDS.STORE]}>
  //       <ManageSingleSingleStore />
  //     </AuthorizedRoute>
  //   ),
  // },
  // {
  //   path: `${APP_STORE_MANAGEMENT_ROUTE}/manage/:uuid`,
  //   exact: true,
  //   element: (
  //     <AuthorizedRoute moduleIds={[MODULE_IDS.STORE]}>
  //       <ManageSingleSingleStore />
  //     </AuthorizedRoute>
  //   ),
  // },
];
