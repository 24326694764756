import { action } from "typesafe-actions";
import { IQueryParams, ISearchQueryParams } from "../../common/common.types";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getSearchQuery, getSearchQueryV2 } from "../../common/helpers";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import { IProductFiberFinish } from "./fiber-finish.types";

export const FETCH_PRODUCT_FIBER_FINISH_LIST_PROGRESS =
  "FETCH_PRODUCT_FIBER_FINISH_LIST_PROGRESS";
export const FETCH_PRODUCT_FIBER_FINISH_LIST_SUCCESS =
  "FETCH_PRODUCT_FIBER_FINISH_LIST_SUCCESS";
export const FETCH_PRODUCT_FIBER_FINISH_LIST_FAILED =
  "FETCH_PRODUCT_FIBER_FINISH_LIST_FAILED";

export const fetchFiberFinishListForProductProgress = () =>
  action(FETCH_PRODUCT_FIBER_FINISH_LIST_PROGRESS);
export const fetchFiberFinishListForProductSuccess = (
  data: IProductFiberFinish[],
  totalRecords: number,
) => action(FETCH_PRODUCT_FIBER_FINISH_LIST_SUCCESS, { data, totalRecords });
export const fetchFiberFinishListForProductFailed = () =>
  action(FETCH_PRODUCT_FIBER_FINISH_LIST_FAILED);

export const fetchFiberFinishListForProductAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchFiberFinishListForProductProgress());
      const res = await api.get(`/it/get-fiber-finish?${searchQuery}`);

      const data: IProductFiberFinish[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchFiberFinishListForProductSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchFiberFinishListForProductFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleFiberFinishFroProductAsync =
  (
    template: IProductFiberFinish,
    onCallback: (isSuccess: boolean, data?: IProductFiberFinish) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        created_by_name,
        modified_by_uuid,
        modified_by_name,
        ...payload
      } = template;

      const res = await api.post("/it/upsert-fiber-finish", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Product Addon saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PRODUCT_FIBER_FINISH =
  "CLEAR_SINGLE_PRODUCT_FIBER_FINISH";
export const CLEAR_PRODUCT_FIBER_FINISH_LIST =
  "CLEAR_PRODUCT_FIBER_FINISH_LIST";

export const clearSingleProductFiberFinishAsync = () =>
  action(CLEAR_SINGLE_PRODUCT_FIBER_FINISH);
export const clearProductFiberFinishListSync = () =>
  action(CLEAR_PRODUCT_FIBER_FINISH_LIST);
