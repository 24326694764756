import { LoadState } from "../../../constants/enums";
import { defaultEnquiry } from "../../enquiries/enquiry.state";
import { defaultProformaInvoice } from "../../quote/defaultState";
import { IOrder, IOrderState } from "./orders.types";

export const defaultOrder: IOrder = {
  ...defaultProformaInvoice,
  order_uuid: "",
};

export const defaultOrderState: IOrderState = {
  multiple_Orders: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  single_order: {
    loading: LoadState.NotLoaded,
    data: defaultOrder,
    error: null,
  },
  multiple_approved_Orders: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
};
