import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IInternalOrderBom,
  IInternalOrderBomState,
} from "./internal-orders-bom.types";
import {
  SERVER_GET_INTERNAL_ORDER_BOM_ROUTE,
  SERVER_UPSERT_INTERNAL_ORDER_BOM_ROUTE,
} from "./internal-orders-bom.constants";

export const FETCH_INTERNAL_ORDER_BOM_LIST_PROGRESS =
  "FETCH_INTERNAL_ORDER_BOM_LIST_PROGRESS";
export const FETCH_INTERNAL_ORDER_BOM_LIST_SUCCESS =
  "FETCH_INTERNAL_ORDER_BOM_LIST_SUCCESS";
export const FETCH_INTERNAL_ORDER_BOM_LIST_FAILED =
  "FETCH_INTERNAL_ORDER_BOM_LIST_FAILED";

export const fetchInternalOrderBomListProgress = () =>
  action(FETCH_INTERNAL_ORDER_BOM_LIST_PROGRESS);

export const fetchInternalOrderBomListSuccess = (
  data: IInternalOrderBomState["multiple_internal_orders_list"]["data"],
  totalRecords: number,
) => action(FETCH_INTERNAL_ORDER_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchInternalOrderBomListFailed = () =>
  action(FETCH_INTERNAL_ORDER_BOM_LIST_FAILED);

export const fetchInternalOrderBomListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      let finalUrl = `${SERVER_GET_INTERNAL_ORDER_BOM_ROUTE}?${searchQuery}`;
      dispatch(fetchInternalOrderBomListProgress());
      const res = await api.get(finalUrl);

      const totalRecords = res.data.totalRecords;
      const finalData: IInternalOrderBomState["multiple_internal_orders_list"]["data"] =
        res.data.data;

      dispatch(fetchInternalOrderBomListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchInternalOrderBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// export const fetchInternalOrderBomApprovalsListAsync =
//   (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       const searchQuery = getSearchQuery(queryParams);
//       let finalUrl = `/approval/get-approval${searchQuery}&table_name=latest_mrp_allocation&status=REQUESTED`;
//       dispatch(fetchInternalOrderBomListProgress());
//       const res = await api.get(finalUrl);
//       const data: IInternalOrderBomState["multiple_internal_orders_list"]["data"] =
//         res.data.data;

//       const totalRecords = res.data.totalRecords;

//       dispatch(fetchInternalOrderBomListSuccess(data, totalRecords));
//     } catch (err: any) {
//       dispatch(fetchInternalOrderBomListFailed());
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     }
//   };

export const FETCH_INTERNAL_ORDER_BOM_PROGRESS =
  "FETCH_INTERNAL_ORDER_BOM_PROGRESS";
export const FETCH_INTERNAL_ORDER_BOM_SUCCESS =
  "FETCH_INTERNAL_ORDER_BOM_SUCCESS";
export const FETCH_INTERNAL_ORDER_BOM_FAILED =
  "FETCH_INTERNAL_ORDER_BOM_FAILED";

export const fetchInternalOrderBomProgress = () =>
  action(FETCH_INTERNAL_ORDER_BOM_PROGRESS);
export const fetchInternalOrderBomSuccess = (data: IInternalOrderBom) =>
  action(FETCH_INTERNAL_ORDER_BOM_SUCCESS, { data });
export const fetchInternalOrderBomFailed = (errorMessage: string) =>
  action(FETCH_INTERNAL_ORDER_BOM_FAILED, { errorMessage });

export const fetchSingleInternalOrderBomAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInternalOrderBomProgress());
      const res = await api.get(
        `${SERVER_GET_INTERNAL_ORDER_BOM_ROUTE}?internal_order_bom_uuid=${uuid}`,
      );
      const data: IInternalOrderBom[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchInternalOrderBomSuccess(data[0]));
      } else {
        dispatch(
          fetchInternalOrderBomFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchInternalOrderBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleInternalOrderBomAsync =
  (
    data: IInternalOrderBom,
    onCallback: (isSuccess: boolean, deviation?: IInternalOrderBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(
        SERVER_UPSERT_INTERNAL_ORDER_BOM_ROUTE,
        payload,
      );
      let message = "Internal Order Bom saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const createInternalOrderBySodNumberAsync =
  (
    sod_number: string,
    onCallback: (isSuccess: boolean, deviation?: IInternalOrderBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_INTERNAL_ORDER_BOM_ROUTE, {
        sod_no: sod_number,
      });
      let message = "Internal Order saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_INTERNAL_ORDER_BOM =
  "CLEAR_SINGLE_INTERNAL_ORDER_BOM";
export const CLEAR_INTERNAL_ORDER_BOM_STATE = "CLEAR_INTERNAL_ORDER_BOM_STATE";
export const clearSingleInternalOrderBomSync = () =>
  action(CLEAR_SINGLE_INTERNAL_ORDER_BOM);
export const clearInternalOrderBomStateSync = () =>
  action(CLEAR_INTERNAL_ORDER_BOM_STATE);
