import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../constants/enums";
import { DRAWING_ITEM_PREFIX_ROUTE } from "./Drawingtem.constant";

const PartItemPrefixTableView = Loadable(
  lazy(() =>
    import("./DrawingItemPrefixTableView").then(
      ({ DrawingItemPrefixTableView }) => ({
        default: DrawingItemPrefixTableView,
      }),
    ),
  ),
);

export const drawingItemPrefixRoutes = [
  {
    path: DRAWING_ITEM_PREFIX_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING_PREFIX]}>
        <PartItemPrefixTableView />
      </AuthorizedRoute>
    ),
  },
];
