import produce from "immer";

import {
  CLEAR_INTERNAL_ORDER_BOM_STATE,
  CLEAR_SINGLE_INTERNAL_ORDER_BOM,
  FETCH_INTERNAL_ORDER_BOM_FAILED,
  FETCH_INTERNAL_ORDER_BOM_LIST_FAILED,
  FETCH_INTERNAL_ORDER_BOM_LIST_PROGRESS,
  FETCH_INTERNAL_ORDER_BOM_LIST_SUCCESS,
  FETCH_INTERNAL_ORDER_BOM_PROGRESS,
  FETCH_INTERNAL_ORDER_BOM_SUCCESS,
} from "./internal-orders-bom.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultInternalOrderBom,
  defaultInternalOrderBomState,
  IInternalOrderBomActions,
} from ".";

export const internalOrderBomReducer = (
  state: IStoreState["internalOrderBom"] = defaultInternalOrderBomState,
  action: IInternalOrderBomActions,
) => {
  switch (action.type) {
    case FETCH_INTERNAL_ORDER_BOM_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_internal_orders_list.loading = LoadState.InProgress;
        draftState.multiple_internal_orders_list.data = [];
        draftState.multiple_internal_orders_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_INTERNAL_ORDER_BOM_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_internal_orders_list.loading = LoadState.Loaded;
        draftState.multiple_internal_orders_list.data = data;
        draftState.multiple_internal_orders_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_INTERNAL_ORDER_BOM_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_internal_orders_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_INTERNAL_ORDER_BOM_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_internal_order_bom.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_INTERNAL_ORDER_BOM_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_internal_order_bom.loading = LoadState.Loaded;
        draftState.single_internal_order_bom.data = data;
      });
      return newState;
    }
    case FETCH_INTERNAL_ORDER_BOM_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_internal_order_bom.loading = LoadState.Failed;
        draftState.single_internal_order_bom.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_SINGLE_INTERNAL_ORDER_BOM: {
      const newState = produce(state, (draftState) => {
        draftState.single_internal_order_bom.loading = LoadState.NotLoaded;
        draftState.single_internal_order_bom.data = defaultInternalOrderBom;
        draftState.single_internal_order_bom.error = null;
      });
      return newState;
    }

    case CLEAR_INTERNAL_ORDER_BOM_STATE: {
      return defaultInternalOrderBomState;
    }

    default: {
      return state;
    }
  }
};
