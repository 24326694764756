import { action } from "typesafe-actions";
import { IPartItem, IPartItemState, IUploadPartItem } from "./partItem.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";

export const FETCH_PART_ITEM_LIST_PROGRESS = "FETCH_PART_ITEM_LIST_PROGRESS";
export const FETCH_PART_ITEM_LIST_SUCCESS = "FETCH_PART_ITEM_LIST_SUCCESS";
export const FETCH_PART_ITEM_LIST_FAILED = "FETCH_PART_ITEM_LIST_FAILED";

export const fetchPartItemListProgress = () =>
  action(FETCH_PART_ITEM_LIST_PROGRESS);
export const fetchPartItemListSuccess = (
  list: IPartItem[],
  totalRecords: number,
) => action(FETCH_PART_ITEM_LIST_SUCCESS, { list, totalRecords });
export const fetchPartItemListFailed = () =>
  action(FETCH_PART_ITEM_LIST_FAILED);

export const fetchPartItemListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchPartItemListProgress());
      const res = await api.get(`/bom/get-part-item?${searchQuery}`);
      const data: IPartItem[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchPartItemListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPartItemListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertPartItemAsync =
  (
    data: IPartItem,
    onCallback: (isSuccess: boolean, data?: IPartItem) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        part_code,
        combined_part_code,
        isChecked,
        ...payload
      } = data;
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/upsert-part-item", payload);
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Item saved successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_PART_ITEM = "CLEAR_PART_ITEM";
export const clearPartItemAsync = () => action(CLEAR_PART_ITEM);
export const CLEAR_PART_ITEM_STATE = "CLEAR_PART_ITEM_STATE";
export const clearPartItemListAsync = () => action(CLEAR_PART_ITEM_STATE);

export const uploadPartItemsFormExcelAsync =
  (
    partItems: IUploadPartItem[],
    onCallback: (isSuccess: boolean, data?: IUploadPartItem[]) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post("/it/upload-part-item-via-excel", {
        part_item_details: partItems,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Part Item uploaded successfully",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data.part_item_details);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: "error",
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
