import { action } from "typesafe-actions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IDrawingitemPrefix } from "./drawing-prefix.types";
import {
  SERVER_GET_DRAWING_ITEM_PREFIX_ROUTE,
  SERVER_UPSERT_DRAWING_ITEM_PREFIX_ROUTE,
} from "./drawing-prefix.router";

export const FETCH_DRAWING_ITEM_PREFIX_LIST_PROGRESS =
  "FETCH_DRAWING_ITEM_PREFIX_LIST_PROGRESS";
export const FETCH_DRAWING_ITEM_PREFIX_LIST_SUCCESS =
  "FETCH_DRAWING_ITEM_PREFIX_LIST_SUCCESS";
export const FETCH_DRAWING_ITEM_PREFIX_LIST_FAILED =
  "FETCH_DRAWING_ITEM_PREFIX_LIST_FAILED";

export const fetchDrawingitemPrefixProgress = () =>
  action(FETCH_DRAWING_ITEM_PREFIX_LIST_PROGRESS);
export const fetchDrawingitemPrefixSuccess = (
  data: IDrawingitemPrefix[],
  totalRecords: number,
) => action(FETCH_DRAWING_ITEM_PREFIX_LIST_SUCCESS, { data, totalRecords });
export const fetchDrawingitemPrefixFailed = () =>
  action(FETCH_DRAWING_ITEM_PREFIX_LIST_FAILED);

export const fetchDrawingitemPrefixAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchDrawingitemPrefixProgress());
      const res = await api.get(
        `${SERVER_GET_DRAWING_ITEM_PREFIX_ROUTE}?${searchQuery}`,
      );

      const data: IDrawingitemPrefix[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchDrawingitemPrefixSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchDrawingitemPrefixFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleDrawingitemPrefixAsync =
  (
    template: IDrawingitemPrefix,
    onCallback: (isSuccess: boolean, data?: IDrawingitemPrefix) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        created_by_name,
        modified_by_uuid,
        modified_by_name,
        ...payload
      } = template;

      const res = await api.post(
        SERVER_UPSERT_DRAWING_ITEM_PREFIX_ROUTE,
        payload,
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Drawing Item Prefix saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_DRAWING_ITEM_PREFIX =
  "CLEAR_SINGLE_DRAWING_ITEM_PREFIX";
export const CLEAR_DRAWING_ITEM_PREFIX_LIST = "CLEAR_DRAWING_ITEM_PREFIX_LIST";

export const clearSingleDrawingitemPrefixAsync = () =>
  action(CLEAR_SINGLE_DRAWING_ITEM_PREFIX);
export const clearDrawingitemPrefixListSync = () =>
  action(CLEAR_DRAWING_ITEM_PREFIX_LIST);
