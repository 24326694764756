import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  showMessage,
  saveLoaderProgress,
  saveLoaderCompleted,
} from "../messages/messagesActions";
import { IStoreList } from "./store.types";
import {
  SERVER_GET_STORE_ROUTE,
  SERVER_UPSERT_STORE_ROUTE,
} from "./store.routes";

export const FETCH_STORE_LIST_PROGRESS = "FETCH_STORE_LIST_PROGRESS";
export const FETCH_STORE_LIST_SUCCESS = "FETCH_STORE_LIST_SUCCESS";
export const FETCH_STORE_LIST_FAILED = "FETCH_STORE_LIST_FAILED";

export const fetchStoreListProgress = () => action(FETCH_STORE_LIST_PROGRESS);
export const fetchStoreListSuccess = (
  data: IStoreList[],
  totalRecords: number,
) => action(FETCH_STORE_LIST_SUCCESS, { data, totalRecords });
export const fetchStoreListFailed = () => action(FETCH_STORE_LIST_FAILED);

export const fetchStoreListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchStoreListProgress());
      const res = await api.get(`${SERVER_GET_STORE_ROUTE}?${searchQuery}`);
      dispatch(fetchStoreListSuccess(res.data.data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchStoreListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SINGLE_STORE_PROGRESS = "FETCH_SINGLE_STORE_PROGRESS";
export const FETCH_SINGLE_STORE_SUCCESS = "FETCH_SINGLE_STORE_SUCCESS";
export const FETCH_SINGLE_STORE_FAILED = "FETCH_SINGLE_STORE_FAILED";

export const fetchSingleStoreProgress = () =>
  action(FETCH_SINGLE_STORE_PROGRESS);
export const fetchSingleStoreSuccess = (data: IStoreList) =>
  action(FETCH_SINGLE_STORE_SUCCESS, { data });
export const fetchSingleStoreFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_STORE_FAILED, { errorMessage });

export const fetchSingleStoreAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchSingleStoreProgress());
      const res = await api.get(`${SERVER_GET_STORE_ROUTE}?store_uuid=${uuid}`);
      const data: IStoreList[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleStoreSuccess(data[0]));
      } else {
        dispatch(fetchSingleStoreFailed("No store found"));
      }
    } catch (err: any) {
      dispatch(fetchSingleStoreFailed("Failed to fetch store"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertStoreAsync =
  (
    data: IStoreList,
    onCallback: (isSuccess: boolean, store?: IStoreList) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        create_ts,
        created_by_name,
        created_by_uuid,
        modified_by_name,
        modified_by_uuid,
        insert_ts,
        isChecked,
        ...rest
      } = data;
      const res = await api.post(SERVER_UPSERT_STORE_ROUTE, rest);
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Store saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_STORE = "CLEAR_STORE";
export const CLEAR_STORE_STATE = "CLEAR_STORE_STATE";
export const clearStoreSync = () => action(CLEAR_STORE);
export const clearStoreStateSync = () => action(CLEAR_STORE_STATE);
