import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_DRAFT_STATE,
  CLEAR_SINLE_DRAFT_STATE_STATE,
  deafaultDraftState,
  defaultDraft,
  DraftActions,
  FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_FAILED,
  FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_PROGRESS,
  FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_DRAFT_FOR_USER_FAILED,
  FETCH_SINGLE_DRAFT_FOR_USER_PROGRESS,
  FETCH_SINGLE_DRAFT_FOR_USER_SUCCESS,
} from ".";

export const draftsReducer = (
  state: IStoreState["drafts"] = deafaultDraftState,
  action: DraftActions,
) => {
  switch (action.type) {
    case FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_drafts.loading = LoadState.InProgress;
        draftState.multiple_drafts.data = [];
        draftState.multiple_drafts.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_drafts.loading = LoadState.Loaded;
        draftState.multiple_drafts.data = data;
        draftState.multiple_drafts.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_drafts.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_SINGLE_DRAFT_FOR_USER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_draft.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_DRAFT_FOR_USER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_draft.loading = LoadState.Loaded;
        draftState.single_draft.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_DRAFT_FOR_USER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_draft.loading = LoadState.Failed;
        draftState.single_draft.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_SINLE_DRAFT_STATE_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.single_draft.loading = LoadState.NotLoaded;
        draftState.single_draft.data = defaultDraft;
        draftState.single_draft.error = null;
      });
      return newState;
    }

    case CLEAR_DRAFT_STATE: {
      return deafaultDraftState;
    }

    default: {
      return state;
    }
  }
};
