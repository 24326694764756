import { LoadState } from "../../constants/enums";
import {
  IDrawingitemPrefix,
  IDrawingitemPrefixState,
} from "./drawing-prefix.types";

export const defaultDrawingitemPrefix: IDrawingitemPrefix = {
  prefix_uuid: "",
  prefix_name: "",
  description: "",
  status: "ACTIVE",
};

export const defaultDrawingitemPrefixState: IDrawingitemPrefixState = {
  multiple_drawing_item_state: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_drawing_item_state: {
    data: defaultDrawingitemPrefix,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
