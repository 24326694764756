import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  APP_ASSEMBLY_MANAGE_ROUTE,
  APP_ASSEMBLY_ROUTE,
} from "./assembly.constant";

const AssemblyList = Loadable(
  lazy(() =>
    import("./AssemblyTable").then(({ AssemblyTable }) => ({
      default: AssemblyTable,
    })),
  ),
);

const AssemblyManage = Loadable(
  lazy(() =>
    import("./ManageAssembly").then(({ ManageAssembly }) => ({
      default: ManageAssembly,
    })),
  ),
);

export const assemblyRoutes = [
  {
    path: APP_ASSEMBLY_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ASSEMBLY]}>
        <AssemblyList />
      </AuthorizedRoute>
    ),
  },
  {
    path: APP_ASSEMBLY_MANAGE_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ASSEMBLY]}>
        <AssemblyManage />
      </AuthorizedRoute>
    ),
  },
  {
    path: APP_ASSEMBLY_MANAGE_ROUTE + "/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ASSEMBLY]}>
        <AssemblyManage />
      </AuthorizedRoute>
    ),
  },
];
