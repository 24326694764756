import React from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { CustomDatePicker } from "../../../formsComponents/CustomDatePicker";
import moment from "moment";
import { api } from "../../../../api/api";
import { Dialog } from "../../../Dialogs/Dialog";

interface IFieldType {
  [key: string]: string;
}

interface IApiResponse {
  data: {
    [key: string]: string;
  };
}

interface IFilterRow {
  id: string;
  column: string;
  value: any;
}

interface IAdvancedFilterDialogProps {
  open: boolean;
  onClose: () => void;
  onApply: (filters: any) => void;
  tableName: string;
}

export const AdvancedFilterDialog: React.FC<IAdvancedFilterDialogProps> = ({
  open,
  onClose,
  onApply,
  tableName,
}) => {
  const [fields, setFields] = React.useState<IFieldType>({});
  const [filterRows, setFilterRows] = React.useState<IFilterRow[]>([
    { id: "1", column: "", value: "" },
  ]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      fetchFieldTypes();
    }
  }, [open, tableName]);

  const fetchFieldTypes = async () => {
    try {
      setLoading(true);
      const response = await api.get<IApiResponse>(
        `/general/get-table-fields-and-type?table_name=${tableName}`,
      );
      const data = response.data;
      if (data.data) {
        const filteredFields = Object.entries(data.data).reduce<IFieldType>(
          (acc, [key, value]) => {
            if (
              !key.toLowerCase().includes("uuid") &&
              ![
                "create_ts",
                "insert_ts",
                "created_by",
                "insert_by",
                "created_by_name",
                "created_by_uuid",
                "modified_by_name",
                "modified_by_uuid",
              ].includes(key)
            ) {
              acc[key] = value;
            }
            return acc;
          },
          {},
        );
        setFields(filteredFields);
      }
    } catch (error) {
      console.error("Error fetching field types:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRow = () => {
    setFilterRows([
      ...filterRows,
      { id: Date.now().toString(), column: "", value: "" },
    ]);
  };

  const handleRemoveRow = (id: string) => {
    if (filterRows.length > 1) {
      setFilterRows(filterRows.filter((row) => row.id !== id));
    }
  };

  const handleColumnChange = (id: string, column: string) => {
    setFilterRows(
      filterRows.map((row) =>
        row.id === id ? { ...row, column, value: "" } : row,
      ),
    );
  };

  const handleValueChange = (id: string, value: any) => {
    setFilterRows(
      filterRows.map((row) => (row.id === id ? { ...row, value } : row)),
    );
  };

  const handleClear = () => {
    setFilterRows([{ id: "1", column: "", value: "" }]);
    onApply([]);
  };

  const handleApply = () => {
    const filters = filterRows
      .filter((row) => row.column && row.value !== "")
      .map((row) => {
        const fieldType = fields[row.column];
        const operator =
          fieldType?.includes("datetime") ||
          fieldType?.includes("int") ||
          fieldType?.includes("decimal") ||
          fieldType?.includes("float")
            ? "EQUAL"
            : "CONTAINS";

        return {
          column: [row.column],
          operator,
          value: row.value,
          logicalOperator: "AND",
        };
      });

    onApply(filters);
    onClose();
  };

  const renderValueField = (row: IFilterRow) => {
    const fieldType = fields[row.column];
    if (!fieldType) return null;

    if (fieldType.includes("datetime")) {
      return (
        <CustomDatePicker
          value={row.value || ""}
          fullWidth
          sx={{
            "& .MuiInputBase-root": {
              padding: "8px 16px",
            },
          }}
          onChange={(newValue) =>
            handleValueChange(
              row.id,
              newValue ? moment(newValue).format("YYYY-MM-DD") : "",
            )
          }
          fieldName=""
        />
      );
    }

    if (
      fieldType.includes("int") ||
      fieldType.includes("decimal") ||
      fieldType.includes("float")
    ) {
      return (
        <TextField
          fullWidth
          type="number"
          value={row.value}
          onChange={(e) => handleValueChange(row.id, e.target.value)}
        />
      );
    }

    return (
      <TextField
        fullWidth
        value={row.value}
        onChange={(e) => handleValueChange(row.id, e.target.value)}
      />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="md"
      title="Advance Filter"
      actions={[
        {
          label: "Clear",
          onClick: handleClear,
          variant: "outlined",
          type: "button",
        },
        {
          label: "Cancel",
          onClick: onClose,
          variant: "outlined",
          type: "button",
        },
        {
          label: "Apply Filters",
          onClick: handleApply,
          variant: "contained",
          type: "button",
        },
      ]}
    >
      <Box sx={{ mt: 2 }}>
        {filterRows.map((row, index) => (
          <Grid container spacing={2} key={row.id} sx={{ mb: 2 }}>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel>Column</InputLabel>
                <Select
                  value={row.column}
                  label="Column"
                  onChange={(e) => handleColumnChange(row.id, e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select a column</em>
                  </MenuItem>
                  {Object.entries(fields).map(([fieldName, fieldType]) => (
                    <MenuItem key={fieldName} value={fieldName}>
                      {fieldName
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase())}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              {renderValueField(row)}
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              {filterRows.length > 1 && (
                <IconButton
                  color="error"
                  onClick={() => handleRemoveRow(row.id)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddRow}
          variant="outlined"
          sx={{ mt: 2 }}
        >
          Add Filter
        </Button>
      </Box>
    </Dialog>
  );
};
