import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../../constants/enums";
import { PROFORMA_INVOICE_ROUTE } from "./ProformaInvoices.constants";
import { appNavRoutes } from "../../../constants/appNavRoutes";

const ProformaInvoicesTable = Loadable(
  lazy(() =>
    import("./ProformaInvoicesTable").then(({ ProformaInvoicesTable }) => ({
      default: ProformaInvoicesTable,
    })),
  ),
);
const ManageSingleProformaInvoice = Loadable(
  lazy(() =>
    import("./ManageSingleProformaInvoice").then(
      ({ ManageSingleProformaInvoice }) => ({
        default: ManageSingleProformaInvoice,
      }),
    ),
  ),
);

const proformaRoute = appNavRoutes.sales.proformaInvoice;
export const proformaInvoicesRoutes = [
  {
    path: proformaRoute,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PERFORMA]}>
        <ProformaInvoicesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${proformaRoute}/manage/:enquiry_no/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PERFORMA]}>
        <ManageSingleProformaInvoice />
      </AuthorizedRoute>
    ),
  },
];
