import { LoadState } from "../../constants/enums";
import { IAssembly, IAssemblyDetail, IAssemblyState } from "./assembly.types";

export const defaultAssemblyDetail: IAssemblyDetail = {
  assembly_code: "",
  name: "",
  description: "",
};

export const defaultAssembly: IAssembly = {
  assembly_uuid: null,
  assembly_name: "",
  assembly_details: [defaultAssemblyDetail],
  status: "ACTIVE",
  created_by_uuid: null,
};

export const defaultAssemblyState: IAssemblyState = {
  assemblies: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_assembly: {
    data: defaultAssembly,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
