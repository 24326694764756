import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IWarehouse } from "./warehouse.types";

//=============================== Ledger List ===============================

export const FETCH_WAREHOUSE_LIST_PROGRESS = "FETCH_WAREHOUSE_LIST_PROGRESS";
export const FETCH_WAREHOUSE_LIST_SUCCESS = "FETCH_WAREHOUSE_LIST_SUCCESS";
export const FETCH_WAREHOUSE_LIST_FAILED = "FETCH_WAREHOUSE_LIST_FAILED";

export const fetchWarehouseListProgress = () =>
  action(FETCH_WAREHOUSE_LIST_PROGRESS);
export const fetchWarehouseListSuccess = (
  data: IWarehouse[],
  totalRecords: number,
) => action(FETCH_WAREHOUSE_LIST_SUCCESS, { data, totalRecords });
export const fetchWarehouseListFailed = () =>
  action(FETCH_WAREHOUSE_LIST_FAILED);

export const fetchWarehouseListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchWarehouseListProgress());
      const res = await api.get(`/warehouse/get-warehouse-info?${searchQuery}`);
      const data: IWarehouse[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchWarehouseListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWarehouseListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//================ Product warehouse List =================

export const fetchProductWarehouseListProgress = () =>
  action(FETCH_WAREHOUSE_LIST_PROGRESS);
export const fetchProductWarehouseListSuccess = (
  data: IWarehouse[],
  totalRecords: number,
) => action(FETCH_WAREHOUSE_LIST_SUCCESS, { data, totalRecords });
export const fetchProductWarehouseListFailed = () =>
  action(FETCH_WAREHOUSE_LIST_FAILED);

export const fetchProductWarehouseListAsync =
  (
    queryParams: IQueryParams,
    productUUID: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchWarehouseListProgress());
      const res = await api.get(
        `ledger/get-ledger${searchQuery}&product_uuid=${productUUID}`,
      );
      const data: IWarehouse[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchWarehouseListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWarehouseListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_WAREHOUSE_STATE = "CLEAR_WAREHOUSE_STATE";
export const clearWarehouseState = () => action(CLEAR_WAREHOUSE_STATE);
