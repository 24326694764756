import React from "react";
import {
  CustomFormLabel,
  CustomTextField,
  CustomTextFieldStyled,
  CustomTextFieldV2,
} from "../formsComponents";
import { ITextFieldWithLabelProps } from "./TextFieldWithLabel.types";
import debounce from "lodash/debounce";

export const TextFieldWithLabel: React.FC<ITextFieldWithLabelProps> =
  React.memo((props) => {
    const { value, ...rest } = props;
    // const [initialText, setInitialText] = React.useState<string | undefined>(props.value as any);

    // // Only update if value changes
    // React.useEffect(() => {
    //   if (props.value !== initialText) {
    //     setInitialText(props.value as any);
    //   }
    // }, [props.value]);

    // // Debounce user input to prevent excessive re-renders
    // const handleChange = React.useCallback(
    //   debounce((value: string) => {
    //     setInitialText(value);
    //   }, 300),
    //   []
    // );

    // const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    //   if (props.onChange) props.onChange(event);
    // };
    return (
      <>
        <CustomFormLabel>{props.heading}</CustomFormLabel>
        <CustomTextFieldV2
          {...props}
          variant="outlined"
          size="small"
          error={!!props.error}
          helperText={props.error ? props.helperText : ""}
        />
      </>
    );
  });

export const TextFieldWithLabelOld: React.FC<ITextFieldWithLabelProps> =
  React.memo((props) => {
    const [initalText, setInitalText] = React.useState<string | unknown>(
      undefined,
    );

    React.useEffect(() => {
      setInitalText(props.value);
    }, [props.value]);

    const handleOnblur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (props.onChange) props.onChange(event);
    };

    return (
      <>
        <CustomFormLabel>{props.heading}</CustomFormLabel>
        <CustomTextField
          {...props}
          value={initalText}
          onChange={(evt) => setInitalText(evt.target.value)}
          onBlur={handleOnblur}
          variant="outlined"
          size="small"
          error={props.error ? true : false}
          helperText={props.error ? props.helperText : ""}
        />
      </>
    );
  });

export const NumberFieldWithLabel: React.FC<ITextFieldWithLabelProps> =
  React.memo((props) => {
    return (
      <>
        <CustomFormLabel>{props.heading}</CustomFormLabel>
        <CustomTextField
          {...props}
          variant="outlined"
          size="small"
          placeholder={props.placeholder || "0"}
          value={props.value?.toString() === "0" ? "" : props.value?.toString()}
          onChange={(evt) => {
            if (!props.onChange) return;
            if (
              props.value?.toString().includes(".") &&
              evt.target.value === "."
            )
              return;
            let newEvt = { ...evt };
            const onlyNums = newEvt.target.value
              .replace(/[^0-9.]/g, "")
              .replace(/(\..*)\./g, "$1");
            newEvt.target.value = onlyNums;
            props.onChange(newEvt);
          }}
          error={props.error ? true : false}
          helperText={props.error ? props.helperText : ""}
        />
      </>
    );
  });
