import { Autocomplete, Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { ISelectOption } from "../../constants/types";
import { debounce } from "lodash";
import { api } from "../../api/api";
import {
  IAutoCompleteSearchesProps,
  IRoleIdAutoSearchProps,
} from "./AutoCompleteSearches.types";
import { IRole } from "../../redux/security/security.types";

export const INITIAL_STATE: IRole = {
  role_id: 0,
  role_uuid: "",
  role_name: "",
  role_json: "",
  status: "",
};

export const RoleIdAutoSearch: React.FC<IRoleIdAutoSearchProps> = (props) => {
  const { label, value, onSelect, disabled, error } = props;

  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<ISelectOption[]>([]);
  // const [searchText, setSearchText] = React.useState("");
  const [options, setOptions] = React.useState<readonly IRole[]>([]);
  const [fieldValue, setFieldValue] = React.useState<IRole | null>(null);
  const [search, setSearch] = React.useState<any>("");

  // useEffect(() => {
  //   fetchSuggestion(value);
  // }, []);

  // const fetchSuggestion = debounce(async (value) => {
  //   setLoading(true);
  //   try {
  //     const res = await api.get(`security/get-roles?pageNo=1&itemPerPage=20`);
  //     const data: any[] = res.data.data;
  //     const finalData: ISelectOption[] = [];
  //     for (const role of data) {
  //       if (role.role_id) {
  //         finalData.push({
  //           label: role.role_name,
  //           value: role.role_uuid,
  //         });
  //       }
  //     }
  //     setSearchResults(finalData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, 500);

  const fetchSuggestion = async (value: string) => {
    setLoading(true);

    console.log("value", value);

    try {
      let url = `security/get-roles?columns=role_name&value=${value}&pageNo=1&itemPerPage=20`;
      if (value === "") {
        url = `security/get-roles?pageNo=1&itemPerPage=20`;
      }
      const res = await api.get(url);
      const finalData: IRole[] = res.data.data;
      setOptions(finalData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.currentTarget.value;

  //   setSearchText(value);
  // };
  const getOptionLabel = (option: string | IRole) => {
    if (typeof option === "string") {
      return option;
    }
    return option.role_name;
  };

  const getValue = () => {
    let newValue: IRole | null = null;
    if (value && typeof value === "object") {
      newValue =
        options.find((option) => option.role_uuid === value?.role_uuid) || null;
    } else {
      newValue =
        options.find((option) => option.role_uuid === value.role_uuid) || null;
    }
    setFieldValue(newValue);
  };

  React.useEffect(() => {
    if (search && search !== value && search.length > 1) {
      debounceFn(search);
    }
  }, [search]);

  React.useEffect(() => {
    fetchSuggestion("");
  }, []);

  React.useEffect(() => {
    getValue();
  }, [value, options]);

  // React.useEffect(() => {
  //   if (value) {
  //     setSearchText(value);
  //     fetchSuggestion(value);
  //   } else {
  //     setSearchText("");
  //   }
  // }, [value]);

  React.useEffect(() => {
    if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
      const option: IRole = {
        ...INITIAL_STATE,
        role_uuid: value.role_uuid,
        role_name: value.role_name,
      };
      setOptions([option]);
    }
  }, [value]);

  return (
    // <>
    //   <Autocomplete
    //     options={searchResults.map((result) => ({
    //       label: result.label,
    //       value: result.value,
    //     }))}

    //     sx={{
    //       ".MuiOutlinedInput-root": {
    //         paddingTop: "2px",
    //         paddingBottom: "2px",
    //         fontSize: "0.8rem",
    //         color: "rgb(38, 38, 38)",
    //       },
    //     }}
    //     value={searchResults.find((option) => option.value === value) || null}
    //     defaultValue={value}
    //     getOptionLabel={getOptionLabel}
    //     onChange={(e, newValue) => {
    //       if (newValue && typeof newValue !== "string") {
    //         onSelect(newValue);
    //       } else {
    //         onSelect({ label: "", value: "" });
    //       }
    //     }}
    //     renderInput={(params) => (
    //       <CustomTextField
    //         {...params}
    //         fullWidth
    //         value={searchText}
    //         onChange={handleChange}
    //         InputProps={{
    //           ...params.InputProps,
    //           endAdornment: (
    //             <React.Fragment>
    //               {loading && <CircularProgress color="inherit" size={20} />}
    //               {params.InputProps.endAdornment}
    //             </React.Fragment>
    //           ),
    //         }}
    //       />
    //     )}
    //   />
    // </>
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
          {/* {search.length > 0 && options.length === 0 && !loading && (
          <Box
            sx={{
              paddingLeft: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 1,
            }}
            onClick={handleToggle}
          >
            <AddCircleIcon fontSize="small" color="primary" />
            <Typography
              variant="h6"
              color="primary"
              marginLeft={0.5}
              marginTop={0.3}
              fontWeight={500}
            >
              Add Br
            </Typography>
          </Box>
        )} */}
        </Box>
      )}
      <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
        <Autocomplete
          id="google-map-demo"
          fullWidth
          disabled={disabled}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingTop: "2px",
              paddingBottom: "2px",
              fontSize: "0.8rem",
              color: "rgb(38, 38, 38)",
              width: "100%",
              backgroundColor: disabled ? "#f8f9fb" : "inherit",
            },
          }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            typeof option === "string"
              ? option === value //@ts-ignore
              : option.role_name === value.role_name
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          value={fieldValue}
          loading={loading}
          noOptionsText="No Role Found"
          //@ts-ignore
          onChange={(
            event: React.ChangeEvent<HTMLElement>,
            newValue: IRole | null,
          ) => {
            if (newValue) {
              onSelect(newValue);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if ((event && event.type === "change") || !newInputValue) {
              setSearch(newInputValue);
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={disabled}
              error={error ? true : false}
              helperText={error}
            />
          )}
        />
      </Stack>
    </>
  );
};
