import produce from "immer";
import { IPriceUpdationActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  FETCH_PRICE_UPDATION_LIST_PROGRESS,
  FETCH_PRICE_UPDATION_LIST_SUCCESS,
  FETCH_PRICE_UPDATION_LIST_FAILED,
  CLEAR_PRICE_UPDATION_LIST,
  FETCH_PRICE_UPDATION_FAILED,
  FETCH_PRICE_UPDATION_PROGRESS,
  FETCH_PRICE_UPDATION_SUCCESS,
  CLEAR_SINGLE_PRICE_UPDATION,
} from "./priceUpdation.action";
import {
  defaultPriceUpdation,
  defaultPriceUpdationState,
} from "./priceUpdation.state";

export const priceUpdationReducer = (
  state: IStoreState["priceUpdation"] = defaultPriceUpdationState,
  action: IPriceUpdationActions,
) => {
  switch (action.type) {
    //=============================== Rendering List ===============================
    case FETCH_PRICE_UPDATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRICE_UPDATION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecord = totalRecords;
      });
      return newState;
    }
    case FETCH_PRICE_UPDATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
        draftState.list.data = [];
        draftState.list.totalRecord = 0;
      });
      return newState;
    }
    case FETCH_PRICE_UPDATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.priceUpdation.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRICE_UPDATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.priceUpdation.loading = LoadState.Loaded;
        draftState.priceUpdation.data = data;
      });
      return newState;
    }
    case FETCH_PRICE_UPDATION_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.priceUpdation.loading = LoadState.Failed;
        draftState.priceUpdation.data = defaultPriceUpdation;
      });
      return newState;
    }

    case CLEAR_PRICE_UPDATION_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.NotLoaded;
        draftState.list.data = [];
        draftState.list.totalRecord = 0;
        draftState.list.error = null;
      });
      return newState;
    }
    case CLEAR_SINGLE_PRICE_UPDATION: {
      const newState = produce(state, (draftState) => {
        draftState.priceUpdation.loading = LoadState.NotLoaded;
        draftState.priceUpdation.data = defaultPriceUpdation;
        draftState.priceUpdation.error = null;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
