import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IContact } from "./contacts.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";

export const FETCH_CONTACTS_LIST_PROGRESS = "FETCH_CONTACTS_LIST_PROGRESS";
export const FETCH_CONTACTS_LIST_SUCCESS = "FETCH_CONTACTS_LIST_SUCCESS";
export const FETCH_CONTACTS_LIST_FAILED = "FETCH_CONTACTS_LIST_FAILED";

export const fetchContactsListProgress = () =>
  action(FETCH_CONTACTS_LIST_PROGRESS);
export const fetchContactsListSuccess = (
  data: IContact[],
  totalRecords: number,
) => action(FETCH_CONTACTS_LIST_SUCCESS, { data, totalRecords });
export const fetchContactsListFailed = () => action(FETCH_CONTACTS_LIST_FAILED);

export const fetchContactsListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchContactsListProgress());

      const res = await api.get(`/customer/get-contacts?${searchQuery}`);
      const data: IContact[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchContactsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchContactsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertContactsAsync =
  (
    data: IContact,
    onCallback: (isSuccess: boolean, contact?: IContact) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = data;
      const res = await api.post("/customer/upsert-contacts", rest);

      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Contact saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_CONTACT_PROGRESS = "FETCH_CONTACT_PROGRESS";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAILED = "FETCH_CONTACT_FAILED";

export const fetchContactProgress = () => action(FETCH_CONTACT_PROGRESS);
export const fetchContactSuccess = (data: IContact) =>
  action(FETCH_CONTACT_SUCCESS, { data });
export const fetchContactFailed = (errorMessage: string) =>
  action(FETCH_CONTACT_FAILED, { errorMessage });

export const fetchContactAsync =
  (contactId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchContactProgress());
      const res = await api.get(
        `/customer/get-contacts?contact_uuid=${contactId}`,
      );
      const data: IContact[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchContactSuccess(data[0]));
      } else {
        dispatch(fetchContactFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchContactFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_CONTACT = "CLEAR_CONTACT";
export const CLEAR_CONTACT_STATE = "CLEAR_CONTACT_STATE";
export const clearContact = () => action(CLEAR_CONTACT);
export const clearContactState = () => action(CLEAR_CONTACT_STATE);
