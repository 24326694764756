import { LoadState } from "../../constants/enums";
import { IDraft, IDraftState } from "./draft.types";

export const defaultDraft: IDraft = {
  draft_code: null,
  form_url: null,
  reference_data_uuid: null,
  reference_data: {},
  status: "ACTIVE",
};

export const deafaultDraftState: IDraftState = {
  multiple_drafts: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_draft: {
    data: defaultDraft,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
