import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IGrn } from "./warehouseGrn.types";
import {
  SERVER_GET_GRN_ROUTE,
  SERVER_UPSERT_GRN_ROUTE,
} from "./warehouseGrn.route";

//=============================== Ledger List ===============================

export const FETCH_GRN_LIST_PROGRESS = "FETCH_GRN_LIST_PROGRESS";
export const FETCH_GRN_LIST_SUCCESS = "FETCH_GRN_LIST_SUCCESS";
export const FETCH_GRN_LIST_FAILED = "FETCH_GRN_LIST_FAILED";

export const fetchGrnListProgress = () => action(FETCH_GRN_LIST_PROGRESS);
export const fetchGrnListSuccess = (data: IGrn[], totalRecords: number) =>
  action(FETCH_GRN_LIST_SUCCESS, { data, totalRecords });
export const fetchGrnListFailed = () => action(FETCH_GRN_LIST_FAILED);

export const fetchGrnListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchGrnListProgress());
      const res = await api.get(`${SERVER_GET_GRN_ROUTE}?${searchQuery}`);
      const data: IGrn[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchGrnListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchGrnListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_GRN_PROGRESS = "FETCH_GRN_PROGRESS";
export const FETCH_GRN_SUCCESS = "FETCH_GRN_SUCCESS";
export const FETCH_GRN_FAILED = "FETCH_GRN_FAILED";

export const fetchGrnProgress = () => action(FETCH_GRN_PROGRESS);
export const fetchGrnSuccess = (data: IGrn, totalRecords: number) =>
  action(FETCH_GRN_SUCCESS, { data, totalRecords });
export const fetchGrnFailed = () => action(FETCH_GRN_FAILED);

export const fetchGrnAsync =
  (grnUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGrnProgress());
      const res = await api.get(`${SERVER_GET_GRN_ROUTE}?grn_uuid=${grnUUID}`);
      const data: IGrn = res.data.data[0];

      const totalRecords = res.data.totalRecords;

      dispatch(fetchGrnSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchGrnFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//================ Product Grn List =================
export const upsertSingleGrnAsync =
  (
    data: IGrn,
    onCallback: (isSuccess: boolean, deviation?: IGrn) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        combined_grn_no,
        grn_no,
        ...payload
      } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_GRN_ROUTE, payload);
      let message = "GRN saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_GRN_STATE = "CLEAR_GRN_STATE";
export const clearGrnState = () => action(CLEAR_GRN_STATE);
