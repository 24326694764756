import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { uploadFile } from "../../helpers/uploadFile";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IBom,
  IBomItem,
  IBomProduct,
  IBomState,
  IGroupedBom,
  IValidatePartItem,
} from "./bom.types";
import { initialBom } from "./defaultState";
import { IUpsertBulkDrawingAssignTo } from "../drawing";

export const FETCH_BOM_LIST_PROGRESS = "FETCH_BOM_LIST_PROGRESS";
export const FETCH_BOM_LIST_SUCCESS = "FETCH_BOM_LIST_SUCCESS";
export const FETCH_BOM_LIST_FAILED = "FETCH_BOM_LIST_FAILED";

export const fetchBomListProgress = () => action(FETCH_BOM_LIST_PROGRESS);

export const fetchBomListSuccess = (
  data: IBomState["list"]["data"],
  totalRecords: number,
) => action(FETCH_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchBomListFailed = () => action(FETCH_BOM_LIST_FAILED);

const execGroupData = (data: IBom[]): IGroupedBom[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.order_no]) {
        //@ts-ignore
        acc[obj.order_no] = {
          id: obj.order_no,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.order_no].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  const checkVerifications = (groupedData as IGroupedBom[]).map((item) => {
    let isVerified = true;
    item.childs.forEach((child_item) => {
      if (child_item.creation_status === "EXISTS") {
        isVerified = false;
      }
    });
    return { ...item, isVerified };
  });
  return checkVerifications as IGroupedBom[];
};

export const fetchBomListAsync =
  (
    queryParams: ISearchQueryParams,
    origin?: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchBomListProgress());

      let url = `/bom/get-bom?${searchQuery}`;
      if (origin && origin?.length > 0) {
        const filteredOrigins = origin.filter((o) => o !== "ALL");
        if (filteredOrigins.length > 0) {
          url += `&origin=${filteredOrigins}`;
        }
      }
      const res = await api.get(url);
      const data: IBomState["list"]["data"] = res.data.data;
      const finalData = execGroupData(data);
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBomListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_BOM_PROGRESS = "FETCH_BOM_PROGRESS";
export const FETCH_BOM_SUCCESS = "FETCH_BOM_SUCCESS";
export const FETCH_BOM_FAILED = "FETCH_BOM_FAILED";

export const fetchBomProgress = () => action(FETCH_BOM_PROGRESS);
export const fetchBomSuccess = (data: IBom) =>
  action(FETCH_BOM_SUCCESS, { data });
export const fetchBomFailed = (errorMessage: string) =>
  action(FETCH_BOM_FAILED, { errorMessage });

export const fetchBomAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBomProgress());
      const res = await api.get(`/bom/get-bom?bom_uuid=${uuid}`);
      const data: IBom[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBomSuccess(data[0]));
      } else {
        dispatch(fetchBomFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_COMPLETE_BOM_PROGRESS = "FETCH_COMPLETE_BOM_PROGRESS";
export const FETCH_COMPLETE_BOM_SUCCESS = "FETCH_COMPLETE_BOM_SUCCESS";
export const FETCH_COMPLETE_BOM_FAILED = "FETCH_COMPLETE_BOM_FAILED";

export const fetchCompleteBomProgress = () =>
  action(FETCH_COMPLETE_BOM_PROGRESS);
export const fetchCompleteBomSuccess = (data: IBom) =>
  action(FETCH_COMPLETE_BOM_SUCCESS, { data });
export const fetchCompleteBomFailed = (errorMessage: string) =>
  action(FETCH_COMPLETE_BOM_FAILED, { errorMessage });

export const fetchCompleteBomAsync =
  (
    product_code: string,
    order_no: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCompleteBomProgress());
      const res = await api.get(
        `/bom/get-complete-bom?product_code=${product_code}&sod_no=${order_no}`,
      );
      const data: IBom = res.data.data;
      if (data) {
        dispatch(fetchCompleteBomSuccess(data));
      } else {
        dispatch(fetchCompleteBomFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchCompleteBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertBomAsync =
  (
    data: IBom,
    file: any,
    onCallback: (isSuccess: boolean, bom?: IBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let {
        create_ts,
        insert_ts,
        approval_status,
        approval_uuid,
        is_user_approver,
        requested_by_uuid,
        remark,
        rev_no,

        ...payload
      } = data;

      let path = "";
      if (file) {
        const asPayload = {
          series: data.series,
        };
        path = await uploadFile(
          file,
          "BOM",
          data.product_code || "",
          asPayload,
        );
        payload.excel_link = path;
      }

      dispatch(saveLoaderProgress());
      const res = await api.post("/bom/upsert-bom", payload);
      let message = "Bom saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
      // if (data.status === "BOM_APPROVAL_REQUESTED" && data.bom_uuid) {
      //   await api.post("/bom/bulk-insert-part-item", {
      //     bom_uuid: data.bom_uuid,
      //   });
      // }
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertBulkBomAssignToAsync =
  (
    data: IUpsertBulkDrawingAssignTo,
    onCallback: (isSuccess: boolean, Drawing?: IBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/bom/bulk-transfer-assigned-user-in-bom",
        data,
      );
      let message = "Bom assigned to user successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// export const upsertRequestBomAsync =
//   (
//     enquiryNo: string,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(saveLoaderProgress());
//         await api.post("/bom/upsert-request-bom", {
//           enquiry_no: enquiryNo,
//           status: "BOM_REQUESTED",
//         });
//         let message = "Bom requested successfully!";
//         onCallback(true);
//         dispatch(
//           showMessage({
//             type: "success",
//             displayAs: "snackbar",
//             message: message,
//           }),
//         );
//       } catch (err: any) {
//         onCallback(false);
//         dispatch(
//           showMessage({
//             type: "error",
//             message: err.response.data.message,
//             displayAs: "snackbar",
//           }),
//         );
//       } finally {
//         dispatch(saveLoaderCompleted());
//       }
//     };

export const upsertRequestRoutingAsync =
  (
    bomUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/planning/upsert-routing-from-bom", {
        bom_uuid: bomUuid,
      });
      let message = "Routing requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// routing and mrp
export const upsertRequestRoutingAndMrpAsync =
  (
    bomUuid: string,
    insertMrp: boolean,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/planning/upsert-routing-and-mrp-from-bom", {
        bom_uuid: bomUuid,
        insert_mrp: insertMrp,
      });
      let message = "Action requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Fetch Approval BOM List********************** //
export const FETCH_APPROVAL_BOM_LIST_PROGRESS =
  "FETCH_APPROVAL_BOM_LIST_PROGRESS";
export const FETCH_APPROVAL_BOM_LIST_SUCCESS =
  "FETCH_APPROVAL_BOM_LIST_SUCCESS";
export const FETCH_APPROVAL_BOM_LIST_FAILED = "FETCH_APPROVAL_BOM_LIST_FAILED";

export const fetchApprovalBomListProgress = () =>
  action(FETCH_APPROVAL_BOM_LIST_PROGRESS);
export const fetchApprovalBomListSuccess = (
  data: IBom[],
  totalRecords: number,
) => action(FETCH_APPROVAL_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalBomListFailed = () =>
  action(FETCH_APPROVAL_BOM_LIST_FAILED);

export const fetchApprovalBomListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalBomListProgress());
      const res = await api.get(
        `/approval/get-approval?table_name=latest_bom&status=REQUESTED&${searchQuery}`,
      );
      const data: IBom[] = res.data.data;

      const totalRecords = res.data.totalRecords;
      dispatch(fetchApprovalBomListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Upsert Bom Item ********************* //
export const upsertBomItemsAsync =
  (
    data: IBomItem,
    onCallback: (isSuccess: boolean, product?: IBomItem) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, ...rest } = data;

      const res = await api.post("/bom/upsert-bom-item", rest);
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_BOM_ITEM_PROGRESS = "FETCH_BOM_ITEM_PROGRESS";
export const FETCH_BOM_ITEM_SUCCESS = "FETCH_BOM_ITEM_SUCCESS";
export const FETCH_BOM_ITEM_FAILED = "FETCH_BOM_ITEM_FAILED";

export const fetchBomItemProgress = () => action(FETCH_BOM_ITEM_PROGRESS);
export const fetchBomItemSuccess = (data: IBomItem) =>
  action(FETCH_BOM_ITEM_SUCCESS, { data });
export const fetchBomItemFailed = (errorMessage: string) =>
  action(FETCH_BOM_ITEM_FAILED, { errorMessage });

export const fetchBomItemAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchBomItemProgress());
      const res = await api.get(
        `/approval/get-approval?table_name=latest_bom&status=REQUESTED&${searchQuery}`,
      );
      const data: IBomItem[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBomItemSuccess(data[0]));
      } else {
        dispatch(fetchBomItemFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// upset bom item for hierarchy
export const upsertBomItemsHierarchyAsync =
  (
    bomUUID: string,
    onCallback: (isSuccess: boolean, data?: IBomProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // dispatch(saveLoaderProgress());

      const res = await api.post("/bom/bulk-insert-bom-item", {
        bom_uuid: bomUUID,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Bom Revision ********************* //;

export const upsertBomRevisionAsync =
  (
    bomUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/upsert-bom-revision", {
        bom_uuid: bomUuid,
      });
      let message = "Revision requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Request BDL ********************* //;

export const upsertRequestBdlAsync =
  (
    bomUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/request-bdl", {
        bom_uuid: bomUuid,
      });
      let message = "BDL requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchBomRevisionAsync =
  (
    uuid: string,
    revNo: number,
    onCallback: (isSuccess: boolean, data: IBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/bom/get-bom-revision?bom_uuid=${uuid}&rev_no=${revNo}`,
      );
      const data: IBom[] = res.data.data;
      if (data.length > 0) {
        onCallback(true, data[0]);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
//
//this api will be called right after the bom approval is done
export const upsertBulkPartItemAsync =
  (
    bomUUID: string,
    onCallback: (isSuccess: boolean, data?: IBomProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post("/bom/bulk-insert-part-item", {
        bom_uuid: bomUUID,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Internal Order creation for bom item with 71 ********************* //;

export const upsertInternalOrderAsync =
  (
    bomUuid: string,
    rowUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/upsert-internal-order", {
        bom_uuid: bomUuid,
        row_uuid: rowUuid,
      });
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//*********************Bom Request for routing ******************** */

export interface IRequestROuting {
  bom_uuid: string;
}

export const requestRoutingFromBom =
  (
    data: IRequestROuting[],
    onCallback: (isSuccess: boolean, data?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/planning/bulk-transfer-bom-to-routing", {
        bom_details: data,
      });
      onCallback(true, res.data.data);

      dispatch(
        showMessage({
          type: "success",
          message: "Routing requested successfully!",
          displayAs: "dialog",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** get internal order items ********************* //;
export const fetchInternalOrderItemsAsync =
  (
    part_code: string,
    bom_uuid: string,
    onCallback: (isSuccess: boolean, data: IBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/bom/get-internal-bom?part_code=${part_code}&bom_uuid=${bom_uuid}`,
      );
      const data: IBom[] = res.data.data;
      if (data.length > 0) {
        onCallback(true, data[0]);
      }
    } catch (err: any) {
      onCallback(false, initialBom);
      // dispatch(
      //   showMessage({
      //     type: "error",
      //     message: err.response.data.message,
      //     displayAs: "snackbar",
      //   }),
      // );
    }
  };

// ********************** verify Internal Order ********************* //;

export const upsertVerifyInternalBomAsync =
  (
    internal_bom_uuid: string,
    current_bom_uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/verify-internal-bom", {
        internal_bom_uuid,
        current_bom_uuid,
      });
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** get internal order items ********************* //;
export const fetchSubBomForSEZRegionAsync =
  (
    part_code: string,
    bom_uuid: string,
    onCallback: (isSuccess: boolean, data: IBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/bom/get-sub-bom?part_code=${part_code}&bom_uuid=${bom_uuid}`,
      );
      const data: IBom = res.data.data;
      onCallback(true, data);
    } catch (err: any) {
      onCallback(false, initialBom);
      // dispatch(
      //   showMessage({
      //     type: "error",
      //     message: err.response.data.message,
      //     displayAs: "snackbar",
      //   }),
      // );
    }
  };

// ********************** verify Internal Order ********************* //;

export const verifySubBomForSEZRegionAsync =
  (
    sub_bom_uuid: string,
    current_bom_uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/verify-sub-bom", {
        sub_bom_uuid,
        current_bom_uuid,
      });
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BOM = "CLEAR_BOM";
export const CLEAR_BOM_STATE = "CLEAR_BOM_STATE";
export const clearBom = () => action(CLEAR_BOM);
export const clearBomState = () => action(CLEAR_BOM_STATE);

// Drawing Verification
export const FETCH_ORDER_EXISTING_BOM_PROGRESS =
  "FETCH_ORDER_EXISTING_BOM_PROGRESS";
export const FETCH_ORDER_EXISTING_BOM_SUCCESS =
  "FETCH_ORDER_EXISTING_BOM_SUCCESS";
export const FETCH_ORDER_EXISTING_BOM_FAILED =
  "FETCH_ORDER_EXISTING_BOM_FAILED";

export const fetchOrderExistingBomProgress = () =>
  action(FETCH_ORDER_EXISTING_BOM_PROGRESS);
export const fetchOrderExistingBomSuccess = (
  data: IBomState["order_existing_bom"]["data"],
  totalRecords: number,
) => action(FETCH_ORDER_EXISTING_BOM_SUCCESS, { data, totalRecords });
export const fetchOrderExistingBomFailed = (errorMessage: string) =>
  action(FETCH_ORDER_EXISTING_BOM_FAILED, { errorMessage });

export const fetchOrderExistingBomAsync =
  (
    order_number: string,
    existing: boolean,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let url = `/bom/get-bom?order_no=${order_number}`;
      if (existing) {
        url += `&creation_status=EXISTS`;
      }
      dispatch(fetchOrderExistingBomProgress());
      const res = await api.get(url);
      const finalData: IBom[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchOrderExistingBomSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchOrderExistingBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const verifyOrderExistingBomAsync =
  (
    payload: {
      product_code: string;
      order_no: string;
      bom_uuid: string;
    }[],
    isBomRequest: boolean,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/verify-bom", {
        bom_items: payload,
        request_routing: isBomRequest,
      });
      let message = "Drawing Verified successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// post_api_v1_bom_verify_part_item
export const verifyUploadedPartItemsAsync =
  (
    partItems: IValidatePartItem[],
    onCallback: (isSuccess: boolean, data?: IValidatePartItem[]) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post("/bom/verify-part-item", {
        part_items: partItems,
      });
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
