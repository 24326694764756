import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import { LoadState } from "../../../constants/enums";
import {
  CLEAR_SINGLE_ORDER,
  FETCH_APPROVED_ORDERS_LIST_FAILED,
  FETCH_APPROVED_ORDERS_LIST_PROGRESS,
  FETCH_APPROVED_ORDERS_LIST_SUCCESS,
  FETCH_SINGLE_ORDER_FAILED,
  FETCH_SINGLE_ORDER_PROGRESS,
  FETCH_SINGLE_ORDER_SUCCESS,
} from "./orders.actions";
import { defaultOrderState } from "./orders.state";
import { OrderProcessingActions } from ".";

export const ordersReducer = (
  state: IStoreState["sales"]["orders"] = defaultOrderState,
  action: OrderProcessingActions,
) => {
  switch (action.type) {
    // ----------------------------------  Comment List----------------------------------
    case FETCH_SINGLE_ORDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_order.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_ORDER_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_order.loading = LoadState.Loaded;
        draftState.single_order.data = action.payload;
      });
      return newState;
    }
    case FETCH_SINGLE_ORDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_order.loading = LoadState.Failed;
        draftState.single_order.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_ORDER: {
      const newState = produce(state, (draftState) => {
        draftState.single_order.loading = LoadState.NotLoaded;
        draftState.single_order.data =
          defaultOrderState["single_order"]["data"];
        draftState.single_order.error = null;
      });
      return newState;
    }

    // ----------------------------------  APPROVED_ORDERS_LIST ----------------------------------
    case FETCH_APPROVED_ORDERS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_approved_Orders.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_APPROVED_ORDERS_LIST_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_approved_Orders.loading = LoadState.Loaded;
        draftState.multiple_approved_Orders.data = action.payload.data;
        draftState.multiple_approved_Orders.totalRecords = action.payload.count;
      });
      return newState;
    }
    case FETCH_APPROVED_ORDERS_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_approved_Orders.loading = LoadState.Failed;
        draftState.multiple_approved_Orders.error = errorMessage;
      });
      return newState;
    }
    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
