import { LoadState } from "../../constants/enums";
import { IRequest, IRequestState } from "./request.types";

export const defaultrequest: IRequest = {
  request_id: 0,
  request_uuid: "",
  request_name: "",
  module: "",
  running_no: 0,
  request_prefix: "REQ",
  requesting_department: "",
  requesting_user_uuid: "",
  requesting_user_name: "",
  verifier_department: [
    {
      uuid: "",
      department_name: "",
    },
  ],
  current_verifier_role_uuid: null,
  verified_departments: null,
  description: "",
  category: "",
  specifications: [
    {
      key: "",
      value: "",
    },
  ],
  additional_attributes: [
    {
      key: "",
      value: "",
    },
  ],
  attachments: [],
  status: "REQUEST",
  created_by_uuid: "",
  create_ts: "",
  insert_ts: "",
  combined_request_no: "",
  approval_uuid: "",
  requested_by_uuid: "",
  is_user_approver: false,
};

export const defaultRequestState: IRequestState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  request: {
    data: defaultrequest,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
