import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IGroupedProformaInvoices,
  IProformaInvoice,
  IUpsertProformaInvoice,
} from "./proformaInvoice.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { getGroupedProformaInvoicesbyArray } from "./proformaInvoice.helpers";
import { IRaiseCostingSheetItems } from "../../views/quotes/dialogs/RaiseCostingSheetDialog/RaiseCostingSheetDialog.types";
//******************************** FETCH_QUOTE_PROFORMA_INVOICE_LIST By Enquiry *************************************/
export const FETCH_PI_LIST_BY_ENQUIRY_PROGRESS =
  "FETCH_PI_LIST_BY_ENQUIRY_PROGRESS";
export const FETCH_PI_LIST_BY_ENQUIRY_SUCCESS =
  "FETCH_PI_LIST_BY_ENQUIRY_SUCCESS";
export const FETCH_PI_LIST_BY_ENQUIRY_FAILED =
  "FETCH_PI_LIST_BY_ENQUIRY_FAILED";

export const fetchPIListByEnquiryProgress = () =>
  action(FETCH_PI_LIST_BY_ENQUIRY_PROGRESS);
export const fetchPIListByEnquirySuccess = (
  list: IProformaInvoice[],
  totalRecords: number,
) =>
  action(FETCH_PI_LIST_BY_ENQUIRY_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchPIListByEnquiryFailed = () =>
  action(FETCH_PI_LIST_BY_ENQUIRY_FAILED);

export const fetchQuoteProformaInvoicesAsync =
  (
    enquiryNo: string,
    latest: boolean,
    revisionNo?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPIListByEnquiryProgress());
      const res = await api.get(
        `/quotes/get-proforma-invoice?enquiry_no=${enquiryNo}&getLatest=${latest}${
          !latest ? `&quote_revision=${revisionNo}` : ""
        }`,
      );
      const data: IProformaInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchPIListByEnquirySuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPIListByEnquiryFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_PI_LIST_BY_ENQUIRY = "CLEAR_PI_LIST_BY_ENQUIRY";
export const clearPIListByEnquiryNoAsync = () =>
  action(CLEAR_PI_LIST_BY_ENQUIRY);

//******************************** FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS *************************************/
export const FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED =
  "FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED";

export const fetchQuotesProformaInvoiceListProgress = () =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS);
export const fetchQuotesProformaInvoiceListSuccess = (
  list: IProformaInvoice[],
  totalRecords: number,
) => action(FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS, { list, totalRecords });
export const fetchQuotesProformaInvoiceListFailed = () =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED);

export const fetchQuotesProformaInvoiceListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuotesProformaInvoiceListProgress());
      const searchQuery = getSearchQueryV2(queryParams);
      const res = await api.get(
        `/quotes/get-proforma-invoice?getLatest=true&${searchQuery}`,
      );
      const data: IProformaInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchQuotesProformaInvoiceListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchQuotesProformaInvoiceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
//******************************** fetchMultipleGroupedProformaInvoiceAsync *************************************/

export const FETCH_GROUPED_PROFORMA_INVOICE_LIST_PROGRESS =
  "FETCH_GROUPED_PROFORMA_INVOICE_LIST_PROGRESS";
export const FETCH_GROUPED_PROFORMA_INVOICE_LIST_SUCCESS =
  "FETCH_GROUPED_PROFORMA_INVOICE_LIST_SUCCESS";
export const FETCH_GROUPED_PROFORMA_INVOICE_LIST_FAILED =
  "FETCH_GROUPED_PROFORMA_INVOICE_LIST_FAILED";

export const fetchGroupedProformaInvoiceListProgress = () =>
  action(FETCH_GROUPED_PROFORMA_INVOICE_LIST_PROGRESS);
export const fetchGroupedProformaInvoiceListSuccess = (
  list: IGroupedProformaInvoices[],
  totalRecords: number,
) =>
  action(FETCH_GROUPED_PROFORMA_INVOICE_LIST_SUCCESS, { list, totalRecords });
export const fetchGroupedProformaInvoiceListFailed = () =>
  action(FETCH_GROUPED_PROFORMA_INVOICE_LIST_FAILED);
export const fetchMultipleGroupedProformaInvoiceAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGroupedProformaInvoiceListProgress());
      const searchQuery = getSearchQueryV2(queryParams);
      const res = await api.get(
        `/quotes/get-proforma-invoice?getLatest=false&${searchQuery}`,
      );
      const data: IProformaInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(
        fetchGroupedProformaInvoiceListSuccess(
          getGroupedProformaInvoicesbyArray(data),
          totalRecords,
        ),
      );
    } catch (err: any) {
      dispatch(fetchGroupedProformaInvoiceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
export const CLEAR_GROUPED_PROFORMA_INVOICE_LIST =
  "CLEAR_GROUPED_PROFORMA_INVOICE_LIST";

export const clearMultipleGroupedProformaInvoicesSync = () =>
  action(CLEAR_GROUPED_PROFORMA_INVOICE_LIST);

//******************************** FETCH_QUOTE_PROFORMA_INVOICE *************************************/
export const FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_FAILED =
  "FETCH_QUOTE_PROFORMA_INVOICE_FAILED";

export const fetchQuoteProformaInvoiceProgress = () =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS);
export const fetchQuoteProformaInvoiceSuccess = (data: IProformaInvoice) =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS, { data });
export const fetchQuoteProformaInvoiceFailed = (errorMessage: string) =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_FAILED, { errorMessage });

export const fetchQuoteProformaInvoiceAsync =
  (
    proformaInvoiceUUID: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuoteProformaInvoiceProgress());
      const res = await api.get(
        `/quotes/get-proforma-invoice?proforma_invoice_uuid=${proformaInvoiceUUID}&getLatest=false`,
      );
      const data: IProformaInvoice[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchQuoteProformaInvoiceSuccess(data[0]));
      } else {
        dispatch(
          fetchQuoteProformaInvoiceFailed(
            "Oops! We couldn't find any records.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchQuoteProformaInvoiceFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
// export const FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_PROGRESS =
//   "FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_PROGRESS";
// export const FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_SUCCESS =
//   "FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_SUCCESS";
// export const FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_FAILED =
//   "FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_FAILED";

// export const fetchQuoteProformaInvoiceAttachmentProgress = () =>
//   action(FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_PROGRESS);
// export const fetchQuoteProformaInvoiceAttachmentSuccess = (data: IProformaInvoice) =>
//   action(FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_SUCCESS, { data });
// export const fetchQuoteProformaInvoiceAttachmentFailed = (errorMessage: string) =>
//   action(FETCH_QUOTE_PROFORMA_INVOICE_ATTACHMENT_FAILED, { errorMessage });

export const fetchQuoteProformaInvoiceAttachmentAsync =
  (enquiry_no: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // dispatch(fetchQuoteProformaInvoiceAttachmentProgress());
      const res = await api.get(
        `/quotes/get-qrf-quote-costing-attachments?enquiry_no=${enquiry_no}`,
      );
      const data: any = res.data.data;
      if (data.length > 0) {
        // dispatch(fetchQuoteProformaInvoiceSuccess(data[0]));
      } else {
        // dispatch(
        //   fetchQuoteProformaInvoiceFailed(
        //     "Oops! We couldn't find any records.",
        // ),
        // );
      }
    } catch (err: any) {
      // dispatch(fetchQuoteProformaInvoiceFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertQuoteProformaInvoiceAsync =
  (
    payload: IProformaInvoice,
    onCallback: (
      isSuccess: boolean,
      proformaUUID?: string,
      filePath?: string,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        insert_ts,
        create_ts,
        enquiry_uuid,
        quote_attachments,
        costing_attachments,
        approved_flag,
        approved_by,
        requested_by_uuid,
        approval_uuid,
        customer_address_district,
        delivery_address_district,
        customer_type,
        sign_off_url,
        project_name,
        transportation_charges,
        transportation_charges_value,
        other_charges,
        other_charges_value,
        pi_count,
        proforma_invoice_id,
        proforma_invoice_no,
        rev_no,
        sod_no,
        sod_type,
        created_by_name,
        created_by_uuid,
        modified_by_uuid,
        modified_by_name,
        combined_proforma_invoice_no,
        enquiry_assigned_to_uuid,
        enquiry_secondary_assigned_to_uuid,
        ...restPayload
      } = payload;
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/quotes/upsert-proforma-invoice",
        restPayload,
      );
      await api.get(
        `/quotes/get-pi-preview-or-pdf?proforma_invoice_uuid=${res.data.data.proforma_invoice_uuid}&isPreviewOrUpload=UPLOAD_PDF&isProformaInvoice=true`,
      );
      onCallback(
        true,
        res.data.data.proforma_invoice_uuid,
        res.data.data.filePath,
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Proforma Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export interface IUpsertMultipleCostingSheetsPayloadforPi {
  proforma_invoice_uuid: string;
  quote_items: IRaiseCostingSheetItems[];
  status: "DC_COSTING_RAISE_APPROVAL_REQUESTED";
}

export const upsertMultipleCostingSheetsAsyncForPi =
  (
    payload: IUpsertMultipleCostingSheetsPayloadforPi,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/quotes/upsert-multiple-costing-sheets-for-pi",
        payload,
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Costing Sheet saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export interface IUpsertMultipleCostingSheetsPayload {
  proforma_invoice_uuid: string;
  quote_items: string[];
}
export const upsertMatchProductAsyncV2ForPi =
  (
    payload: IUpsertMultipleCostingSheetsPayload,
    onCallback: (isSuccess: boolean, quoteData?: IProformaInvoice) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      // const { create_ts, project_name, ...rest } = payload;
      const res = await api.post(
        "/quotes/match-products-from-costing-sheet-for-pi",
        payload,
      );
      const data: IProformaInvoice = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Matched successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertProformaInvoiceFromOrfAsync =
  (
    payload: IProformaInvoice,
    onCallback: (isSuccess: boolean, data?: IProformaInvoice) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        enquiry_uuid,
        quote_attachments,
        costing_attachments,
        approved_flag,
        approved_by,
        requested_by_uuid,
        approval_uuid,
        customer_address_district,
        delivery_address_district,
        customer_type,
        sign_off_url,
        project_name,
        transportation_charges,
        transportation_charges_value,
        other_charges,
        other_charges_value,
        ...restPayload
      } = payload;
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/quotes/upsert-proforma-invoice",
        restPayload,
      );
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Proforma Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const CLEAR_QUOTE_PROFORMA_INVOICE = "CLEAR_QUOTE_PROFORMA_INVOICE";
export const CLEAR_QUOTE_PROFORMA_INVOICE_LIST =
  "CLEAR_QUOTE_PROFORMA_INVOICE_LIST";
export const clearQuoteProformaInvoice = () =>
  action(CLEAR_QUOTE_PROFORMA_INVOICE);
export const clearQuoteProformaInvoiceList = () =>
  action(CLEAR_QUOTE_PROFORMA_INVOICE_LIST);

export interface IConvertToSOD {
  proforma_invoice_uuid: string;
  sod_type: string;
}

export interface ISodData {
  sod_type: string;
  sod_no: string;
}
export const convertPIToSODAsync =
  (
    payload: IConvertToSOD,
    onCallback: (isSuccess: boolean, sod_type?: ISodData) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.put("/quotes/convert-pi-to-sod", payload);
      onCallback(true, {
        sod_type: res.data.data.sod_type,
        sod_no: res.data.data.sod_no,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Converted PI to SOD successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const createProformaInvoiceRevisionAsync =
  (
    proforma_invoice_uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("quotes/create-proforma-invoice-revision", {
        proforma_invoice_uuid,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "PI Revised successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export interface ICreateOrderFromPI {
  enquiry_no: string;
  proforma_invoices: string[];
}

export const createOrderFromProformaInvoiceAsync =
  (
    payload: ICreateOrderFromPI,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/enquiry/raise-order-approval-request",
        payload,
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Order created successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
