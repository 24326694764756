import { action } from "typesafe-actions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  SERVER_GET_PRICE_UPDATION,
  SERVER_UPSERT_PRICE_UPDATION,
} from "./priceUpdation.route";
import { IPriceUpdation } from "./priceUpdation.types";

export const FETCH_PRICE_UPDATION_LIST_PROGRESS =
  "FETCH_PRICE_UPDATION_LIST_PROGRESS";
export const FETCH_PRICE_UPDATION_LIST_SUCCESS =
  "FETCH_PRICE_UPDATION_LIST_SUCCESS";
export const FETCH_PRICE_UPDATION_LIST_FAILED =
  "FETCH_PRICE_UPDATION_LIST_FAILED";

export const fetchPriceUpdationProgress = () =>
  action(FETCH_PRICE_UPDATION_LIST_PROGRESS);
export const fetchPriceUpdationSuccess = (
  data: IPriceUpdation[],
  totalRecords: number,
) => action(FETCH_PRICE_UPDATION_LIST_SUCCESS, { data, totalRecords });
export const fetchPriceUpdationFailed = () =>
  action(FETCH_PRICE_UPDATION_LIST_FAILED);

export const fetchPriceUpdationAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchPriceUpdationProgress());
      const res = await api.get(`${SERVER_GET_PRICE_UPDATION}?${searchQuery}`);

      const data: IPriceUpdation[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchPriceUpdationSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPriceUpdationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
export const FETCH_PRICE_UPDATION_PROGRESS = "FETCH_PRICE_UPDATION_PROGRESS";
export const FETCH_PRICE_UPDATION_SUCCESS = "FETCH_PRICE_UPDATION_SUCCESS";
export const FETCH_PRICE_UPDATION_FAILED = "FETCH_PRICE_UPDATION_FAILED";

export const fetchSinglePriceUpdationProgress = () =>
  action(FETCH_PRICE_UPDATION_PROGRESS);
export const fetchSinglePriceUpdationSuccess = (data: IPriceUpdation) =>
  action(FETCH_PRICE_UPDATION_SUCCESS, { data });
export const fetchSinglePriceUpdationFailed = () =>
  action(FETCH_PRICE_UPDATION_FAILED);

export const fetchSinglePriceUpdationAsync =
  (uuid: String): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchSinglePriceUpdationProgress());
      const res = await api.get(
        `${SERVER_GET_PRICE_UPDATION}?part_item_uuid=${uuid}`,
      );

      const data: IPriceUpdation = res.data.data[0];

      const totalRecords = res.data.totalRecords;

      dispatch(fetchSinglePriceUpdationSuccess(data));
    } catch (err: any) {
      dispatch(fetchSinglePriceUpdationFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchPriceUpdationByPartCodeAsync = async (partCode: string) => {
  try {
    const res = await api.get(
      `${SERVER_GET_PRICE_UPDATION}?part_item_code=${partCode}`,
    );
    return res.data.data[0];
  } catch (error) {
    return null;
  }
};

export const upsertSinglePriceUpdationAsync =
  (
    template: IPriceUpdation,
    onCallback: (isSuccess: boolean, data?: IPriceUpdation) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        created_by_name,
        modified_by_uuid,
        modified_by_name,
        running_no,
        price_updation_code,
        updated_date,
        updated_vendors_uuid,
        combined_part_code,
        ...payload
      } = template;

      const res = await api.post(SERVER_UPSERT_PRICE_UPDATION, payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Price update successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PRICE_UPDATION = "CLEAR_SINGLE_DRAWING_ITEM_";
export const CLEAR_PRICE_UPDATION_LIST = "CLEAR_PRICE_UPDATION_LIST";

export const clearSinglePriceUpdationAsync = () =>
  action(CLEAR_SINGLE_PRICE_UPDATION);
export const clearPriceUpdationListSync = () =>
  action(CLEAR_PRICE_UPDATION_LIST);
