import { LoadState } from "../../../constants/enums";
import {
  IVendorAttachment,
  IVendorBank,
  IVendorBranch,
  IVendorContact,
  IVendorDetailsState,
} from "./VendorTabs.types";

export const defaultVendorBranch: IVendorBranch = {
  vendors_uuid: "",
  vendors_name: "",
  vendors_branches_uuid: "",
  vendors_branches_name: "",
  gst_number: "",
  address_line1: "",
  address_line2: null,
  address_state: null,
  address_district: "",
  address_city: "",
  address_pincode: "",
  address_country: "",
  status: "",
  currency: "RUPEES",
  area: "DTZ",
  created_by_uuid: "",
  created_by_name: null,
  create_ts: "",
  insert_ts: "",
};
export const defaultVendorBank: IVendorBank = {
  vendors_uuid: "",
  vendors_name: "",
  vendors_bankdetails_uuid: "",
  bank_branches_name: null,
  vendors_branches_uuid: "",
  vendors_branches_name: "",
  bank_holders_name: "",
  bank_name: "",
  account_number: "",
  account_type: "",
  pan_number: "",
  ifsc_code: "",
  branch_code: null,
  bank_address: "",
  status: "",
  created_by_uuid: "",
  created_by_name: null,
  create_ts: "",
  insert_ts: "",
};
export const defaultVendorContact: IVendorContact = {
  vendors_uuid: "",
  vendors_name: "",
  vendors_branches_name: null,
  vendors_branches_uuid: null,
  vendors_contacts_uuid: "",
  contact_number: null,
  contact_person_name: null,
  contact_person_number: null,
  contact_person_designation: null,
  contact_person_email: null,
  status: "",
  created_by_uuid: "",
  create_ts: "",
  created_by_name: null,
  insert_ts: "",
};

export const defaultVendorAttachment: IVendorAttachment = {
  vendors_attachments_uuid: null,
  vendors_attachments_name: null,
  vendors_uuid: "",
  name_of_entity: "",
  attachment: "",
  status: "",
};

export const defaultVendorDetailsState: IVendorDetailsState = {
  branchList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },

  singleBranchData: {
    data: defaultVendorBranch,
    loading: LoadState.NotLoaded,
    error: null,
  },
  bankList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },

  singleBankData: {
    data: defaultVendorBank,
    loading: LoadState.NotLoaded,
    error: null,
  },
  contactList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },

  singleContactData: {
    data: defaultVendorContact,
    loading: LoadState.NotLoaded,
    error: null,
  },
  attachmentList: {
    list: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },
  singleAttachmentData: {
    data: defaultVendorAttachment,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
