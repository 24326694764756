import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { INcp, INcpState } from "./ncp.types";

export const FETCH_NCP_LIST_PROGRESS = "FETCH_NCP_LIST_PROGRESS";
export const FETCH_NCP_LIST_SUCCESS = "FETCH_NCP_LIST_SUCCESS";
export const FETCH_NCP_LIST_FAILED = "FETCH_NCP_LIST_FAILED";

export const fetchNcpListProgress = () => action(FETCH_NCP_LIST_PROGRESS);

export const fetchNcpListSuccess = (
  data: INcpState["list"]["data"],
  totalRecords: number,
) => action(FETCH_NCP_LIST_SUCCESS, { data, totalRecords });
export const fetchNcpListFailed = () => action(FETCH_NCP_LIST_FAILED);

export const fetchNcpListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchNcpListProgress());
      const res = await api.get(`/deviation/get-ncp?${searchQuery}`);
      const data: INcpState["list"]["data"] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchNcpListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchNcpListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_NCP_PROGRESS = "FETCH_NCP_PROGRESS";
export const FETCH_NCP_SUCCESS = "FETCH_NCP_SUCCESS";
export const FETCH_NCP_FAILED = "FETCH_NCP_FAILED";

export const fetchNcpProgress = () => action(FETCH_NCP_PROGRESS);
export const fetchNcpSuccess = (data: INcp) =>
  action(FETCH_NCP_SUCCESS, { data });
export const fetchNcpFailed = (errorMessage: string) =>
  action(FETCH_NCP_FAILED, { errorMessage });

export const fetchNcpAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchNcpProgress());
      const res = await api.get(`/deviation/get-ncp?ncp_uuid=${uuid}`);
      const data: INcp[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchNcpSuccess(data[0]));
      } else {
        dispatch(fetchNcpFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchNcpFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertNcpAsync =
  (
    data: INcp,
    onCallback: (isSuccess: boolean, deviation?: INcp) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/deviation/upsert-ncp", payload);
      let message = "NCP saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_NCP = "CLEAR_NCP";
export const CLEAR_NCP_STATE = "CLEAR_NCP_STATE";
export const clearNcp = () => action(CLEAR_NCP);
export const clearNcpState = () => action(CLEAR_NCP_STATE);
