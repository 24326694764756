import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../../constants/enums";
import { appNavRoutes } from "../../../constants/appNavRoutes";
import { approvalPath } from "../../approval/path";
const QuoteORFTableView = Loadable(
  lazy(() =>
    import("./QuoteORFTableView").then(({ QuoteORFTableView }) => ({
      default: QuoteORFTableView,
    })),
  ),
);
const ManageSingleORFForm = Loadable(
  lazy(() =>
    import("./ManageSingleORFForm").then(({ ManageSingleORFForm }) => ({
      default: ManageSingleORFForm,
    })),
  ),
);

const orfRoute = appNavRoutes.sales.orf;

export const orfRoutes = [
  {
    path: orfRoute,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORF]}>
        <QuoteORFTableView />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${orfRoute}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORF]}>
        <ManageSingleORFForm />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${approvalPath.approval.orf}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORF]}>
        <ManageSingleORFForm />
      </AuthorizedRoute>
    ),
  },
];
