import { LoadState } from "../../constants/enums";
import {
  IGrn,
  IGrnInvoiceItem,
  IGrnState,
  IPoReference,
} from "./warehouseGrn.types";

export const defaultGrnInvoiceItem: IGrnInvoiceItem = {
  id: 0,

  HSN_code: "",
  po_no: "",
  product_code: "",
  product_uuid: "",
  comment: "",
  quantity: 0,
  store_code: "",
  store_uuid: "",
  description: "",
  qc_check: false,
  per_pack_quantity: 0,
};
export const defaultPoReference: IPoReference = {
  po_no: "",
  po_uuid: "",
};
export const defaultGrn: IGrn = {
  grn_uuid: "",
  grn_type: null,
  grn_no: "",
  vendor_uuid: "",
  vendor_name: "",
  invoice_items: [defaultGrnInvoiceItem],
  total_amount: 0,
  is_round_off: 0,
  term_and_condition: null,
  total_amount_after_tax: 0,
  attachments: null,
  po_references: [],
  status: "ACTIVE",
  created_by_uuid: "",
  create_ts: "",
  insert_ts: "",
  combined_grn_no: "",
};

export const defaultGrnState: IGrnState = {
  grnList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  grn: {
    data: defaultGrn,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
