import { LoadState } from "../../constants/enums";
import { IPartItemPrefix, IPartItemPrefixState } from "./partItem-prefix.types";

export const defaultPartItemPrefix: IPartItemPrefix = {
  prefix_uuid: "",
  prefix_name: "",
  description: "",
  status: "ACTIVE",
};

export const defaultPartItemPrefixState: IPartItemPrefixState = {
  multiple_part_item_state: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_part_item_state: {
    data: defaultPartItemPrefix,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
