import { Box, Grid, InputBase, Typography, Button } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { ITableCommandBarProps } from "./interfaces/ITableCommandBarProps";
import React from "react";
import { useSearch } from "../../../../hooks";
import { TableSearchBar } from "../TableSearchBar/TableSearhBar";
import "../../DataTable.css";
import { borderColor } from "@mui/system";
import Card from "@mui/material/Card";
import { FilterList } from "@mui/icons-material";
import { AdvancedFilterDialog } from "../AdvancedFilterDialog/AdvancedFilterDialog";
import { useAdvancedFilter } from "../../hooks/useAdvancedFilter";

export const TableCommandBar: React.FC<ITableCommandBarProps> = (props) => {
  const { leftItems, rightItems, tableName } = props.tableCommandBarProps;
  const { onRowFilteredBySearch, columns, items, onApplyFilters } = props;
  const { isOpen, filters, openDialog, closeDialog, applyFilters } =
    useAdvancedFilter();

  React.useEffect(() => {
    if (onApplyFilters) {
      onApplyFilters(filters);
    }
  }, [filters, onApplyFilters]);

  const renderLeftItems = React.useMemo(() => {
    const plugins = leftItems.plugins;
    const customPlugins = leftItems.customPlugins;
    let leftNodes: React.ReactNode[] = [];
    if (plugins) {
      leftNodes = Object.keys(plugins).map((key) => {
        switch (key) {
          case "leftText":
            const text = plugins.leftText || "";

            return (
              <Box
                sx={{ mr: 1, mb: 1 }}
                display="flex"
                justifyContent={"flex-start"}
              >
                <Typography variant="h4" fontWeight="600">
                  {text}
                </Typography>
              </Box>
            );

          default:
            return <></>;
        }
      });
    }
    if (customPlugins) {
      customPlugins.forEach((customPlugin) => {
        leftNodes.push(
          <Box
            sx={{ mr: 1, mb: 1, p: "9px 0px" }}
            display="flex"
            justifyContent={"flex-start"}
          >
            {customPlugin.onRender(columns, items) || null}
          </Box>,
        );
      });
    }

    return leftNodes;
  }, [leftItems, columns, items]);

  const renderRightItems = React.useMemo(() => {
    const plugins = rightItems.plugins;
    const customPlugins = rightItems.customPlugins;
    let rightNodes: React.ReactNode[] = [];

    if (plugins) {
      Object.keys(plugins).forEach((key) => {
        switch (key) {
          case "searchField":
            const searchField = plugins.searchField;
            if (searchField) {
              rightNodes.push(
                <Box
                  sx={{ ml: 1, mb: 1 }}
                  display="flex"
                  justifyContent={"flex-start"}
                >
                  <TableSearchBar
                    items={searchField.items}
                    searchKeys={searchField.searchKeys}
                    onRowFilteredBySearch={onRowFilteredBySearch}
                  />
                </Box>,
              );
            }
            break;
        }
      });
    }
    if (customPlugins) {
      customPlugins.forEach((customPlugin) => {
        rightNodes.push(
          <Box
            sx={{ ml: 1, mb: 1 }}
            display="flex"
            justifyContent={"flex-start"}
          >
            {customPlugin.onRender(columns, items) || null}
          </Box>,
        );
      });
    }

    // Add Advanced Filter button if tableName is provided
    if (tableName) {
      rightNodes.push(
        <Box sx={{ ml: 1, mb: 1 }} display="flex" justifyContent={"flex-start"}>
          <Button
            startIcon={<FilterList fontSize="small" />}
            onClick={openDialog}
            variant="outlined"
            sx={{
              color: "#000",
              height: "40px",
              padding: "8px 16px",
              margin: "auto",
              "&:hover": {
                borderColor: "#000",
              },
            }}
          >
            Advanced Filter
          </Button>
        </Box>,
      );
    }

    return rightNodes;
  }, [rightItems, columns, items, tableName, openDialog]);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: 0,
          border: 0,
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 3px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box
          sx={{
            mb: 1,
            flex: 1,
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            flexWrap: { xs: "wrap", md: "unset" },
          }}
        >
          {renderLeftItems}
        </Box>
        <Box
          sx={{
            mb: 1,
            flex: 1,
            display: "flex",
            justifyContent: { xs: "center", lg: "flex-end" },
            flexWrap: { xs: "wrap", md: "unset" },
          }}
        >
          {renderRightItems}
        </Box>
      </Card>
      {tableName && (
        <AdvancedFilterDialog
          open={isOpen}
          onClose={closeDialog}
          onApply={applyFilters}
          tableName={tableName}
        />
      )}
    </>
  );
};
