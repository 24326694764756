import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { COSTING_SHEET_ROUTE } from "./CostingSheet.constant";
import { approvalPath } from "../approval/path";

const CostingSheetTable = Loadable(
  lazy(() =>
    import("./CostingSheetTable").then(({ CostingSheetTable }) => ({
      default: CostingSheetTable,
    })),
  ),
);
const ManageSingleCostingSheet = Loadable(
  lazy(() =>
    import("./ManageSingleCostingSheet").then(
      ({ ManageSingleCostingSheet }) => ({
        default: ManageSingleCostingSheet,
      }),
    ),
  ),
);

export const costingSheetRoutes = [
  {
    path: COSTING_SHEET_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COSTING_SHEET]}>
        <CostingSheetTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${COSTING_SHEET_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COSTING_SHEET]}>
        <ManageSingleCostingSheet />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${COSTING_SHEET_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COSTING_SHEET]}>
        <ManageSingleCostingSheet />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${approvalPath.approval.costingSheet.costingSheet}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COSTING_SHEET]}>
        <ManageSingleCostingSheet />
      </AuthorizedRoute>
    ),
  },
];
