import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../constants/enums";
import { PRICE_UPDATION_ROUTE } from "./PriceUpdation.constant";

const PartItemPrefixTableView = Loadable(
  lazy(() =>
    import("./PriceUpdationTableView").then(({ PriceUpdationTableView }) => ({
      default: PriceUpdationTableView,
    })),
  ),
);

export const priceUpdationRoutes = [
  {
    path: PRICE_UPDATION_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_PRICE_UPDATION]}>
        <PartItemPrefixTableView />
      </AuthorizedRoute>
    ),
  },
];
