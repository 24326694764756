import { action } from "typesafe-actions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IPartItemPrefix } from "./partItem-prefix.types";
import {
  SERVER_GET_PART_ITEM_PREFIX_ROUTE,
  SERVER_UPSERT_PART_ITEM_PREFIX_ROUTE,
} from "./partItem-prefix.router";

export const FETCH_PART_ITEM_PREFIX_LIST_PROGRESS =
  "FETCH_PART_ITEM_PREFIX_LIST_PROGRESS";
export const FETCH_PART_ITEM_PREFIX_LIST_SUCCESS =
  "FETCH_PART_ITEM_PREFIX_LIST_SUCCESS";
export const FETCH_PART_ITEM_PREFIX_LIST_FAILED =
  "FETCH_PART_ITEM_PREFIX_LIST_FAILED";

export const fetchPartItemPrefixProgress = () =>
  action(FETCH_PART_ITEM_PREFIX_LIST_PROGRESS);
export const fetchPartItemPrefixSuccess = (
  data: IPartItemPrefix[],
  totalRecords: number,
) => action(FETCH_PART_ITEM_PREFIX_LIST_SUCCESS, { data, totalRecords });
export const fetchPartItemPrefixFailed = () =>
  action(FETCH_PART_ITEM_PREFIX_LIST_FAILED);

export const fetchPartItemPrefixAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchPartItemPrefixProgress());
      const res = await api.get(
        `${SERVER_GET_PART_ITEM_PREFIX_ROUTE}?${searchQuery}`,
      );

      const data: IPartItemPrefix[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchPartItemPrefixSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPartItemPrefixFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSinglePartItemPrefixAsync =
  (
    template: IPartItemPrefix,
    onCallback: (isSuccess: boolean, data?: IPartItemPrefix) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        created_by_name,
        modified_by_uuid,
        modified_by_name,
        ...payload
      } = template;

      const res = await api.post(SERVER_UPSERT_PART_ITEM_PREFIX_ROUTE, payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Part Item Prefix saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PART_ITEM_PREFIX = "CLEAR_SINGLE_PART_ITEM_PREFIX";
export const CLEAR_PART_ITEM_PREFIX_LIST = "CLEAR_PART_ITEM_PREFIX_LIST";

export const clearSinglePartItemPrefixAsync = () =>
  action(CLEAR_SINGLE_PART_ITEM_PREFIX);
export const clearPartItemPrefixListSync = () =>
  action(CLEAR_PART_ITEM_PREFIX_LIST);
