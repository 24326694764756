import produce from "immer";
import { LoadState } from "../../constants/enums";

import { defaultStore, defaultStoreListState } from "./store.state";
import {
  CLEAR_STORE,
  CLEAR_STORE_STATE,
  FETCH_STORE_LIST_FAILED,
  FETCH_STORE_LIST_PROGRESS,
  FETCH_STORE_LIST_SUCCESS,
  FETCH_SINGLE_STORE_FAILED,
  FETCH_SINGLE_STORE_PROGRESS,
  FETCH_SINGLE_STORE_SUCCESS,
} from "./store.actions";
import { IStoreState } from "../initialStoreState";
import { StoreListActions } from ".";

export const storeListReducer = (
  state: IStoreState["storeList"] = defaultStoreListState,
  action: StoreListActions,
) => {
  switch (action.type) {
    case FETCH_STORE_LIST_PROGRESS:
      return produce(state, (draft) => {
        draft.store_list.loading = LoadState.InProgress;
        draft.store_list.data = [];
        draft.store_list.totalRecords = 0;
      });

    case FETCH_STORE_LIST_SUCCESS:
      return produce(state, (draft) => {
        draft.store_list.loading = LoadState.Loaded;
        draft.store_list.data = action.payload.data;
        draft.store_list.totalRecords = action.payload.totalRecords;
      });

    case FETCH_STORE_LIST_FAILED:
      return produce(state, (draft) => {
        draft.store_list.loading = LoadState.Failed;
      });

    case FETCH_SINGLE_STORE_PROGRESS:
      return produce(state, (draft) => {
        draft.single_store.loading = LoadState.InProgress;
      });

    case FETCH_SINGLE_STORE_SUCCESS:
      return produce(state, (draft) => {
        draft.single_store.loading = LoadState.Loaded;
        draft.single_store.data = action.payload.data;
      });

    case FETCH_SINGLE_STORE_FAILED:
      return produce(state, (draft) => {
        draft.single_store.loading = LoadState.Failed;
        draft.single_store.error = action.payload.errorMessage;
      });

    case CLEAR_STORE:
      return produce(state, (draft) => {
        draft.single_store.loading = LoadState.NotLoaded;
        draft.single_store.data = defaultStore;
        draft.single_store.error = null;
      });

    case CLEAR_STORE_STATE:
      return defaultStoreListState;

    default:
      return state;
  }
};
