import { LoadState } from "../../constants/enums";
import { IVendor, IVendorState } from "./vendors.types";

export const defaultVendor: IVendor = {
  vendors_uuid: null,
  name_of_entity: "",
  vendors_email: "",
  nature_of_business: null,
  purchase_mode: null,
  legal_status_of_party: null,
  type_of_service: null,
  gst_number: null,
  location: null,
  contact_number: null,
  currency: "RUPEES",
  area: "DTZ",
  alias: null,
  lut_no: null,
  MSME_no: null,

  status: "ACTIVE",
};

export const defaultVendorState: IVendorState = {
  vendor_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_vendor: {
    data: defaultVendor,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
