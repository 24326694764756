import produce from "immer";
import {
  CLEAR_PART_ITEM_PREFIX_LIST,
  FETCH_PART_ITEM_PREFIX_LIST_FAILED,
  FETCH_PART_ITEM_PREFIX_LIST_PROGRESS,
  FETCH_PART_ITEM_PREFIX_LIST_SUCCESS,
  IPartItemPrefixActions,
} from ".";
import { IStoreState } from "../initialStoreState";
import {
  defaultPartItemPrefix,
  defaultPartItemPrefixState,
} from "./partItem-prefix.state";
import { LoadState } from "../../constants/enums";

export const partItemPrefixReducer = (
  state: IStoreState["partItemPrefix"] = defaultPartItemPrefixState,
  action: IPartItemPrefixActions,
) => {
  switch (action.type) {
    //=============================== Rendering List ===============================
    case FETCH_PART_ITEM_PREFIX_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_part_item_state.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PART_ITEM_PREFIX_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_part_item_state.loading = LoadState.Loaded;
        draftState.multiple_part_item_state.data = data;
        draftState.multiple_part_item_state.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PART_ITEM_PREFIX_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_part_item_state.loading = LoadState.Failed;
        draftState.multiple_part_item_state.data = [];
        draftState.multiple_part_item_state.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_PART_ITEM_PREFIX_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_part_item_state.loading = LoadState.NotLoaded;
        draftState.multiple_part_item_state.data = [];
        draftState.multiple_part_item_state.totalRecords = 0;
        draftState.multiple_part_item_state.error = null;
      });
      return newState;
    }
    // ---------------------------------- Whole State ----------------------------------
    // case CLEAR_RENDERING_STATE: {
    // return defaultRenderingState;
    // }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
