import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  SERVER_GET_GATE_PASS_ROUTE,
  SERVER_UPSERT_GATE_PASS_ROUTE,
} from "./gatepass.routes";
import { IGatePass } from "./gatepass.types";

export const FETCH_GATE_PASS_LIST_PROGRESS = "FETCH_GATE_PASS_LIST_PROGRESS";
export const FETCH_GATE_PASS_LIST_SUCCESS = "FETCH_GATE_PASS_LIST_SUCCESS";
export const FETCH_GATE_PASS_LIST_FAILED = "FETCH_GATE_PASS_LIST_FAILED";

export const fetchGatePassListProgress = () =>
  action(FETCH_GATE_PASS_LIST_PROGRESS);

export const fetchGatePassListSuccess = (
  data: IGatePass[],
  totalRecords: number,
) => action(FETCH_GATE_PASS_LIST_SUCCESS, { data, totalRecords });
export const fetchGatePassListFailed = () =>
  action(FETCH_GATE_PASS_LIST_FAILED);

export const fetchGatePassListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchGatePassListProgress());
      const res = await api.get(`${SERVER_GET_GATE_PASS_ROUTE}?${searchQuery}`);
      const data: IGatePass[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchGatePassListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchGatePassListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_GATE_PASS_PROGRESS = "FETCH_GATE_PASS_PROGRESS";
export const FETCH_GATE_PASS_SUCCESS = "FETCH_GATE_PASS_SUCCESS";
export const FETCH_GATE_PASS_FAILED = "FETCH_GATE_PASS_FAILED";

export const fetchGatePassProgress = () => action(FETCH_GATE_PASS_PROGRESS);
export const fetchGatePassSuccess = (data: IGatePass) =>
  action(FETCH_GATE_PASS_SUCCESS, { data });
export const fetchGatePassFailed = (errorMessage: string) =>
  action(FETCH_GATE_PASS_FAILED, { errorMessage });

export const fetchSingleGatePassAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGatePassProgress());
      const res = await api.get(
        `${SERVER_GET_GATE_PASS_ROUTE}?gate_pass_uuid=${uuid}`,
      );
      const data: IGatePass[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchGatePassSuccess(data[0]));
      } else {
        dispatch(fetchGatePassFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchGatePassFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleGatePassAsync =
  (
    data: IGatePass,
    onCallback: (isSuccess: boolean, deviation?: IGatePass) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        gate_pass_no,
        modified_by_name,
        modified_by_uuid,
        created_by_name,
        created_by_uuid,
        combined_gate_pass_no,
        ...payload
      } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_GATE_PASS_ROUTE, payload);
      let message = "MRP Allocation saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_GATE_PASS = "CLEAR_GATE_PASS";
export const CLEAR_GATE_PASS_STATE = "CLEAR_GATE_PASS_STATE";
export const clearSingleGatePassSync = () => action(CLEAR_GATE_PASS);
export const clearGatePasssStateSync = () => action(CLEAR_GATE_PASS_STATE);
