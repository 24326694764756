import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultSalesDocument,
  defaultOrderDocumentState,
} from "./defaultState";
import {
  CLEAR_SALES_DOCUMENT,
  CLEAR_SALES_DOCUMENT_STATE,
  FETCH_SALES_DOCUMENT_FAILED,
  FETCH_SALES_DOCUMENT_LIST_FAILED,
  FETCH_SALES_DOCUMENT_LIST_PROGRESS,
  FETCH_SALES_DOCUMENT_LIST_SUCCESS,
  FETCH_SALES_DOCUMENT_PROGRESS,
  FETCH_SALES_DOCUMENT_SUCCESS,
} from "./orderDocument.actions";
import { OrderDocumentAction } from "./";

export const OrderDocumentReducer = (
  state: IStoreState["orderDocument"] = defaultOrderDocumentState,
  action: OrderDocumentAction,
) => {
  switch (action.type) {
    case FETCH_SALES_DOCUMENT_LIST_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
    }
    case FETCH_SALES_DOCUMENT_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      return produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
    }
    case FETCH_SALES_DOCUMENT_LIST_FAILED: {
      return produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
    }
    case FETCH_SALES_DOCUMENT_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.document.loading = LoadState.InProgress;
        draftState.document.data = defaultSalesDocument;
      });
    }
    case FETCH_SALES_DOCUMENT_SUCCESS: {
      const { data } = action.payload;
      return produce(state, (draftState) => {
        draftState.document.loading = LoadState.Loaded;
        draftState.document.data = data;
      });
    }
    case FETCH_SALES_DOCUMENT_FAILED: {
      return produce(state, (draftState) => {
        draftState.document.loading = LoadState.Failed;
      });
    }
    case CLEAR_SALES_DOCUMENT: {
      return produce(state, (draftState) => {
        draftState.document.loading = LoadState.NotLoaded;
        draftState.document.data = defaultSalesDocument;
        draftState.document.error = null;
      });
    }
    case CLEAR_SALES_DOCUMENT_STATE: {
      return defaultOrderDocumentState;
    }
    default: {
      return state;
    }
  }
};
