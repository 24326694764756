import produce from "immer";
import {
  CLEAR_DRAWING_ITEM_PREFIX_LIST,
  FETCH_DRAWING_ITEM_PREFIX_LIST_FAILED,
  FETCH_DRAWING_ITEM_PREFIX_LIST_PROGRESS,
  FETCH_DRAWING_ITEM_PREFIX_LIST_SUCCESS,
  IDrawingItemPrefixActions,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultDrawingitemPrefixState } from "./drawing-prefix.state";

export const drawingItemPrefixReducer = (
  state: IStoreState["drawingItemPrefix"] = defaultDrawingitemPrefixState,
  action: IDrawingItemPrefixActions,
) => {
  switch (action.type) {
    //=============================== Rendering List ===============================
    case FETCH_DRAWING_ITEM_PREFIX_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_drawing_item_state.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_DRAWING_ITEM_PREFIX_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_drawing_item_state.loading = LoadState.Loaded;
        draftState.multiple_drawing_item_state.data = data;
        draftState.multiple_drawing_item_state.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_DRAWING_ITEM_PREFIX_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_drawing_item_state.loading = LoadState.Failed;
        draftState.multiple_drawing_item_state.data = [];
        draftState.multiple_drawing_item_state.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_DRAWING_ITEM_PREFIX_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_drawing_item_state.loading = LoadState.NotLoaded;
        draftState.multiple_drawing_item_state.data = [];
        draftState.multiple_drawing_item_state.totalRecords = 0;
        draftState.multiple_drawing_item_state.error = null;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
