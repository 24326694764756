import { LoadState } from "../../constants/enums";
import {
  IBatch,
  IBatchState,
  ICancelBatch,
  IItemDetails,
  ISodDetail,
  IVerifyBatch,
} from "./batch.types";

export const defaultItemDetail: IItemDetails = {
  part_item: "",
  part_item_uuid: "",
  fg_code: "",
  batch_quantity: 0,
  orderIdentifier: "",
  description: "",
  bdl_qty: 0,
  bom_uuid: null,
  routing_uuid: null,
  reserved_quantity: null,
};

export const defaultCancelBatch: ICancelBatch = {
  batch_uuid: "",
  cancel_note: "",
};

export const defaultSodDetail: ISodDetail = {
  sod_no: "",
  order_uuid: "",
};

export const defaultVerifyBatch: IVerifyBatch = {
  bom_progess: null,
  routing_progess: null,
  sod_no: "",
  part_code: "",
  bom_uuid: null,
  routing_uuid: null,
};

export const defaultBatch: IBatch = {
  batch_uuid: null,
  sod_type: "",
  sod_no: "",
  sod_details: [defaultSodDetail],
  item_details: [],
  batch_prefix: "PB",
  status: "ACTIVE",
  created_by_uuid: "",
  batch_number: 0,
  rev_no: 0,
  created_by_name: "",
  create_ts: "",
};

export const defaultBatchState: IBatchState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  batch: {
    loading: LoadState.NotLoaded,
    data: defaultBatch,
    error: null,
  },
  verifyBatch: {
    loading: LoadState.NotLoaded,
    data: defaultVerifyBatch,
    error: null,
  },
};
