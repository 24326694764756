import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../constants/enums";
import { PART_ITEM_PREFIX_ROUTE } from "./partItem.constant";

const PartItemPrefixTableView = Loadable(
  lazy(() =>
    import("./PartItemPrefixTableView").then(({ PartItemPrefixTableView }) => ({
      default: PartItemPrefixTableView,
    })),
  ),
);

export const partItemPrefixRoutes = [
  {
    path: PART_ITEM_PREFIX_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PART_PREFIX]}>
        <PartItemPrefixTableView />
      </AuthorizedRoute>
    ),
  },
];
