import { IEnquiryORF } from "./enquiry-orf.types";

export const defaultEnquiryORF: IEnquiryORF = {
  orf_uuid: null,
  orf_prefix: "",
  project_name: "",
  enquiry_no: "",
  enquiry_uuid: "",
  client_location: null,
  category: "",
  iec_no: null,
  gst_no: null,
  pan_no: null,
  contact_name: "",
  contact_uuid: "",
  contact_number: "",
  po_value: null,
  mail_id: "",
  po_no: null,
  po_date: null,
  po_basis_del_date: null,
  customer_name: "",
  customer_uuid: "",
  customer_address_line1: "",
  customer_address_line2: "",
  customer_address_state: "",
  customer_address_city: "",
  customer_address_pincode: "",
  customer_address_country: "",
  delivery_address_line1: "",
  delivery_address_line2: "",
  delivery_address_city: "",
  delivery_address_state: "",
  delivery_address_pincode: "",
  delivery_address_country: "",
  payment_mode: "",
  amount: "",
  amount_in_words: "",
  expected_dispatch_date: null,
  expected_handover_date: null,
  payment_term: [{ key: null, value: null }],
  officer_name: "",
  officer_uuid: "",
  status: "ORF_REQUESTED",
  quote_items: [],
};
