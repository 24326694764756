import React from "react";
import { IAdvancedFilter } from "../interfaces/IDataTableProps";
import { IDataTableV2SearchFilterSearchItem } from "../../../redux/common/common.types";

interface IAdvancedFilterState {
  isOpen: boolean;
  filters: IDataTableV2SearchFilterSearchItem[];
}

export const useAdvancedFilter = () => {
  const [state, setState] = React.useState<IAdvancedFilterState>({
    isOpen: false,
    filters: [],
  });

  const openDialog = () => {
    setState((prev) => ({ ...prev, isOpen: true }));
  };

  const closeDialog = () => {
    setState((prev) => ({ ...prev, isOpen: false }));
  };

  const applyFilters = (filters: IDataTableV2SearchFilterSearchItem[]) => {
    setState((prev) => ({ ...prev, filters }));
  };

  const clearFilters = () => {
    setState((prev) => ({ ...prev, filters: [] }));
  };

  return {
    isOpen: state.isOpen,
    filters: state.filters,
    openDialog,
    closeDialog,
    applyFilters,
    clearFilters,
  };
};
