import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { IAssembly } from "./assembly.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";

export const FETCH_ASSEMBLY_LIST_PROGRESS = "FETCH_ASSEMBLY_LIST_PROGRESS";
export const FETCH_ASSEMBLY_LIST_SUCCESS = "FETCH_ASSEMBLY_LIST_SUCCESS";
export const FETCH_ASSEMBLY_LIST_FAILED = "FETCH_ASSEMBLY_LIST_FAILED";

export const fetchAssemblyListProgress = () =>
  action(FETCH_ASSEMBLY_LIST_PROGRESS);
export const fetchAssemblyListSuccess = (
  data: IAssembly[],
  totalRecords: number,
) => action(FETCH_ASSEMBLY_LIST_SUCCESS, { data, totalRecords });
export const fetchAssemblyListFailed = () => action(FETCH_ASSEMBLY_LIST_FAILED);

export const fetchAssemblyListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchAssemblyListProgress());
      const res = await api.get(`/routing/get-assembly?${searchQuery}`);
      dispatch(fetchAssemblyListSuccess(res.data.data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchAssemblyListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SINGLE_ASSEMBLY_PROGRESS = "FETCH_SINGLE_ASSEMBLY_PROGRESS";
export const FETCH_SINGLE_ASSEMBLY_SUCCESS = "FETCH_SINGLE_ASSEMBLY_SUCCESS";
export const FETCH_SINGLE_ASSEMBLY_FAILED = "FETCH_SINGLE_ASSEMBLY_FAILED";

export const fetchSingleAssemblyProgress = () =>
  action(FETCH_SINGLE_ASSEMBLY_PROGRESS);
export const fetchSingleAssemblySuccess = (data: IAssembly) =>
  action(FETCH_SINGLE_ASSEMBLY_SUCCESS, { data });
export const fetchSingleAssemblyFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_ASSEMBLY_FAILED, { errorMessage });

export const fetchSingleAssemblyAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchSingleAssemblyProgress());
      const res = await api.get(`/routing/get-assembly?assembly_uuid=${uuid}`);
      const data: IAssembly[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleAssemblySuccess(data[0]));
      } else {
        dispatch(fetchSingleAssemblyFailed("No assembly found"));
      }
    } catch (err: any) {
      dispatch(fetchSingleAssemblyFailed("Something went wrong"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertAssemblyAsync =
  (
    assembly: IAssembly,
    onCallback: (isSuccess: boolean, assembly?: IAssembly) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        modified_by_name,
        modified_by_uuid,
        created_by_name,
        created_by_uuid,
        ...payload
      } = assembly;

      const res = await api.post("/routing/upsert-assembly", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Assembly saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_ASSEMBLY = "CLEAR_SINGLE_ASSEMBLY";
export const CLEAR_ASSEMBLY_LIST = "CLEAR_ASSEMBLY_LIST";
export const clearSingleAssemblyAsync = () => action(CLEAR_SINGLE_ASSEMBLY);
export const clearAssemblyListAsync = () => action(CLEAR_ASSEMBLY_LIST);

// ... I'll continue with more actions in the next part ...
