import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../../constants/enums";
import { appNavRoutes } from "../../../constants/appNavRoutes";

const ApprovedOrdersTableView = Loadable(
  lazy(() =>
    import("./ApprovedOrdersTableView").then(({ ApprovedOrdersTableView }) => ({
      default: ApprovedOrdersTableView,
    })),
  ),
);

const approvedOrdersRoute = appNavRoutes.sales.approvedOrders;
export const approvedOrdersRoutes = [
  {
    path: approvedOrdersRoute,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVED_ORDER]}>
        <ApprovedOrdersTableView />
      </AuthorizedRoute>
    ),
  },
];
