import { LoadState } from "../../constants/enums";
import { IRouting, IRoutingProduct, IRoutingState } from "./routing.types";

export const defaultRoutingProduct: IRoutingProduct = {
  serial_no: "",
  unit: "",
  level: "",
  part_code: "",
  drawing: "",
  part_name: "",
  product_size_h: "",
  product_size_d: "",
  product_size_w: "",
  box_size_h: "",
  box_size_d: "",
  box_size_w: "",
  weight: "",
  volume: "",
  meaning: "",
  kept_position: "",
  item_type: "",
  quantity_per_unit: "",
  cost: "",
  colour: "",
  routing_front_end_1: "",
  routing_front_end_2: "",
  routing_front_end_3: "",
  routing_front_end_4: "",
  routing_front_end_5: "",
  routing_front_end_6: "",
  routing_front_end_7: "",
  routing_front_end_8: "",
  routing_front_end_9: "",
  routing_front_end_10: "",
  routing_front_end_1_estd_time: null,
  routing_front_end_2_estd_time: null,
  routing_front_end_3_estd_time: null,
  routing_front_end_4_estd_time: null,
  routing_front_end_5_estd_time: null,
  routing_front_end_6_estd_time: null,
  routing_front_end_7_estd_time: null,
  routing_front_end_8_estd_time: null,
  routing_front_end_9_estd_time: null,
  routing_front_end_10_estd_time: null,
  routing_back_end_1: "",
  routing_back_end_2: "",
  routing_back_end_3: "",
  routing_back_end_4: "",
  routing_back_end_5: "",
  routing_back_end_6: "",
  routing_back_end_7: "",
  routing_back_end_8: "",
  routing_back_end_9: "",
  routing_back_end_10: "",
  routing_back_end_1_estd_time: null,
  routing_back_end_2_estd_time: null,
  routing_back_end_3_estd_time: null,
  routing_back_end_4_estd_time: null,
  routing_back_end_5_estd_time: null,
  routing_back_end_6_estd_time: null,
  routing_back_end_7_estd_time: null,
  routing_back_end_8_estd_time: null,
  routing_back_end_9_estd_time: null,
  routing_back_end_10_estd_time: null,
  bom_for_line: "",
  color: "",
};
export const initialRouting: IRouting = {
  routing_uuid: null,
  order_no: "",
  bom_uuid: null,
  product_code: null,
  product_size: null,
  series: null,
  rev_no: null,
  effective_date: null,
  description: null,
  add: null,
  change: null,
  delete: null,
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  excel_link: null,
  product_items: [defaultRoutingProduct],
  status: "ROUTING_REQUESTED",
  creation_status: "",
};

export const initialRoutingState: IRoutingState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  routing: {
    data: initialRouting,
    loading: LoadState.NotLoaded,
    error: null,
  },
  routing_approvals: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  order_existing_routings: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
