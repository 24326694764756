import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IVendor, IVendorState } from "./vendors.types";
import {
  SERVER_GET_VENDOR_ROUTE,
  SERVER_UPSERT_VENDOR_ROUTE,
} from "./vendors.routes";

export const FETCH_VENDOR_LIST_PROGRESS = "FETCH_VENDOR_LIST_PROGRESS";
export const FETCH_VENDOR_LIST_SUCCESS = "FETCH_VENDOR_LIST_SUCCESS";
export const FETCH_VENDOR_LIST_FAILED = "FETCH_VENDOR_LIST_FAILED";

export const fetchVendorListProgress = () => action(FETCH_VENDOR_LIST_PROGRESS);

export const fetchVendorListSuccess = (
  data: IVendorState["vendor_list"]["data"],
  totalRecords: number,
) => action(FETCH_VENDOR_LIST_SUCCESS, { data, totalRecords });
export const fetchVendorListFailed = () => action(FETCH_VENDOR_LIST_FAILED);

export const fetchVendorListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchVendorListProgress());
      const res = await api.get(`${SERVER_GET_VENDOR_ROUTE}?${searchQuery}`);
      const data: IVendorState["vendor_list"]["data"] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchVendorListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchVendorListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_VENDOR_PROGRESS = "FETCH_VENDOR_PROGRESS";
export const FETCH_VENDOR_SUCCESS = "FETCH_VENDOR_SUCCESS";
export const FETCH_VENDOR_FAILED = "FETCH_VENDOR_FAILED";

export const fetchVendorProgress = () => action(FETCH_VENDOR_PROGRESS);
export const fetchVendorSuccess = (data: IVendor) =>
  action(FETCH_VENDOR_SUCCESS, { data });
export const fetchVendorFailed = (errorMessage: string) =>
  action(FETCH_VENDOR_FAILED, { errorMessage });

export const fetchSingleVendorAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorProgress());
      const res = await api.get(
        `${SERVER_GET_VENDOR_ROUTE}?vendors_uuid=${uuid}`,
      );
      const data: IVendor[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchVendorSuccess(data[0]));
      } else {
        dispatch(fetchVendorFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchVendorFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleVendorAsync =
  (
    data: IVendor,
    onCallback: (isSuccess: boolean, deviation?: IVendor) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        legal_status_of_party,
        type_of_service,
        gst_number,

        ...payload
      } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_VENDOR_ROUTE, payload);
      let message = "MRP Allocation saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_VENDOR = "CLEAR_VENDOR";
export const CLEAR_VENDOR_STATE = "CLEAR_VENDOR_STATE";
export const clearSingleVendorSync = () => action(CLEAR_VENDOR);
export const clearVendorsStateSync = () => action(CLEAR_VENDOR_STATE);
