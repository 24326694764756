import { LoadState } from "../../constants/enums";
import { defaultEnquiryMRF } from "./enquiry-mrf";
import { defaultEnquiryORF } from "./enquiry-orf";
import { defaultEnquiryQRF } from "./enquiry-qrf";
import { IEnquiry, IEnquiryState } from "./enquiry.types";
import { defaultProject } from "./project";

export const defaultEnquiry: IEnquiry = {
  customer_uuid: null,
  assigned_to_uuid: null,
  assigned_to_name: null,
  secondary_assigned_to_uuid: null,
  secondary_assigned_to_name: null,
  contact_uuid: "",
  enquiry_no: "",
  enquiry_uuid: null,
  design_coordinator_uuid: null,
  design_coordinator_name: null,
  project_uuid: null,
  order_value: null,
  // project_name: "",
  sales_person: "",
  designer: "",
  industry: "",
  customer_name: "",
  contact_person: "",
  contact_email: "",

  order_type: null,
  order_approx_value: null,
  // design_cordinator: "",
  // date_of_closure: null,
  probability: null,
  probability_status: "",
  product_category: null,
  product_type: null,
  appx_no_of_workstation: null,
  enquiry_details: "",
  remarks: "",
  win_loss: "",
  reason_for_loss: "",
  customer_address_city: "",
  customer_address_district: "",
  customer_address_country: "",
  customer_address_line1: "",
  customer_address_line2: "",
  customer_address_pincode: "",
  customer_address_state: "",
  status: "REQUEST_FOR_QUOTE",
  communication_status: "",
  sales_invoice_zone: null,
  //pi
  proforma_invoice_uuid: null,

  // delivery address fields
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_district: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  created_by_name: null,
  created_by_uuid: null,
  modified_by_name: null,
  modified_by_uuid: null,

  approval_uuid: null,
};

export const defaultEnquiryState: IEnquiryState = {
  projectId: null,
  enquiryStatus: "NEW",
  project: {
    data: defaultProject,
    loading: LoadState.NotLoaded,
    error: null,
  },
  project_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  enquiry_documents: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  single_enquiry: {
    data: defaultEnquiry,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_history: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_emails: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_checkList: {
    data: [],
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_orf: {
    orfData: defaultEnquiryORF,
    orfLoading: LoadState.NotLoaded,
    orfError: null,
  },
  enquiry_orf_ist: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_grouped_orf_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_qrf: {
    data: defaultEnquiryQRF,
    loading: LoadState.NotLoaded,
    error: null,
  },
  enquiry_mrf: {
    data: defaultEnquiryMRF,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
