import { action } from "typesafe-actions";
import { ICostingSheet, ICostingSheetFor } from "./costingSheet.types";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadFile } from "../../helpers/uploadFile";
import { IRaiseCostingSheetItems } from "../../views/quotes/dialogs/RaiseCostingSheetDialog/RaiseCostingSheetDialog.types";
// ********************** Fetch Costing Sheet List********************** //
export const FETCH_COSTING_SHEET_LIST_PROGRESS =
  "FETCH_COSTING_SHEET_LIST_PROGRESS";
export const FETCH_COSTING_SHEET_LIST_SUCCESS =
  "FETCH_COSTING_SHEET_LIST_SUCCESS";
export const FETCH_COSTING_SHEET_LIST_FAILED =
  "FETCH_COSTING_SHEET_LIST_FAILED";

export const fetchCostingSheetListProgress = () =>
  action(FETCH_COSTING_SHEET_LIST_PROGRESS);
export const fetchCostingSheetListSuccess = (
  data: ICostingSheet[],
  totalRecords: number,
) => action(FETCH_COSTING_SHEET_LIST_SUCCESS, { data, totalRecords });
export const fetchCostingSheetListFailed = () =>
  action(FETCH_COSTING_SHEET_LIST_FAILED);

export const fetchCostingSheetListAsync =
  (
    queryParams: ISearchQueryParams,
    enq_no?: string,
    costingSheet_uuid?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    console.log(costingSheet_uuid, "costing.............");

    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchCostingSheetListProgress());
      let finalUrl = `/quotes/${enq_no ? "get-all-costing-sheet?" : "get-costing-sheet?"}${searchQuery}`;
      if (enq_no) {
        finalUrl += `&enquiry_no=${enq_no}`;
      }

      if (costingSheet_uuid) {
        finalUrl += `&costing_sheet_uuid=${costingSheet_uuid}`;
      }
      const res = await api.get(finalUrl);
      const data: ICostingSheet[] = res.data.data.result;
      const totalRecords = res.data.totalRecords;
      const status_count = res.data.totalRecords;
      dispatch(fetchCostingSheetListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCostingSheetListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Fetch Single Costing Sheet ********************** //
export const FETCH_COSTING_SHEET_PROGRESS = "FETCH_COSTING_SHEET_PROGRESS";
export const FETCH_COSTING_SHEET_SUCCESS = "FETCH_COSTING_SHEET_SUCCESS";
export const FETCH_COSTING_SHEET_FAILED = "FETCH_COSTING_SHEET_FAILED";

export const fetchCostingSheetProgress = () =>
  action(FETCH_COSTING_SHEET_PROGRESS);
export const fetchCostingSheetSuccess = (data: ICostingSheet) =>
  action(FETCH_COSTING_SHEET_SUCCESS, { data });
export const fetchCostingSheetFailed = (errorMessage: string) =>
  action(FETCH_COSTING_SHEET_FAILED, { errorMessage });

export const fetchCostingSheetAsync =
  (costingSheetUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCostingSheetProgress());
      const res = await api.get(
        `/quotes/get-costing-sheet?costing_sheet_uuid=${costingSheetUUID}`,
      );
      const data: ICostingSheet[] = res.data.data.result;

      if (data.length > 0) {
        dispatch(fetchCostingSheetSuccess(data[0]));
      } else {
        dispatch(
          fetchCostingSheetFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchCostingSheetFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Fetch Single Costing Sheet By Enquiry ********************** //

export const FETCH_COSTING_SHEET_BY_ENQ_PROGRESS =
  "FETCH_COSTING_SHEET_BY_ENQ_PROGRESS";
export const FETCH_COSTING_SHEET_BY_ENQ_SUCCESS =
  "FETCH_COSTING_SHEET_BY_ENQ_SUCCESS";
export const FETCH_COSTING_SHEET__BY_ENQ_FAILED =
  "FETCH_COSTING_SHEET__BY_ENQ_FAILED";

export const fetchCostingSheetByEnqProgress = () =>
  action(FETCH_COSTING_SHEET_BY_ENQ_PROGRESS);
export const fetchCostingSheetByEnqSuccess = (data: ICostingSheet[]) =>
  action(FETCH_COSTING_SHEET_BY_ENQ_SUCCESS, { data });
export const fetchCostingSheetByEnqFailed = (errorMessage: string) =>
  action(FETCH_COSTING_SHEET__BY_ENQ_FAILED, { errorMessage });

export const fetchCostingSheetByEnqAsync =
  (enquiryUUId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCostingSheetByEnqProgress());
      const res = await api.get(
        `/quotes/get-costing-sheet?enquiry_no=${enquiryUUId}`,
      );
      const data: ICostingSheet[] = res.data.data.result;
      if (data.length > 0) {
        dispatch(fetchCostingSheetByEnqSuccess(data));
      } else {
        dispatch(
          fetchCostingSheetByEnqFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchCostingSheetByEnqFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Upsert Multiple Costing Sheets By Enquiry ********************** //

export interface IUpsertMultipleCostingSheetsPayload {
  quote_uuid: string;
  quote_items: IRaiseCostingSheetItems[];
  status: "DC_COSTING_RAISE_APPROVAL_REQUESTED";
}

export const upsertMultipleCostingSheetsAsync =
  (
    payload: IUpsertMultipleCostingSheetsPayload,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        "/quotes/upsert-multiple-costing-sheets",
        payload,
      );
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Costing Sheet saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Upsert Costing Sheet By Enquiry ********************** //

export const upsertCostingSheetAsync =
  (
    values: ICostingSheet,
    file: any | null,
    onCallback: (isSuccess: boolean, costingSheet?: ICostingSheet) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        insert_ts,
        create_ts,
        incremental_column,
        approval_uuid,
        approved_by_name,
        modified_by_uuid,
        modified_by_name,
        created_by_name,
        created_by_uuid,
        remark,
        costing_sheet_no,
        combined_costing_sheet_no,
        ...rest
      } = values;
      let payload = {
        ...rest,
      };
      if (file) {
        const asPayload = {
          enquiry_no: values.enquiry_no,
          document_type: "Other",
        };
        const path = await uploadFile(
          file,
          "ENQUIRY",
          values.filePath || "",
          asPayload as any,
        );
        payload.filePath = path;
        await api.post("/enquiry/upsert-enquiry-attachment", {
          enquiry_attachment_uuid: null,
          enquiry_no: values.enquiry_no,
          document_type: "Other",
          description: "Upload From Costing Sheet",
          file_url: path,
        });
      }
      const res = await api.post("/quotes/upsert-costing-sheet", payload);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Costing Sheet saved successfully!",
          displayAs: "snackbar",
        }),
      );
      dispatch(fetchCostingSheetSuccess(data));
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const costingSheetPreview =
  (
    costingSheetUUID: string,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/quotes/get-costing-sheet-preview?costing_sheet_uuid=${costingSheetUUID}&isPreview=true`,
      );

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const costingSheetDownloadPdf =
  (
    costingSheetUUID: string,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // dispatch(saveLoaderProgress());

      const res = await api.get(
        `/quotes/get-costing-sheet-preview?costing_sheet_uuid=${costingSheetUUID}&isPreview=false`,
        {
          responseType: "arraybuffer",
        },
      );

      dispatch(saveLoaderCompleted());
      // Convert the PDF content into a Blob
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const CLEAR_COSTING_SHEET = "CLEAR_COSTING_SHEET";
export const CLEAR_COSTING_SHEET_STATE = "CLEAR_COSTING_SHEET_STATE";

export const clearCostingSheet = () => action(CLEAR_COSTING_SHEET);
export const clearCostingSheetState = () => action(CLEAR_COSTING_SHEET_STATE);

// ********************** Fetch Approval Costing Sheet List********************** //
export const FETCH_APPROVAL_COSTING_SHEET_LIST_PROGRESS =
  "FETCH_APPROVAL_COSTING_SHEET_LIST_PROGRESS";
export const FETCH_APPROVAL_COSTING_SHEET_LIST_SUCCESS =
  "FETCH_APPROVAL_COSTING_SHEET_LIST_SUCCESS";
export const FETCH_APPROVAL_COSTING_SHEET_LIST_FAILED =
  "FETCH_APPROVAL_COSTING_SHEET_LIST_FAILED";

export const fetchApprovalCostingSheetListProgress = () =>
  action(FETCH_APPROVAL_COSTING_SHEET_LIST_PROGRESS);
export const fetchApprovalCostingSheetListSuccess = (
  data: ICostingSheet[],
  totalRecords: number,
) => action(FETCH_APPROVAL_COSTING_SHEET_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalCostingSheetListFailed = () =>
  action(FETCH_APPROVAL_COSTING_SHEET_LIST_FAILED);

export const fetchApprovalCostingSheetListAsync =
  (
    queryParams: IQueryParams,
    costingSheetType:
      | "costing-sheet"
      | "costing-sheet-raise"
      | "costing-sheet-acknowledgement",
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalCostingSheetListProgress());
      const res = await api.get(
        `/approval/get-approval${searchQuery}&table_name=latest_costing_sheet&status=REQUESTED`,
      );
      const data: ICostingSheet[] = res.data.data;
      const statusMapping = {
        "costing-sheet": "COSTING_SHEET_APPROVAL_REQUESTED",
        "costing-sheet-raise": "DC_COSTING_RAISE_APPROVAL_REQUESTED",
        "costing-sheet-acknowledgement": "ACKNOWLEDGMENT_APPROVAL_REQUESTED",
      };

      const status = statusMapping[costingSheetType] || "";

      const filteredData = data.filter((item) => item.status === status);
      const totalRecords = res.data.totalRecords;
      dispatch(
        fetchApprovalCostingSheetListSuccess(filteredData, totalRecords),
      );
    } catch (err: any) {
      dispatch(fetchApprovalCostingSheetListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_APPROVAL_COSTING_SHEET = "CLEAR_APPROVAL_COSTING_SHEET";

export const clearApprovalCostingSheetListAsync = () =>
  action(CLEAR_APPROVAL_COSTING_SHEET);
