import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../../constants/enums";
import { appNavRoutes } from "../../../constants/appNavRoutes";

const OrderApprovalRequestTableView = Loadable(
  lazy(() =>
    import(
      "../../approval/approvalOrderRequest/OrderApprovalRequestTableView"
    ).then(({ OrderApprovalRequestTableView }) => ({
      default: OrderApprovalRequestTableView,
    })),
  ),
);

const OrdersTableView = Loadable(
  lazy(() =>
    import("./OrdersTableView").then(({ OrdersTableView }) => ({
      default: OrdersTableView,
    })),
  ),
);

const ManageSingleOrderForm = Loadable(
  lazy(() =>
    import("./ManageSingleOrderForm").then(({ ManageSingleOrderForm }) => ({
      default: ManageSingleOrderForm,
    })),
  ),
);

const ordersRoute = appNavRoutes.sales.orders;
const orderApprovalsRoute = appNavRoutes.approvals.orders;
export const ordersRoutes = [
  {
    path: ordersRoute,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
        <OrdersTableView />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${ordersRoute}/view/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
        <ManageSingleOrderForm />
      </AuthorizedRoute>
    ),
  },
  {
    path: orderApprovalsRoute,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_ORDER_PROCESSING]}>
        <OrderApprovalRequestTableView />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${orderApprovalsRoute}/view/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_ORDER_PROCESSING]}>
        <ManageSingleOrderForm />
      </AuthorizedRoute>
    ),
  },
];
