import produce from "immer";
import { LoadState } from "../../constants/enums";

import {
  defaultStoreTransfer,
  defaultStoreTransferState,
} from "./store-transfer.state";
import {
  CLEAR_STORE_TRANSFER,
  CLEAR_STORE_TRANSFER_STATE,
  FETCH_STORE_TRANSFER_LIST_FAILED,
  FETCH_STORE_TRANSFER_LIST_PROGRESS,
  FETCH_STORE_TRANSFER_LIST_SUCCESS,
  FETCH_SINGLE_STORE_TRANSFER_FAILED,
  FETCH_SINGLE_STORE_TRANSFER_PROGRESS,
  FETCH_SINGLE_STORE_TRANSFER_SUCCESS,
} from "./store-transfer.actions";
import { IStoreState } from "../initialStoreState";
import { StoreTransferActions } from ".";

export const storeTransferReducer = (
  state: IStoreState["store_transfer"] = defaultStoreTransferState,
  action: StoreTransferActions,
) => {
  switch (action.type) {
    case FETCH_STORE_TRANSFER_LIST_PROGRESS:
      return produce(state, (draft) => {
        draft.store_transfer_list.loading = LoadState.InProgress;
        draft.store_transfer_list.data = [];
        draft.store_transfer_list.totalRecords = 0;
      });

    case FETCH_STORE_TRANSFER_LIST_SUCCESS:
      return produce(state, (draft) => {
        draft.store_transfer_list.loading = LoadState.Loaded;
        draft.store_transfer_list.data = action.payload.data;
        draft.store_transfer_list.totalRecords = action.payload.totalRecords;
      });

    case FETCH_STORE_TRANSFER_LIST_FAILED:
      return produce(state, (draft) => {
        draft.store_transfer_list.loading = LoadState.Failed;
      });

    case FETCH_SINGLE_STORE_TRANSFER_PROGRESS:
      return produce(state, (draft) => {
        draft.single_store_transfer.loading = LoadState.InProgress;
      });

    case FETCH_SINGLE_STORE_TRANSFER_SUCCESS:
      return produce(state, (draft) => {
        draft.single_store_transfer.loading = LoadState.Loaded;
        draft.single_store_transfer.data = action.payload.data;
      });

    case FETCH_SINGLE_STORE_TRANSFER_FAILED:
      return produce(state, (draft) => {
        draft.single_store_transfer.loading = LoadState.Failed;
        draft.single_store_transfer.error = action.payload.errorMessage;
      });

    case CLEAR_STORE_TRANSFER:
      return produce(state, (draft) => {
        draft.single_store_transfer.loading = LoadState.NotLoaded;
        draft.single_store_transfer.data = defaultStoreTransfer;
        draft.single_store_transfer.error = null;
      });

    case CLEAR_STORE_TRANSFER_STATE:
      return defaultStoreTransferState;

    default:
      return state;
  }
};
