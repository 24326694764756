import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { ISearchQueryParams } from "../common/common.types";
import { getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  showMessage,
  saveLoaderProgress,
  saveLoaderCompleted,
} from "../messages/messagesActions";
import { IStoreTransfer } from "./store-transfer.types";
import {
  SERVER_GET_STORE_TRANSFER_ROUTE,
  SERVER_UPSERT_STORE_TRANSFER_ROUTE,
} from "./store-transfer.routes";

export const FETCH_STORE_TRANSFER_LIST_PROGRESS =
  "FETCH_STORE_TRANSFER_LIST_PROGRESS";
export const FETCH_STORE_TRANSFER_LIST_SUCCESS =
  "FETCH_STORE_TRANSFER_LIST_SUCCESS";
export const FETCH_STORE_TRANSFER_LIST_FAILED =
  "FETCH_STORE_TRANSFER_LIST_FAILED";

export const fetchStoreTransferListProgress = () =>
  action(FETCH_STORE_TRANSFER_LIST_PROGRESS);
export const fetchStoreTransferListSuccess = (
  data: IStoreTransfer[],
  totalRecords: number,
) => action(FETCH_STORE_TRANSFER_LIST_SUCCESS, { data, totalRecords });
export const fetchStoreTransferListFailed = () =>
  action(FETCH_STORE_TRANSFER_LIST_FAILED);

export const fetchStoreTransferListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchStoreTransferListProgress());
      const res = await api.get(
        `${SERVER_GET_STORE_TRANSFER_ROUTE}?${searchQuery}`,
      );
      dispatch(
        fetchStoreTransferListSuccess(res.data.data, res.data.totalRecords),
      );
    } catch (err: any) {
      dispatch(fetchStoreTransferListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SINGLE_STORE_TRANSFER_PROGRESS =
  "FETCH_SINGLE_STORE_TRANSFER_PROGRESS";
export const FETCH_SINGLE_STORE_TRANSFER_SUCCESS =
  "FETCH_SINGLE_STORE_TRANSFER_SUCCESS";
export const FETCH_SINGLE_STORE_TRANSFER_FAILED =
  "FETCH_SINGLE_STORE_TRANSFER_FAILED";

export const fetchSingleStoreTransferProgress = () =>
  action(FETCH_SINGLE_STORE_TRANSFER_PROGRESS);
export const fetchSingleStoreTransferSuccess = (data: IStoreTransfer) =>
  action(FETCH_SINGLE_STORE_TRANSFER_SUCCESS, { data });
export const fetchSingleStoreTransferFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_STORE_TRANSFER_FAILED, { errorMessage });

export const fetchSingleStoreTransferAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchSingleStoreTransferProgress());
      const res = await api.get(
        `${SERVER_GET_STORE_TRANSFER_ROUTE}?store_transfer_uuid=${uuid}`,
      );
      const data: IStoreTransfer[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleStoreTransferSuccess(data[0]));
      } else {
        dispatch(fetchSingleStoreTransferFailed("No store transfer found"));
      }
    } catch (err: any) {
      dispatch(
        fetchSingleStoreTransferFailed("Failed to fetch store transfer"),
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertStoreTransferAsync =
  (
    data: IStoreTransfer,
    onCallback: (isSuccess: boolean, storeTransfer?: IStoreTransfer) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        store_transfer_no,
        approved_by_name,
        approved_by_uuid,
        created_by_name,
        created_by_uuid,
        modified_by_name,
        modified_by_uuid,
        create_ts,
        insert_ts,
        combined_store_transfer_code,
        ...rest
      } = data;
      const res = await api.post(SERVER_UPSERT_STORE_TRANSFER_ROUTE, rest);
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          message: "Store transfer saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_STORE_TRANSFER = "CLEAR_STORE_TRANSFER";
export const CLEAR_STORE_TRANSFER_STATE = "CLEAR_STORE_TRANSFER_STATE";
export const clearStoreTransferSync = () => action(CLEAR_STORE_TRANSFER);
export const clearStoreTransferStateSync = () =>
  action(CLEAR_STORE_TRANSFER_STATE);
