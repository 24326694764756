import { LoadState } from "../../../constants/enums";
import {
  IProductFiberFinish,
  IProductFiberFinishState,
} from "./fiber-finish.types";

export const defaultProductFiberFinish: IProductFiberFinish = {
  fiber_finish_uuid: "",
  fiber_finish_name: "",
  description: null,
  status: "",
};

export const defaultProductFiberFinishState: IProductFiberFinishState = {
  multiple_fiber_finish_records: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_fiber_finish_state: {
    data: defaultProductFiberFinish,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
