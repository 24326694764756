import produce from "immer";
import { LoadState } from "../../constants/enums";

import { IStoreState } from "../initialStoreState";
import {
  FETCH_ASSEMBLY_LIST_PROGRESS,
  FETCH_ASSEMBLY_LIST_SUCCESS,
  FETCH_ASSEMBLY_LIST_FAILED,
  FETCH_SINGLE_ASSEMBLY_PROGRESS,
  FETCH_SINGLE_ASSEMBLY_SUCCESS,
  FETCH_SINGLE_ASSEMBLY_FAILED,
  CLEAR_SINGLE_ASSEMBLY,
  CLEAR_ASSEMBLY_LIST,
} from "./assembly.actions";
import { defaultAssemblyState } from "./assembly.state";

export const assemblyReducer = (
  state: IStoreState["assembly"] = defaultAssemblyState,
  action: any,
) => {
  switch (action.type) {
    case FETCH_ASSEMBLY_LIST_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.assemblies.loading = LoadState.InProgress;
      });
    }
    case FETCH_ASSEMBLY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      return produce(state, (draftState) => {
        draftState.assemblies.loading = LoadState.Loaded;
        draftState.assemblies.data = data;
        draftState.assemblies.totalRecords = totalRecords;
      });
    }
    case FETCH_ASSEMBLY_LIST_FAILED: {
      return produce(state, (draftState) => {
        draftState.assemblies.loading = LoadState.Failed;
        draftState.assemblies.data = [];
        draftState.assemblies.totalRecords = 0;
      });
    }

    case FETCH_SINGLE_ASSEMBLY_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.single_assembly.loading = LoadState.InProgress;
      });
    }
    case FETCH_SINGLE_ASSEMBLY_SUCCESS: {
      const { data } = action.payload;
      return produce(state, (draftState) => {
        draftState.single_assembly.loading = LoadState.Loaded;
        draftState.single_assembly.data = data;
      });
    }
    case FETCH_SINGLE_ASSEMBLY_FAILED: {
      const { errorMessage } = action.payload;
      return produce(state, (draftState) => {
        draftState.single_assembly.loading = LoadState.Failed;
        draftState.single_assembly.error = errorMessage;
      });
    }

    case CLEAR_SINGLE_ASSEMBLY: {
      return produce(state, (draftState) => {
        draftState.single_assembly.loading = LoadState.NotLoaded;
        draftState.single_assembly.data =
          defaultAssemblyState.single_assembly.data;
        draftState.single_assembly.error = null;
      });
    }

    case CLEAR_ASSEMBLY_LIST: {
      return produce(state, (draftState) => {
        draftState.assemblies.loading = LoadState.NotLoaded;
        draftState.assemblies.data = defaultAssemblyState.assemblies.data;
        draftState.assemblies.totalRecords = 0;
        draftState.assemblies.error = null;
      });
    }

    default:
      return state;
  }
};
