import { LoadState } from "../../constants/enums";
import {
  ILatestPurchaseOrder,
  IPurchaseOrder,
  IPurchaseOrderItem,
  IPurchaseOrderState,
  IPurchaseRequisitionReference,
} from "./purchase-order.types";

export const defaultPurchaseOrderItem: IPurchaseOrderItem = {
  purchase_order_items_uuid: null,
  purchase_requisition_uuid: "",
  pr_no: "",
  pr_uuid: "",
  part_code: null,
  part_code_uuid: null,
  description: null,
  hsn_code: null,
  etd: null,
  eta: null,
  quantity: null,
  max_price: null,
  uom: null,
  price_per_quantity: null,
  total_price: null,
  status: "ACTIVE",
  tolerance: "",
  discount: 0,
};

export const defaultPurchaseRequisitionReference: IPurchaseRequisitionReference =
  {
    pr_no: "",
    pr_uuid: "",
  };

export const defaultPurchaseOrder: IPurchaseOrder = {
  purchase_order_uuid: null,
  purchase_order_no: "",
  vendor_name: "",
  vendor_uuid: "",
  combined_purchase_order_no: "",
  reference: null,
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_state: null,
  vendor_address_city: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  vendor_contact: null,
  vendor_gstin: null,
  purchase_order_date: null,
  pr_references: [],
  dispatch_mode: null,
  po_prefix: "RWBR",
  destination: null,
  terms_of_payment: null,
  terms_of_delivery: null,
  quotation_no: null,
  pan: null,
  gstin: null,
  deliver_address_line1: null,
  deliver_address_line2: null,
  deliver_address_state: null,
  deliver_address_city: null,
  deliver_address_pincode: null,
  deliver_address_country: null,
  deliver_contact: null,
  purchase_order_items: [defaultPurchaseOrderItem],
  remark: null,
  currency: null,
  discount: null,
  discount_value: null,
  freight: null,
  freight_value: null,
  packing_charges: null,
  packing_charges_value: null,
  others: null,
  others_value: null,
  others_two: null,
  others_two_value: null,
  full_amount: null,
  customs_duty: null,
  customs_duty_value: null,
  igst: null,
  igst_value: null,
  sgst: null,
  sgst_value: null,
  cgst: null,
  cgst_value: null,
  total_amount_after_tax: null,
  total_amount_in_words: null,
  first_approver_uuid: null,
  first_approver_name: null,
  second_approver_uuid: null,
  second_approver_name: null,
  status: "PO_REQUESTED",
  po_type: "",
  po_to: "",
  po_group: "DOMESTIC",
};

export const defaultLatestPurchaseOrder: ILatestPurchaseOrder = {
  purchase_order_uuid: "",
  purchase_order_no: "",
  vendor_name: "",
  vendor_uuid: "",
  vendor_email: null,
  po_type: null,
  po_to: null,
  po_group: null,
  po_prefix: "",
  rev_no: 0,
  remarks_for_revision: "",
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_state: null,
  vendor_address_city: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  vendor_contact: null,
  vendor_contact_uuid: null,
  vendor_gstin: null,
  purchase_order_date: "",
  pr_references: [],
  dispatch_mode: null,
  destination: null,
  terms_of_payment: null,
  terms_of_delivery: null,
  quotation_no: null,
  pan: null,
  gstin: null,
  invoice_address_line1: null,
  invoice_address_line2: null,
  invoice_address_state: null,
  invoice_address_city: null,
  invoice_address_pincode: null,
  invoice_address_country: null,
  invoice_contact: null,
  deliver_address_line1: null,
  deliver_address_line2: null,
  deliver_address_state: null,
  deliver_address_city: null,
  deliver_address_pincode: null,
  deliver_address_country: null,
  deliver_contact: null,
  remark: "",
  currency: null,
  discount: null,
  discount_value: 0,
  freight: 0,
  freight_value: 0,
  packing_charges: null,
  packing_charges_value: 0,
  full_amount: 0,
  customs_duty: null,
  customs_duty_value: 0,
  igst: null,
  igst_value: null,
  sgst: null,
  sgst_value: null,
  cgst: null,
  cgst_value: null,
  total_amount_after_tax: 0,
  additional_charges: null,
  total_amount_in_words: null,
  estimated_time_of_departure: null,
  estimated_time_of_arrival: null,
  first_approver_uuid: null,
  first_approver_name: null,
  second_approver_uuid: null,
  second_approver_name: null,
  attachments: null,
  status: "",
  created_by_uuid: "",
  create_ts: "",
  insert_ts: "",
  combined_purchase_order_no: "",
  purchase_order_items_uuid: "",
  purchase_requisition_uuid: "",
  pr_no: "",
  part_code: "",
  description: null,
  hsn_code: null,
  etd: null,
  eta: null,
  quantity: "",
  per_pack_quantity: 0,
  uom: "",
  price_per_quantity: null,
  total_price: null,
};

export const defaultPurchaseOrderState: IPurchaseOrderState = {
  purchase_order_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_purchase_order: {
    data: defaultPurchaseOrder,
    loading: LoadState.NotLoaded,
    error: null,
  },
  latest_purchase_order: {
    data: defaultLatestPurchaseOrder,
    loading: LoadState.NotLoaded,
    error: null,
  },
  approval: {
    data: [],
    loading: LoadState.NotLoaded,
    error: null,
    totalRecords: 0,
  },
};
