import { LoadState } from "../../constants/enums";
import { IPrDocument } from "../enquiries";
import {
  IPurchaseRequisitionItem,
  IPurchaseRequisition,
  IPurchaseRequisitionState,
} from "./purchase-requisition.types";

export const defaultPrAttachment: IPrDocument = {
  document_type: "",
  file_url: "",
  description: "",
};

export const defaultIPurchaseRequisitionItem: IPurchaseRequisitionItem = {
  id: "",
  part_code: "",
  description: "",
  part_code_uuid: "",
  required_quantity: "",
  requested_date: "",
  supplier_name: "",
  supplier_uuid: "",
  uom: null,
  po_no: "",
  tolerance: "",
  min: 0,
  max: 0,
  total_stock: 0,
  free_stock: 0,
  min_stock: 0,
  last_month_average: 0,
  stock: 0,
  pr_quantity: 0,
  comaprision_sheet: "",
  comment: "",
  attachment: defaultPrAttachment,
  dispatch_date: "",
};

export const defaultPurchaseRequisition: IPurchaseRequisition = {
  purchase_requisition_uuid: null,
  pr_prefix: "",
  request_division: null,
  currency: "RUPEES",
  request_department: "MRP",
  address: "Bangalore",
  project_uuid: "",

  request_reference_a: null,
  request_reference_b: null,
  remarks: "",
  pr_type: "Stock Refilling",
  origin: "DOMESTIC",
  sod_no: "",
  purchase_items: [defaultIPurchaseRequisitionItem],
  first_approval_by_uuid: null,
  first_approval_by_name: null,
  second_approval_by_uuid: null,
  second_approval_by_name: null,
  region: "",
  status: "PR_REQUESTED",
};

export const defaultPurchaseRequisitionState: IPurchaseRequisitionState = {
  purchase_requisition_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_purchase_requisition: {
    data: defaultPurchaseRequisition,
    loading: LoadState.NotLoaded,
    error: null,
  },
  approval: {
    data: [],
    loading: LoadState.NotLoaded,
    error: null,
    totalRecords: 0,
  },
};
