import { String } from "lodash";
import { LoadState } from "../../constants/enums";
import {
  IInternalOrder,
  IInternalOrderItem,
  IInternalOrderState,
} from "./internal-orders.types";

export const defaultInternalOrderItem: IInternalOrderItem = {
  dtz_bom_product_code: "",
  dtz_bom_uuid: "",
  dtz_part_code: "",
  dtz_part_code_uuid: "",
  internal_order_bom_uuid: "",
  sez_bom_uuid: "",
  sez_part_code: "",
  sez_part_code_uuid: "",
  quantity: 0,
  unique_uuid: "",
  bom_uuid: null,
  routing_uuid: null,
  description: null,
};

export const defaultInternalOrder: IInternalOrder = {
  internal_order_uuid: null,
  project_uuid: null,
  project_name: "",
  sod_no: "",
  internal_sod_no: "",
  target_dispatch_date: null,
  customer_uuid: null,
  customer_name: null,
  delivery_address_line1: null,

  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  product_items: [],
  status: "ACTIVE",
};

export const defaultInternalOrderState: IInternalOrderState = {
  internal_orders_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_internal_order: {
    data: defaultInternalOrder,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
