import { LoadState } from "../../constants/enums";
import { IStoreList, IStoreListState } from "./store.types";

export const defaultStore: IStoreList = {
  store_uuid: "",
  store_name: "",
  description: "",
  layout: [{ key: null, pair: null }],
  isChecked: false,
  created_by_uuid: "",
  created_by_name: "",
  modified_by_uuid: "",
  modified_by_name: "",
  status: "ACTIVE",
  create_ts: "",
  insert_ts: "",
};

export const defaultStoreListState: IStoreListState = {
  store_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_store: {
    data: defaultStore,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
