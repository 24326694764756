import { IQuoteLayout } from "../redux/quote/quote.types";

export const isNotNull = (value: string | number | null | undefined) => {
  return Number(value) || 0;
};

export const calculateDiscount = (amount: number, tax: number) => {
  return (amount * tax) / 100;
};

export const calculateDiscountV2 = (
  amount: string | number | null | undefined,
  tax: string | number | null | undefined,
) => {
  let discount = (isNotNull(amount) * isNotNull(tax)) / 100;
  let remainingAmount = isNotNull(amount) - discount;
  return { discount, remainingAmount };
};

export const calculatePercentage = (
  amount: string | number | null | undefined,
  totalAmount: string | number | null | undefined,
) => {
  const validAmount = amount != null ? Number(amount) : 0;
  const validTotalAmount = totalAmount != null ? Number(totalAmount) : 0;

  // If totalAmount is zero, avoid division by zero and return 0
  if (validTotalAmount === 0) {
    return 0;
  }
  return (
    100 -
    (isNotNull(validAmount) / isNotNull(totalAmount)) * 100
  ).toPrecision(10);
};

export const calculateTax = (amount: number, tax: number) => {
  return (amount * tax) / 100;
};

export const roundoffValue = (value: number, decimalPlaces = 2) => {
  return Number(value.toFixed(decimalPlaces));
};

export const calculateTaxV2 = (
  amount: string | number | null | undefined,
  tax: string | number | null | undefined,
): number => {
  return roundoffValue((isNotNull(amount) * isNotNull(tax)) / 100);
};

export interface ICalculateAllDiscountsInputs {
  quoteLayouts: IQuoteLayout[];
  additionalDiscount: string;
  additionalDiscountValue: string;
  specialDiscount: string;
  specialDiscountValue: string;
  transportationCharges: string;
  transportationChargesValue: string;
  otherCharges: string;
  otherChargesValue: string;

  customDuty: string;
  customDutyValue: string;
  labourCess: string;
  labourCessValue: string;
  isTypeCGST: boolean;
  igst: string;
  cgst: string;
  sgst: string;
  roundOffCheck: string;
}
export interface ICalculateAllDiscountsOutput {
  allProductsAmountTotal: string;
  discount: string;
  discountedValue: string;
  additionalDiscountValue: string;
  specialDiscountValue: string;
  amountAfterDiscount: string;
  customDutyValue: string;
  labourCessValue: string;
  gstTaxValue: string;
  amountAfterTax: string;
  transportationCharges: string;
  otherChargesValue: string;
  transportationChargesValue: string;
}
export const CalculateAllDiscounts = ({
  quoteLayouts,
  additionalDiscount,
  additionalDiscountValue,
  specialDiscount,
  specialDiscountValue,
  transportationCharges,
  transportationChargesValue,
  otherCharges,
  otherChargesValue,
  customDuty,
  customDutyValue,
  labourCess,
  labourCessValue,
  isTypeCGST,
  igst,
  cgst,
  sgst,
  roundOffCheck,
}: ICalculateAllDiscountsInputs): ICalculateAllDiscountsOutput => {
  let totalAmountWithoutDiscount: number = 0;
  quoteLayouts.forEach((layout) => {
    layout.items.forEach((item) => {
      totalAmountWithoutDiscount +=
        isNotNull(item.quantity) * isNotNull(item.price);
    });
  });
  let allProductsAmountTotal: number = 0;
  quoteLayouts.forEach((layout) => {
    layout.items.forEach((item) => {
      allProductsAmountTotal += isNotNull(item.total);
    });
  });

  let discountedValue = totalAmountWithoutDiscount - allProductsAmountTotal;
  let discountPercentage = calculatePercentage(
    allProductsAmountTotal,
    totalAmountWithoutDiscount,
  );

  let additionalDiscountVal = 0;
  if (isNotNull(additionalDiscount) > 0) {
    additionalDiscountVal = calculateDiscount(
      allProductsAmountTotal,
      isNotNull(additionalDiscount),
    );
    allProductsAmountTotal -= additionalDiscountVal;
  } else {
    additionalDiscountVal = isNotNull(additionalDiscountValue);
    allProductsAmountTotal -= additionalDiscountVal;
  }

  let specialDiscountVal = 0;
  if (isNotNull(specialDiscount) > 0) {
    specialDiscountVal = calculateDiscount(
      allProductsAmountTotal,
      isNotNull(specialDiscount),
    );
    allProductsAmountTotal -= specialDiscountVal;
  } else {
    specialDiscountVal = isNotNull(specialDiscountValue);
    allProductsAmountTotal -= specialDiscountVal;
  }

  let transportationChargesVal = 0;
  if (isNotNull(transportationCharges) > 0) {
    transportationChargesVal = calculateDiscount(
      allProductsAmountTotal,
      isNotNull(transportationCharges),
    );
    allProductsAmountTotal += transportationChargesVal;
  } else {
    transportationChargesVal = isNotNull(transportationChargesValue);
    allProductsAmountTotal += transportationChargesVal;
  }

  // if (transportationChargesValue != null) {
  //   const calculatedTransportationCharges =
  //     calculatePercentage(transportationChargesValue, allProductsAmountTotal) ??
  //     0;
  //   transportationCharges = calculatedTransportationCharges.toString();
  //   allProductsAmountTotal += transportationChargesValue;
  // }

  let otherChargesVal = 0;
  if (isNotNull(otherCharges) > 0) {
    otherChargesVal = calculateDiscount(
      allProductsAmountTotal,
      isNotNull(otherCharges),
    );
    allProductsAmountTotal += otherChargesVal;
  } else {
    otherChargesVal = isNotNull(otherChargesValue);
    allProductsAmountTotal += otherChargesVal;
  }

  let amountAfterDiscount = allProductsAmountTotal;

  let totalCustomDuty = 0;
  if (isNotNull(customDuty) > 0) {
    totalCustomDuty = calculateTaxV2(allProductsAmountTotal, customDuty);
  } else {
    totalCustomDuty += isNotNull(customDutyValue);
  }

  let totalLabourCess = 0;
  if (isNotNull(labourCess) > 0) {
    totalLabourCess = calculateTaxV2(isNotNull(totalCustomDuty), labourCess);
  } else {
    totalLabourCess += isNotNull(labourCessValue);
  }

  let totalTaxableValue =
    amountAfterDiscount + totalCustomDuty + totalLabourCess;

  let totalGSTTax = 0;
  if (isTypeCGST) {
    const totalCSGST = isNotNull(cgst) + isNotNull(sgst);
    totalGSTTax = calculateTaxV2(totalTaxableValue, totalCSGST);
  } else {
    totalGSTTax = calculateTaxV2(totalTaxableValue, igst);
  }

  let totalAmountAfterTax =
    allProductsAmountTotal + totalGSTTax + totalCustomDuty + totalLabourCess;
  if (roundOffCheck === "Y") {
    allProductsAmountTotal = Math.round(allProductsAmountTotal);
    totalAmountAfterTax = Math.round(totalAmountAfterTax);
  }

  return {
    allProductsAmountTotal: totalAmountWithoutDiscount.toString(),
    discount: discountPercentage.toString(),
    discountedValue: discountedValue.toFixed(2).toString(),
    additionalDiscountValue: additionalDiscountVal.toFixed(2).toString(),
    specialDiscountValue: specialDiscountVal.toFixed(2).toString(),
    amountAfterDiscount: amountAfterDiscount.toFixed(2).toString(),

    customDutyValue: totalCustomDuty.toString(),
    labourCessValue: totalLabourCess.toString(),
    gstTaxValue: totalGSTTax.toString(),
    amountAfterTax: totalAmountAfterTax.toFixed(2).toString(),

    transportationCharges: transportationCharges || "0",
    transportationChargesValue: transportationChargesVal.toFixed(2).toString(),
    otherChargesValue: otherChargesVal.toFixed(2).toString(),
  };
};

export function displayPriceFormat(value: string | number | null) {
  return isNotNull(value)
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
