import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IWorkOrderState } from "./workOrder.types";
import { CLEAR_WORK_ORDER_JOB_LIST } from "./workOrderJob.actions";

// ###########################################################################
// ####################### Fetch Multiple Work Orders ########################
// ###########################################################################
export const FETCH_WORK_ORDER_LIST_PROGRESS = "FETCH_WORK_ORDER_LIST_PROGRESS";
export const FETCH_WORK_ORDER_LIST_SUCCESS = "FETCH_WORK_ORDER_LIST_SUCCESS";
export const FETCH_WORK_ORDER_LIST_FAILED = "FETCH_WORK_ORDER_LIST_FAILED";

export const fetchWorkOrderListProgress = () =>
  action(FETCH_WORK_ORDER_LIST_PROGRESS);

export const fetchWorkOrderListSuccess = (
  data: IWorkOrderState["workOrderList"]["data"],
  totalRecords: number,
) => action(FETCH_WORK_ORDER_LIST_SUCCESS, { data, totalRecords });
export const fetchWorkOrderListFailed = () =>
  action(FETCH_WORK_ORDER_LIST_FAILED);

export const fetchWorkOrderListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchWorkOrderListProgress());
      const res = await api.get(`/work_order/get-work-order?${searchQuery}`);
      const data: IWorkOrderState["workOrderList"]["data"] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchWorkOrderListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWorkOrderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ###########################################################################
// ######################## Fetch Single Work Orders #########################
// ###########################################################################
export const FETCH_SINGLE_WORK_ORDER_PROGRESS =
  "FETCH_SINGLE_WORK_ORDER_PROGRESS";
export const FETCH_SINGLE_WORK_ORDER_SUCCESS =
  "FETCH_SINGLE_WORK_ORDER_SUCCESS";
export const FETCH_SINGLE_WORK_ORDER_FAILED = "FETCH_SINGLE_WORK_ORDER_FAILED";

export const fetchSingleWorkOrderProgress = () =>
  action(FETCH_SINGLE_WORK_ORDER_PROGRESS);
export const fetchSingleWorkOrderSuccess = (
  data: IWorkOrderState["singleWorkOrder"]["data"],
) => action(FETCH_SINGLE_WORK_ORDER_SUCCESS, { data });
export const fetchSingleWorkOrderFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_WORK_ORDER_FAILED, { errorMessage });

export const fetchSingleWorkOrderAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleWorkOrderProgress());
      const res = await api.get(
        `/work_order/get-work-order?work_order_uuid=${uuid}`,
      );
      const data: IWorkOrderState["singleWorkOrder"]["data"][] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleWorkOrderSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleWorkOrderFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleWorkOrderFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ###########################################################################
// ####################### Upsert Single Work Orders #########################
// ###########################################################################
export const upsertSingleWorkOrderAsync =
  (
    data: IWorkOrderState["singleWorkOrder"]["data"],
    onCallback: (
      isSuccess: boolean,
      workOrder?: IWorkOrderState["singleWorkOrder"]["data"],
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/work_order/upsert-work-order", payload);
      let message = "Work Order saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ###########################################################################
// ######################## Clear State #########################
// ###########################################################################
export const CLEAR_SINGLE_WORK_ORDER = "CLEAR_SINGLE_WORK_ORDER";
export const CLEAR_WORK_ORDER_LIST = "CLEAR_WORK_ORDER_LIST";

export const CLEAR_WORK_ORDER_STATE = "CLEAR_WORK_ORDER_STATE";

export const clearSingleWorkOrder = () => action(CLEAR_SINGLE_WORK_ORDER);
export const clearWorkOrderList = () => action(CLEAR_WORK_ORDER_LIST);

export const clearWorkOrderState = () => action(CLEAR_WORK_ORDER_STATE);
