import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { appNavRoutes } from "../../../constants/appNavRoutes";

const ViewSingleBreakDownList = Loadable(
  lazy(() =>
    import("./ViewSingleBreakDownList").then(({ ViewSingleBreakDownList }) => ({
      default: ViewSingleBreakDownList,
    })),
  ),
);

export const bdlNavRoutes = [
  {
    path: `${appNavRoutes.planning.bdl}/view/:order_number`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ROUTING]}>
        <ViewSingleBreakDownList />
      </AuthorizedRoute>
    ),
  },
];
