import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { IDrawingItems } from "./drawingItem.types";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_DRAWING_ITEM_LIST_PROGRESS =
  "FETCH_DRAWING_ITEM_LIST_PROGRESS";
export const FETCH_DRAWING_ITEM_LIST_SUCCESS =
  "FETCH_DRAWING_ITEM_LIST_SUCCESS";
export const FETCH_DRAWING_ITEM_LIST_FAILED = "FETCH_DRAWING_ITEM_LIST_FAILED";

export const fetchDrawingItemListProgress = () =>
  action(FETCH_DRAWING_ITEM_LIST_PROGRESS);
export const fetchDrawingItemListSuccess = (
  list: IDrawingItems[],
  totalRecords: number,
) => action(FETCH_DRAWING_ITEM_LIST_SUCCESS, { list, totalRecords });
export const fetchDrawingItemListFailed = () =>
  action(FETCH_DRAWING_ITEM_LIST_FAILED);

export const fetchDrawingItemListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchDrawingItemListProgress());
      const res = await api.get(`/bom/get-drawing-item?${searchQuery}`);
      const data: IDrawingItems[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchDrawingItemListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchDrawingItemListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_DRAWING_ITEM_PROGRESS = "FETCH_DRAWING_ITEM_PROGRESS";
export const FETCH_DRAWING_ITEM_SUCCESS = "FETCH_DRAWING_ITEM_SUCCESS";
export const FETCH_DRAWING_ITEM_FAILED = "FETCH_DRAWING_ITEM_FAILED";

export const fetchDrawingItemProgress = () =>
  action(FETCH_DRAWING_ITEM_PROGRESS);
export const fetchDrawingItemSuccess = (data: IDrawingItems) =>
  action(FETCH_DRAWING_ITEM_SUCCESS, { data });
export const fetchDrawingItemFailed = () => action(FETCH_DRAWING_ITEM_FAILED);

export const fetchDrawingItemAsync =
  (DrawingUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchDrawingItemProgress());
      const res = await api.get(
        `/bom/get-drawing-item?drawing_item_uuid=${DrawingUUID}`,
      );
      const data: IDrawingItems = res.data.data[0];

      const totalRecords = res.data.totalRecords;

      dispatch(fetchDrawingItemSuccess(data));
    } catch (err: any) {
      dispatch(fetchDrawingItemFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertDrawingItemsAsync =
  (
    data: IDrawingItems,
    attachments: File | null,
    dfxAttachments: File | null,
    onCallback: (isSuccess: boolean, data?: IDrawingItems) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        revision_no,
        created_by_uuid,
        drawing_no,
        combined_drawing_no,
        isChecked,
        ...payload
      } = data;
      dispatch(saveLoaderProgress());
      const asPayload = {
        drawing_no: data.drawing_no,
      };
      const path = await uploadFile(
        attachments,
        "Drawing",
        data.drawing_link || "",
        asPayload as any,
      );
      const dfxpath = await uploadFile(
        dfxAttachments,
        "Drawing",
        data.dfx_link || "",
        asPayload as any,
      );
      const response = await api.post("/bom/upsert-drawing-item", {
        ...payload,
        drawing_link: path,
        dfx_link: dfxpath,
      });
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Item saved successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_DRAWING_ITEM = "CLEAR_DRAWING_ITEM";
export const clearDrawingItemAsync = () => action(CLEAR_DRAWING_ITEM);
export const CLEAR_DRAWING_ITEM_STATE = "CLEAR_DRAWING_ITEM_STATE";
export const clearDrawingItemListAsync = () => action(CLEAR_DRAWING_ITEM_STATE);
