import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { MODULE_IDS } from "../constants/enums";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { ForgetPassword } from "../views/authentication/ForgetPassword/ForgetPassword";
import { OOPSError } from "../views/authentication/OopsError";
import { ManageUserBranch } from "../views/dataManagement/userBranch/ManageUserBranch";
import { BranchList } from "../views/dataManagement/userBranch/UserBranchList";
import { ZoneList } from "../views/dataManagement/userZone/ZoneList";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";

import { ApprovalBomList } from "../views/approval/approvalBom/ApprovalBomList";
import { costingSheetRoutes } from "../views/costingSheet/CostingSheet.router";
import { drawingUploadsRoutes } from "../views/drawingUploads/DrawingUploads.router";
import { enquiryRoutes } from "../views/leads/Enquiries.router";
import { mrpAllocationsRoutes } from "../views/MrpAllocations/MrpAllocations.router";
import { productsRoutes } from "../views/products/Products.router";
import { quotesRoutes } from "../views/quotes/Quote.router";
import { proformaInvoicesRoutes } from "../views/sales/ProformaInvoices/ProformaInvoices.router";
import { workOrderRoutes } from "../views/workOrder/WorkOrder/WorkOrder.router";
import { workOrderJobRoutes } from "../views/workOrder/WorkOrderJob/WorkOrderJob.router";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { vendorsRoutes } from "../views/vendors/Vendor.router";
import { purchaseRequisitionsRoutes } from "../views/PurchaseRequisition/PurchaseRequisition.router";
import { purchaseOrdersRoutes } from "../views/PurchaseOrder/PurchaseOrder.router";
import { routingModuleRoutes } from "../views/routing/Routing.router";
import { warehouseRoutes } from "../views/warehouse/Warehouse.router";
import { drawingRoutes } from "../views/drawing/Drawing.router";
import { bomRoutes } from "../views/bom/Bom.router";
import { internalOrdersRoutes } from "../views/internalOrders/InternalOrders.router";
import { materialRequestsRoutes } from "../views/MaterialRequests/MaterialRequests.router";
import { projectsRoutes } from "../views/projects/Projects.router";
import { grnRoute } from "../views/Grn/Grn.routes";
import { partItemsRoutes } from "../views/partItem/PartItem.router";
import { gatePassRoutes } from "../views/gatepass/gatePass.router";
import { planningNavRoutes } from "../views/planning/planning.router";
import { productsFiberFinishRoutes } from "../views/products/fiberFinish/FiberFinish.router";
import { partItemPrefixRoutes } from "../views/partItemPrefix/partItemPrefix.router";
import { drawingItemPrefixRoutes } from "../views/drawingItemPrefix/DrawingtemPrefix.router";
import { priceUpdationRoutes } from "../views/priceUpdation/PriceUpdation.router";
import { storeManagementRoutes } from "../views/StoreManagement/Store.router";
import { storeTransferRoutes } from "../views/StoreManagement/StoreTransfer/StoreTransfer.router";
import { salesModuleRoutes } from "../views/sales";

import { assemblyRoutes } from "../views/assembly/Assembly.router";
import { batchRoutes } from "../views/batch/Batch.router";
import { orderDocumentRoutes } from "../views/orderDocument/OrderDocument.router";
import { internalOrderBomRoutes } from "../views/InternalOrderBom/InternalOrderBom.router";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    })),
  ),
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout")),
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    })),
  ),
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    })),
  ),
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    })),
  ),
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    })),
  ),
);

// const Leads = Loadable(
//   lazy(() =>
//     import("../views/leads/EnquiriesTable").then(({ Leads }) => ({
//       default: Leads,
//     })),
//   ),
// );

// const ManageLead = Loadable(
//   lazy(() =>
//     import("../views/leads/ManageSingleEnquiry").then(({ ManageLead }) => ({
//       default: ManageLead,
//     })),
//   ),
// );
// const ViewLeadActivity = Loadable(
//   lazy(() =>
//     import("../views/leads/ViewEmailsAndActivity").then(({ ViewLeadActivity }) => ({
//       default: ViewLeadActivity,
//     })),
//   ),
// );
// const ViewProjectAndLead = Loadable(
//   lazy(() =>
//     import("../views/leads/ViewEnquiryWithTabs").then(
//       ({ ViewProjectAndLead }) => ({
//         default: ViewProjectAndLead,
//       }),
//     ),
//   ),
// );

const ViewOpportunites = Loadable(
  lazy(() =>
    import("../views/opportunites/opportunites").then(
      ({ ViewOpportunites }) => ({
        default: ViewOpportunites,
      }),
    ),
  ),
);
// ================================= Rendering_Imports========================================
const RenderingJobs = Loadable(
  lazy(() =>
    import("../views/rendering/renderingJobs/RenderingJobs").then(
      ({ RenderingJobs }) => ({
        default: RenderingJobs,
      }),
    ),
  ),
);

const ManageRenderingJob = Loadable(
  lazy(() =>
    import("../views/rendering/renderingJobs/ManageRenderingJob").then(
      ({ ManageRenderingJob }) => ({
        default: ManageRenderingJob,
      }),
    ),
  ),
);
const RenderingPartners = Loadable(
  lazy(() =>
    import("../views/rendering/renderingPartners/RenderingPartners").then(
      ({ RenderingPartners }) => ({
        default: RenderingPartners,
      }),
    ),
  ),
);

const ManageRenderingPartner = Loadable(
  lazy(() =>
    import("../views/rendering/renderingPartners/ManageRenderingPartner").then(
      ({ ManageRenderingPartner }) => ({
        default: ManageRenderingPartner,
      }),
    ),
  ),
);
/************************ Customer Imports ************************/
const CustomersList = Loadable(
  lazy(() =>
    import("../views/customers/CustomersList").then(({ CustomersList }) => ({
      default: CustomersList,
    })),
  ),
);
const ManageCustomer = Loadable(
  lazy(() =>
    import("../views/customers/ManageCustomer").then(({ ManageCustomer }) => ({
      default: ManageCustomer,
    })),
  ),
);
/************************ Category Imports ************************/
const CategoryList = Loadable(
  lazy(() =>
    import("../views/category/CategoryList").then(({ CategoryList }) => ({
      default: CategoryList,
    })),
  ),
);

/************************ Customer Branches Imports ************************/
const CustomerBranchList = Loadable(
  lazy(() =>
    import("../views/customerBranch/CustomerBranchList").then(
      ({ CustomerBranchList }) => ({
        default: CustomerBranchList,
      }),
    ),
  ),
);
const ManageCustomerBranches = Loadable(
  lazy(() =>
    import("../views/customerBranch/ManageCustomerBranch").then(
      ({ ManageCustomerBranch }) => ({
        default: ManageCustomerBranch,
      }),
    ),
  ),
);
const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    })),
  ),
);

const ViewProfile = Loadable(
  lazy(() =>
    import("../views/Profile/ViewProfile/ViewProfile").then(
      ({ ViewProfile }) => ({
        default: ViewProfile,
      }),
    ),
  ),
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/Security/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup }),
    ),
  ),
);

const SecurityApprovalsList = Loadable(
  lazy(() =>
    import("../views/Security/approval/ApprovalList").then(
      ({ ApprovalList }) => ({ default: ApprovalList }),
    ),
  ),
);

const ManageSecuirtyApproval = Loadable(
  lazy(() =>
    import("../views/Security/approval/ManageApproval").then(
      ({ ManageApproval }) => ({ default: ManageApproval }),
    ),
  ),
);

const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/Security/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups }),
    ),
  ),
);

const RoleGroupTable = Loadable(
  lazy(() =>
    import("../views/Security/roleGroup/RoleGroupTable").then(
      ({ RoleGroupTable }) => ({ default: RoleGroupTable }),
    ),
  ),
);

const CreateOpportunites = Loadable(
  lazy(() =>
    import("../views/opportunites/CreateOppurtunity/CreateOppurtunity").then(
      ({ CreateOpportunites }) => ({ default: CreateOpportunites }),
    ),
  ),
);

const Companies = Loadable(
  lazy(() =>
    import("../views/company/Companies").then(({ Companies }) => ({
      default: Companies,
    })),
  ),
);

const Contacts = Loadable(
  lazy(() =>
    import("../views/contacts/Contacts").then(({ Contacts }) => ({
      default: Contacts,
    })),
  ),
);

const ManageContact = Loadable(
  lazy(() =>
    import("../views/contacts/ManageContact").then(({ ManageContact }) => ({
      default: ManageContact,
    })),
  ),
);

const BomList = Loadable(
  lazy(() =>
    import("../views/bom/BomList").then(({ BomList }) => ({
      default: BomList,
    })),
  ),
);

const ManageBom = Loadable(
  lazy(() =>
    import("../views/bom/ManageBom").then(({ ManageBom }) => ({
      default: ManageBom,
    })),
  ),
);
const ManageFullViewBomItems = Loadable(
  lazy(() =>
    import("../views/bom/components/ManageFullwidthBomItems").then(
      ({ ManageFullwidthBomItems }) => ({
        default: ManageFullwidthBomItems,
      }),
    ),
  ),
);

const RoutingList = Loadable(
  lazy(() =>
    import("../views/routing/RoutingList").then(({ RoutingList }) => ({
      default: RoutingList,
    })),
  ),
);

const ManageRouting = Loadable(
  lazy(() =>
    import("../views/routing/ManageRouting").then(({ ManageRouting }) => ({
      default: ManageRouting,
    })),
  ),
);

// master bom
const MasterBomList = Loadable(
  lazy(() =>
    import("../views/masterBom/MasterBomList").then(({ MasterBomList }) => ({
      default: MasterBomList,
    })),
  ),
);

const ManageMasterBom = Loadable(
  lazy(() =>
    import("../views/masterBom/ManageMasterBom").then(
      ({ ManageMasterBom }) => ({
        default: ManageMasterBom,
      }),
    ),
  ),
);

//request
const RequestList = Loadable(
  lazy(() =>
    import("../views/request/Request").then(({ RequestList }) => ({
      default: RequestList,
    })),
  ),
);
const ManageRequest = Loadable(
  lazy(() =>
    import("../views/request/ManageRequest").then(
      ({ ManageSingleRequest }) => ({
        default: ManageSingleRequest,
      }),
    ),
  ),
);
// drawing
const DrawingList = Loadable(
  lazy(() =>
    import("../views/drawing/DrawingList").then(({ DrawingList }) => ({
      default: DrawingList,
    })),
  ),
);

// const ManageDrawing = Loadable(
//   lazy(() =>
//     import("../views/drawing/ManageDrawing").then(({ ManageDrawing }) => ({
//       default: ManageDrawing,
//     })),
//   ),
// );

// drawing item

const DrawingItemList = Loadable(
  lazy(() =>
    import("../views/drawingItem/DrawingItemList").then(
      ({ DrawingItemList }) => ({
        default: DrawingItemList,
      }),
    ),
  ),
);

// deviation
const DeviationList = Loadable(
  lazy(() =>
    import("../views/deviation/DeviationList").then(({ DeviationList }) => ({
      default: DeviationList,
    })),
  ),
);

const ManageDeviation = Loadable(
  lazy(() =>
    import("../views/deviation/ManageDeviation").then(
      ({ ManageDeviation }) => ({
        default: ManageDeviation,
      }),
    ),
  ),
);

// ecn
const EcnList = Loadable(
  lazy(() =>
    import("../views/ecn/EcnList").then(({ EcnList }) => ({
      default: EcnList,
    })),
  ),
);

const ManageEcn = Loadable(
  lazy(() =>
    import("../views/ecn/ManageEcn").then(({ ManageEcn }) => ({
      default: ManageEcn,
    })),
  ),
);
// ncp
const NcpList = Loadable(
  lazy(() =>
    import("../views/ncp/NcpList").then(({ NcpList }) => ({
      default: NcpList,
    })),
  ),
);

const ManageNcp = Loadable(
  lazy(() =>
    import("../views/ncp/ManageNcp").then(({ ManageNcp }) => ({
      default: ManageNcp,
    })),
  ),
);

const OrderProcessingList = Loadable(
  lazy(() =>
    import("../views/sales/OrderProcessing/OrderProcessingList").then(
      ({ OrderProcessingList }) => ({
        default: OrderProcessingList,
      }),
    ),
  ),
);

const QuotesInvoice = Loadable(
  lazy(() =>
    import("../publicModules/QuotesInvoice/QuotesInvoice").then(
      ({ QuotesInvoice }) => ({
        default: QuotesInvoice,
      }),
    ),
  ),
);

const TaskBoard = Loadable(
  lazy(() =>
    import("../views/tasks/TaskBoard/TasksBoard").then(({ TaskBoard }) => ({
      default: TaskBoard,
    })),
  ),
);

const QuoteWrapper = Loadable(
  lazy(() =>
    import("../views/quotes/QuoteLayout/QuoteWrapper").then(
      ({ QuoteWrapper }) => ({
        default: QuoteWrapper,
      }),
    ),
  ),
);

// ********************** web product *******************
const WebProductList = Loadable(
  lazy(() =>
    import("../views/webProduct/WebProductList").then(({ WebProductList }) => ({
      default: WebProductList,
    })),
  ),
);
const ManageWebProduct = Loadable(
  lazy(() =>
    import("../views/webProduct/ManageWebProduct").then(
      ({ ManageWebProduct }) => ({
        default: ManageWebProduct,
      }),
    ),
  ),
);
const FormulaMapping = Loadable(
  lazy(() =>
    import("../views/formula/FormulaMapping").then(({ FormulaMapping }) => ({
      default: FormulaMapping,
    })),
  ),
);

/************************ Approval ************************/

const ApprovalQuoteList = Loadable(
  lazy(() =>
    import("../views/approval/quote/ApprovalQuoteList").then(
      ({ ApprovalQuotesList }) => ({
        default: ApprovalQuotesList,
      }),
    ),
  ),
);

const ApprovalCostingSheetList = Loadable(
  lazy(() =>
    import(
      "../views/approval/approvalCostingSheet/ApprovalCostingSheetList"
    ).then(({ ApprovalCostingSheetList }) => ({
      default: ApprovalCostingSheetList,
    })),
  ),
);

const OrfApprovalList = Loadable(
  lazy(() =>
    import("../views/approval/orfApprovals/OrfApprovalList").then(
      ({ OrfApprovalList }) => ({
        default: OrfApprovalList,
      }),
    ),
  ),
);
const ItemApprovalList = Loadable(
  lazy(() =>
    import("../views/approval/itemApprovals/ItemApprovalList").then(
      ({ ItemApprovalList }) => ({
        default: ItemApprovalList,
      }),
    ),
  ),
);

const ApprovalDrawingList = Loadable(
  lazy(() =>
    import("../views/approval/approvalDrawing/ApprovalDrawingList").then(
      ({ ApprovalDrawingList }) => ({
        default: ApprovalDrawingList,
      }),
    ),
  ),
);
const ApprovalMasterBomList = Loadable(
  lazy(() =>
    import("../views/approval/approvalMasterBom/ApprovalMasterBomList").then(
      ({ ApprovalMasterBomList }) => ({
        default: ApprovalMasterBomList,
      }),
    ),
  ),
);
const ApprovalPurchaseRequisition = Loadable(
  lazy(() =>
    import(
      "../views/approval/purchaseRequisition/ApprovalPurchaseRequisitionList"
    ).then(({ ApprovalPurchaseRequisitionList }) => ({
      default: ApprovalPurchaseRequisitionList,
    })),
  ),
);
const ApprovalPurchaseOrder = Loadable(
  lazy(() =>
    import("../views/approval/purchaseOrder/ApprovalPurchaseOrderList").then(
      ({ ApprovalPurchaseOrderList }) => ({
        default: ApprovalPurchaseOrderList,
      }),
    ),
  ),
);

// const ManageApprovalQuote = Loadable(
//   lazy(() =>
//     import("../views/approval/quote/ManageApprovalQuote").then(({ ManageApprovalQuote }) => ({
//       default: ManageApprovalQuote
//     }))
//   )
// )

export const Router = [
  {
    path: "/",

    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/enquiry",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
      //       <Leads />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   path: "/leads/manage",
      //   exact: true,
      //   element: (
      //     // <AuthorizedRoute allowFullAccess moduleIds={[]}>
      //     <ManageLead />
      //     // </AuthorizedRoute>
      //   ),
      // },

      // ================================= Rendering_Paths========================================
      {
        path: "/rendering-jobs",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.RENDERINGS_JOBS]}>
            <RenderingJobs />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/rendering-jobs/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.RENDERINGS_JOBS]}>
            <ManageRenderingJob />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/rendering-jobs/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.RENDERINGS_JOBS]}>
            <ManageRenderingJob />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/rendering-partners",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.RENDERINGS_PARTNERS]}>
            <RenderingPartners />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/rendering-partners/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.RENDERINGS_PARTNERS]}>
            <ManageRenderingPartner />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/rendering-partners/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.RENDERINGS_PARTNERS]}>
            <ManageRenderingPartner />
          </AuthorizedRoute>
        ),
      },
      // {
      //   // path: "/enquiry/manage/:leadId",
      //   path: "/enquiry/manage/:enquiryNumber/:uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
      //       <ViewProjectAndLead />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   // path: "/enquiry/manage/:leadId",
      //   path: "/enquiry/manage/:enquiryNumber/:uuid/:tab",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
      //       <ViewProjectAndLead />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   path: "/enquiry/history/:enquiryNumber/:uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.LEADS]}>
      //       <ViewLeadActivity />
      //     </AuthorizedRoute>
      //   ),
      // },
      // ------------- Quote Routes ----------------------------
      {
        path: "/sales/order-processing",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
            <OrderProcessingList />
          </AuthorizedRoute>
        ),
      },
      // {
      //   path: "/sales/orders",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
      //       <Leads />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   path: "/sales/order-requested",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER_REQUESTED]}>
      //       <ApprovalsList />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   path: "/sales/orders/manage/:enquiryNumber/:uuid/:order_uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.ORDER]}>
      //       <ViewProjectAndLead />
      //     </AuthorizedRoute>
      //   ),
      // },
      // ------------- opportunites ----------------------------
      {
        path: "/opportunites",
        exact: true,
        element: (
          // <AuthorizedRoute allowFullAccess moduleIds={[]}>
          <ViewOpportunites />
          // </AuthorizedRoute>
        ),
      },
      {
        path: "/opportunites/manage",
        exact: true,
        element: (
          // <AuthorizedRoute allowFullAccess moduleIds={[]}>
          <CreateOpportunites />
          // </AuthorizedRoute>
        ),
      },
      {
        path: "/opportunites/manage/:oppId",
        exact: true,
        element: (
          // <AuthorizedRoute allowFullAccess moduleIds={[]}>
          <CreateOpportunites />
          // </AuthorizedRoute>
        ),
      },
      {
        path: "/view-customers",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[]}>
            <CustomersList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/view-profile",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ViewProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/taskboard",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.TASKBOARD]}>
            <TaskBoard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <BranchList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage/:branchUUId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/zone-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ZoneList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage/:userId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.USERS]}>
            <ManageUser isMyProfile={false} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/my-profile",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[]} allowFullAccess>
            <ManageUser isMyProfile={true} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/list-security-groups",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ListSecurityGroups />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/secuirty-role-duplicate/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityDashboard isDuplicate />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/approvals-list",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <SecurityApprovalsList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/manage-approval/:approvalId",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <ManageSecuirtyApproval />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/security/role-groups",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.SECURITY]}>
            <RoleGroupTable />
          </AuthorizedRoute>
        ),
      },
      /************************Approval ************************/
      {
        path: "/approval/quote",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_QUOTE]}>
            <ApprovalQuoteList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/costing-sheet",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_COSTINGSHEET]}>
            <ApprovalCostingSheetList costingSheetType={"costing-sheet"} />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/costing-sheet-raise",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_COSTINGSHEET_RAISE]}>
            <ApprovalCostingSheetList
              costingSheetType={"costing-sheet-raise"}
            />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/costing-sheet-acknowledgement",
        exact: true,
        element: (
          <AuthorizedRoute
            moduleIds={[MODULE_IDS.APPROVAL_COSTINGSHEET_ACKNOWLEDGEMENT]}
          >
            <ApprovalCostingSheetList
              costingSheetType={"costing-sheet-acknowledgement"}
            />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/orf",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_ORF]}>
            <OrfApprovalList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/item",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVAL_ITEM]}>
            <ItemApprovalList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/drawing",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_DRAWING]}>
            <ApprovalDrawingList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/bom",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_BOM]}>
            <ApprovalBomList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/master-bom",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_MASTER_BOM]}>
            <ApprovalMasterBomList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/purchase-requisition",
        exact: true,
        element: (
          <AuthorizedRoute
            moduleIds={[MODULE_IDS.APPROVALS_PURCHASE_REQUISITION]}
          >
            <ApprovalPurchaseRequisition />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/approval/purchase-order",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_PURCHASE_ORDER]}>
            <ApprovalPurchaseOrder />
          </AuthorizedRoute>
        ),
      },

      /************************ Customer Routes ************************/
      {
        path: "/customers",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMERS]}>
            <CustomersList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customers/Manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMERS]}>
            <ManageCustomer />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customers/Manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMERS]}>
            <ManageCustomer />
          </AuthorizedRoute>
        ),
      },
      /************************ Customer Branches Routes ************************/
      {
        path: "/customer-branches",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMERS]}>
            <CustomerBranchList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customer-branches/Manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMERS]}>
            <ManageCustomerBranches />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/customer-branches/Manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CUSTOMERS]}>
            <ManageCustomerBranches />
          </AuthorizedRoute>
        ),
      },
      /************************Contact Routes ************************/
      {
        path: "/contacts",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CONTACTS]}>
            <Contacts />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/contacts/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CONTACTS]}>
            <ManageContact />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/contacts/manage/:id",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CONTACTS]}>
            <ManageContact />
          </AuthorizedRoute>
        ),
      },

      // **************Request Route ********************

      {
        path: "/request",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.REQUEST]}>
            <RequestList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/request/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.REQUEST]}>
            <ManageRequest />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/request/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.REQUEST]}>
            <ManageRequest />
          </AuthorizedRoute>
        ),
      },

      //***********Bom routes ***********/
      {
        path: "/bom",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
            <BomList />
          </AuthorizedRoute>
        ),
      },

      // {
      //   path: "/bom/manage",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
      //       <ManageBom />
      //     </AuthorizedRoute>
      //   ),
      // },
      // {
      //   path: "/bom/manage/:uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
      //       <ManageBom />
      //     </AuthorizedRoute>
      //   ),
      // },

      // // routing routes
      // {
      //   path: "/routing",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.ROUTING]}>
      //       <RoutingList />
      //     </AuthorizedRoute>
      //   ),
      // },

      // {
      //   path: "/routing/manage",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
      //       <ManageRouting />
      //     </AuthorizedRoute>
      //   ),
      // },

      // {
      //   path: "/routing/manage/:uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
      //       <ManageRouting />
      //     </AuthorizedRoute>
      //   ),
      // },
      //***********Master Bom routes ***********/
      {
        path: "/master-bom",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.MASTER_BOM]}>
            <MasterBomList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/master-bom/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.MASTER_BOM]}>
            <ManageMasterBom />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/master-bom/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.MASTER_BOM]}>
            <ManageMasterBom />
          </AuthorizedRoute>
        ),
      },

      //***********Drawing routes ***********/
      {
        path: "/drawing",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <DrawingList />
          </AuthorizedRoute>
        ),
      },

      // {
      //   path: "/drawing/manage",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
      //       <ManageDrawing />
      //     </AuthorizedRoute>
      //   ),
      // },

      // {
      //   path: "/drawing/manage/:uuid",
      //   exact: true,
      //   element: (
      //     <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
      //       <ManageDrawing />
      //     </AuthorizedRoute>
      //   ),
      // },

      //***********drawing item ***********/
      {
        path: "/drawing-item",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <DrawingItemList />
          </AuthorizedRoute>
        ),
      },
      //***********deviation routes ***********/
      {
        path: "/deviation",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <DeviationList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/deviation/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <ManageDeviation />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/deviation/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <ManageDeviation />
          </AuthorizedRoute>
        ),
      },

      //***********Ecn routes ***********/
      {
        path: "/ecn",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ECN]}>
            <EcnList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/ecn/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ECN]}>
            <ManageEcn />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/ecn/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ECN]}>
            <ManageEcn />
          </AuthorizedRoute>
        ),
      },
      //***********Ncp routes ***********/
      {
        path: "/ncp",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.ECN]}>
            <NcpList />
          </AuthorizedRoute>
        ),
      },
      //***********Category routes ***********/
      {
        path: "/category",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.CATEGORY]}>
            <CategoryList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/ncp/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <ManageNcp />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/ncp/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
            <ManageNcp />
          </AuthorizedRoute>
        ),
      },
      //***********Web Product routes ***********/
      {
        path: "/web-products",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.WEB_PRODUCT]}>
            <WebProductList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/web-product/manage",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.WEB_PRODUCT]}>
            <ManageWebProduct />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/web-product/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute moduleIds={[MODULE_IDS.WEB_PRODUCT]}>
            <ManageWebProduct />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/formula-mapping",
        exact: true,
        element: (
          <AuthorizedRoute allowFullAccess moduleIds={[]}>
            <FormulaMapping />
          </AuthorizedRoute>
        ),
      },

      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/", element: <Navigate to="/dashboard" /> },
    ]
      .concat(enquiryRoutes)
      .concat(quotesRoutes)
      .concat(proformaInvoicesRoutes)
      .concat(workOrderRoutes)
      .concat(workOrderJobRoutes)
      .concat(costingSheetRoutes)
      .concat(productsRoutes)
      .concat(projectsRoutes)
      .concat(drawingUploadsRoutes)
      .concat(mrpAllocationsRoutes)
      .concat(vendorsRoutes)
      .concat(purchaseRequisitionsRoutes)
      .concat(purchaseOrdersRoutes)
      .concat(routingModuleRoutes)
      .concat(warehouseRoutes)
      .concat(drawingRoutes)
      .concat(bomRoutes)
      .concat(internalOrdersRoutes)
      .concat(materialRequestsRoutes)
      .concat(grnRoute)
      .concat(partItemsRoutes)
      .concat(gatePassRoutes)
      .concat(planningNavRoutes)
      .concat(productsFiberFinishRoutes)
      .concat(partItemPrefixRoutes)
      .concat(drawingItemPrefixRoutes)
      .concat(priceUpdationRoutes)
      .concat(storeManagementRoutes)
      .concat(storeTransferRoutes)
      .concat(salesModuleRoutes)
      .concat(storeTransferRoutes)
      .concat(batchRoutes)
      .concat(assemblyRoutes)
      .concat(orderDocumentRoutes)
      .concat(internalOrderBomRoutes),
  },

  // --------------------------------TASK ROUTING----------------------------------------------

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "forget-password", element: <ForgetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/public/quotes/:quoteId",
    element: <QuotesInvoice />,
  },
  {
    path: "/layouts",
    element: <QuoteWrapper />,
  },
  {
    path: "/bom-items/manage/:uuid",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
        <ManageFullViewBomItems />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/bom-items/manage/:product_code/:order_no",
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
        <ManageFullViewBomItems />
      </AuthorizedRoute>
    ),
  },
];
