import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_WAREHOUSE_ROUTE } from "./Warehouse.constants";

const WarehouseListTable = Loadable(
  lazy(() =>
    import("./WarehouseList").then(({ WarehouseList }) => ({
      default: WarehouseList,
    })),
  ),
);

export const warehouseRoutes = [
  {
    path: APP_WAREHOUSE_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.WAREHOUSE]}>
        <WarehouseListTable />
      </AuthorizedRoute>
    ),
  },
];
