import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IBdl, IOrderBreakDownList } from "./bdl.types";
import { defaultBdl } from "./defaultState";

export const FETCH_BDL_LIST_PROGRESS = "FETCH_BDL_LIST_PROGRESS";
export const FETCH_BDL_LIST_SUCCESS = "FETCH_BDL_LIST_SUCCESS";
export const FETCH_BDL_LIST_FAILED = "FETCH_BDL_LIST_FAILED";

export const fetchBdlListProgress = () => action(FETCH_BDL_LIST_PROGRESS);
export const fetchBdlListSuccess = (list: IBdl[], totalRecords: number) =>
  action(FETCH_BDL_LIST_SUCCESS, { list, totalRecords });
export const fetchBdlListFailed = () => action(FETCH_BDL_LIST_FAILED);

export const fetchBdlListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchBdlListProgress());
      const res = await api.get(`/bom/get-requested-bdl${searchQuery}`);
      const data: IBdl[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchBdlListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBdlListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_BDL_PROGRESS = "FETCH_BDL_PROGRESS";
export const FETCH_BDL_SUCCESS = "FETCH_BDL_SUCCESS";
export const FETCH_BDL_FAILED = "FETCH_BDL_FAILED";

export const fetchBdlProgress = () => action(FETCH_BDL_PROGRESS);
export const fetchBdlSuccess = (data: IBdl) =>
  action(FETCH_BDL_SUCCESS, { data });
export const fetchBdlFailed = (errorMessage: string) =>
  action(FETCH_BDL_FAILED, { errorMessage });

export const fetchBdlAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean, data: IBdl) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBdlProgress());
      const res = await api.get(`/bom/get-requested-bdl?bom_uuid=${uuid}`);
      const data: IBdl[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchBdlSuccess(data[0]));
        onCallback(true, data[0]);
      } else {
        onCallback(false, defaultBdl);
      }
    } catch (err: any) {
      dispatch(fetchBdlFailed("Something went to be wrong!"));
      onCallback(false, defaultBdl);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertBdlAsync =
  (
    data: IBdl,

    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/request-bdl", payload);
      onCallback(true);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Bdl requested successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_SINGLE_ORDER_BDL_PROGRESS =
  "FETCH_SINGLE_ORDER_BDL_PROGRESS";
export const FETCH_SINGLE_ORDER_BDL_SUCCESS = "FETCH_SINGLE_ORDER_BDL_SUCCESS";
export const FETCH_SINGLE_ORDER_BDL_FAILED = "FETCH_SINGLE_ORDER_BDL_FAILED";

export const fetchSingleOrderBDLProgress = () =>
  action(FETCH_SINGLE_ORDER_BDL_PROGRESS);
export const fetchSingleOrderBDLSuccess = (data: IBdl) =>
  action(FETCH_SINGLE_ORDER_BDL_SUCCESS, { data });
export const fetchSingleOrderBDLFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_ORDER_BDL_FAILED, { errorMessage });

export const fetchSingleBreakDownListBySodNumberAsync =
  (
    sod_number: string,
    isAssemblyInfo: number,
    onCallback: (isSuccess: boolean, data: IBdl) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleOrderBDLProgress());
      const res = await api.get(
        `/planning/get-order-bdl?order_no=${sod_number}&isAssemblyInfo=${isAssemblyInfo}`,
      );
      const data: IBdl[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleOrderBDLSuccess(data[0]));
        onCallback(true, data[0]);
      } else {
        onCallback(false, defaultBdl);
      }
    } catch (err: any) {
      dispatch(fetchSingleOrderBDLFailed("Something went to be wrong!"));
      onCallback(false, defaultBdl);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleBdlFromOrderAsync =
  (
    order_no: string,
    onCallback: (isSuccess: boolean, data?: IOrderBreakDownList) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.post("/planning/upsert-order-bdl", {
        order_no,
      });
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Bdl requested successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const fetchBdlbomProgress =
  (
    sod_number: string,
    onCallback: (isSuccess: boolean, data?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.get(
        `/planning/check-bom-progress-status?sod_no=${sod_number}`,
      );
      onCallback(true, response.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BDL = "CLEAR_BDL";
export const clearBdlAsync = () => action(CLEAR_BDL);
export const CLEAR_BDL_STATE = "CLEAR_BDL_STATE";
export const clearBdlListAsync = () => action(CLEAR_BDL_STATE);
