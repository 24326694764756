import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_GATE_PASS_ROUTE } from "./gatepass.constant";

const GatepassList = Loadable(
  lazy(() =>
    import("./GatePassList").then(({ GatePassList }) => ({
      default: GatePassList,
    })),
  ),
);
const ManageSingleGatePass = Loadable(
  lazy(() =>
    import("./ManageGatePassTabs").then(({ ManageGatePassTabs }) => ({
      default: ManageGatePassTabs,
    })),
  ),
);

export const gatePassRoutes = [
  {
    path: APP_GATE_PASS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.GATE_PASS]}>
        <GatepassList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_GATE_PASS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.GATE_PASS]}>
        <ManageSingleGatePass />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_GATE_PASS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.GATE_PASS]}>
        <ManageSingleGatePass />
      </AuthorizedRoute>
    ),
  },
];
