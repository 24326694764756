import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import {
  closeLoaderWithMessage,
  openLoaderWithMessage,
  showMessage,
} from "../messages/messagesActions";
import { ISearchQueryParams } from "../common/common.types";
import { IBatch, ICancelBatch, IVerifyBatch } from "./batch.types";
import { getSearchQueryV2 } from "../common/helpers";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
} from "../messages/messagesActions";
import {
  SERVER_CANCEL_BATCH_ROUTE,
  SERVER_GET_BATCH_ROUTE,
  SERVER_UPSERT_BATCH_ROUTE,
  SERVER_VERIFY_BATCH_ROUTE,
} from "./batch.constant";
import { getFileExtensionFromUrl } from "../../helpers";

export const FETCH_BATCH_LIST_PROGRESS = "FETCH_BATCH_LIST_PROGRESS";
export const FETCH_BATCH_LIST_SUCCESS = "FETCH_BATCH_LIST_SUCCESS";
export const FETCH_BATCH_LIST_FAILED = "FETCH_BATCH_LIST_FAILED";

export const fetchBatchListProgress = () => action(FETCH_BATCH_LIST_PROGRESS);
export const fetchBatchListSuccess = (list: IBatch[], totalRecords: number) =>
  action(FETCH_BATCH_LIST_SUCCESS, { list, totalRecords });
export const fetchBatchListFailed = () => action(FETCH_BATCH_LIST_FAILED);

export const fetchBatchListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchBatchListProgress());
      const res = await api.get(`${SERVER_GET_BATCH_ROUTE}?${searchQuery}`);
      const data: IBatch[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBatchListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBatchListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_BATCH_PROGRESS = "FETCH_BATCH_PROGRESS";
export const FETCH_BATCH_SUCCESS = "FETCH_BATCH_SUCCESS";
export const FETCH_BATCH_FAILED = "FETCH_BATCH_FAILED";

export const fetchBatchProgress = () => action(FETCH_BATCH_PROGRESS);
export const fetchBatchSuccess = (data: IBatch) =>
  action(FETCH_BATCH_SUCCESS, { data });
export const fetchBatchFailed = () => action(FETCH_BATCH_FAILED);

export const fetchBatchAsync =
  (batchUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchBatchProgress());
      const res = await api.get(
        `${SERVER_GET_BATCH_ROUTE}?batch_uuid=${batchUUID}`,
      );
      const data: IBatch = res.data.data[0];

      dispatch(fetchBatchSuccess(data));
    } catch (err: any) {
      dispatch(fetchBatchFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_VERIFY_BATCH_PROGRESS = "FETCH_VERIFY_BATCH_PROGRESS";
export const FETCH_VERIFY_BATCH_SUCCESS = "FETCH_VERIFY_BATCH_SUCCESS";
export const FETCH_VERIFY_BATCH_FAILED = "FETCH_VERIFY_BATCH_FAILED";

export const fetchVerifyBatchProgress = () =>
  action(FETCH_VERIFY_BATCH_PROGRESS);
export const fetchVerifyBatchSuccess = (data: IVerifyBatch) =>
  action(FETCH_VERIFY_BATCH_SUCCESS, { data });
export const fetchVerifyBatchFailed = () => action(FETCH_VERIFY_BATCH_FAILED);
export const fetchVerifyBatchAsync =
  (
    sod_no: string,
    part_code: string,
    onCallback: (isSuccess: boolean, data?: IVerifyBatch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchVerifyBatchProgress());
      const res = await api.get(
        `${SERVER_VERIFY_BATCH_ROUTE}?sod_no=${sod_no}&part_code=${part_code}`,
      );
      const data: IVerifyBatch = res.data.data;

      dispatch(fetchVerifyBatchSuccess(data));
      onCallback(true, data);
    } catch (err: any) {
      dispatch(fetchVerifyBatchFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertBatchAsync =
  (
    data: IBatch,
    onCallback: (isSuccess: boolean, data?: IBatch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      const {
        create_ts,
        rev_no,
        created_by_uuid,
        batch_number,
        created_by_name,
        sod_no,
        ...payload
      } = data;
      dispatch(saveLoaderProgress());
      const response = await api.post(SERVER_UPSERT_BATCH_ROUTE, payload);
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Batch saved successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const cancelBatchAsync =
  (
    data: ICancelBatch,
    onCallback: (isSuccess: boolean, data?: ICancelBatch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.post(SERVER_CANCEL_BATCH_ROUTE, data);
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Batch cancelled successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_BATCH = "CLEAR_BATCH";
export const clearBatchAsync = () => action(CLEAR_BATCH);
export const CLEAR_BATCH_STATE = "CLEAR_BATCH_STATE";
export const clearBatchListAsync = () => action(CLEAR_BATCH_STATE);

export const createMRFromBatchAsync =
  (
    batch_uuid: string,
    onCallback: (isSuccess: boolean, data?: ICancelBatch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.post("/planning/create-mr-from-batch", {
        batch_uuid,
      });
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "MR created successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const previewOrDownloadWorkOrderAsync =
  (
    payload: {
      batch_uuid: string;
      assembly: string;
      isPreviewOrUpload: "EJS" | "PDF";
    },
    onCallback: (isSuccess: boolean, data?: ICancelBatch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(openLoaderWithMessage("Fetch Work Order Details...!"));
      const response = await api.get("/work_order/get-work-order-preview", {
        params: payload,
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "WorkOrder.pdf"); // Set the desired file name and extension
      document.body.appendChild(link);
      link.click();

      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "File Downloaded successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(closeLoaderWithMessage());
    }
  };

export const getReservedBatchItemQuantityAsync =
  (
    payload: {
      part_code: string;
      order_no: string;
      part_item_uuid?: string;
    },
    onCallback: (isSuccess: boolean, data?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      let url = `/work_order/get-reserved-batch-item-quantity?part_code=${payload.part_code}&order_no=${payload.order_no}`;
      if (payload.part_item_uuid) {
        url += `&part_item_uuid=${payload.part_item_uuid}`;
      }
      const response = await api.get(url);
      onCallback(true, response.data.data);
    } catch (err: any) {
      onCallback(false);
    }
  };
