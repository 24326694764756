import { action } from "typesafe-actions";
import { IDraft, IDraftState } from "./draft.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";

export const FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_PROGRESS =
  "FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_PROGRESS";
export const FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_SUCCESS =
  "FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_SUCCESS";
export const FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_FAILED =
  "FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_FAILED";

export const fetchMultipleDraftsForSingleUserProgress = () =>
  action(FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_PROGRESS);

export const fetchMultipleDraftsForSingleUserSuccess = (
  data: IDraftState["multiple_drafts"]["data"],
  totalRecords: number,
) =>
  action(FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_SUCCESS, { data, totalRecords });
export const fetchMultipleDraftsForSingleUserFailed = () =>
  action(FETCH_MULTIPLE_DRAFT_FOR_SINGLE_USER_FAILED);

export const fetchMultipleDraftsForSingleUserAsync =
  (
    user_uuid: string,
    ref_data_uuid: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchMultipleDraftsForSingleUserProgress());
      const res = await api.get(
        `/draft/get-draft?created_by_uuid=${user_uuid}&reference_data_uuid=${ref_data_uuid}`,
      );
      const data: IDraftState["multiple_drafts"]["data"] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchMultipleDraftsForSingleUserSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchMultipleDraftsForSingleUserFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SINGLE_DRAFT_FOR_USER_PROGRESS =
  "FETCH_SINGLE_DRAFT_FOR_USER_PROGRESS";
export const FETCH_SINGLE_DRAFT_FOR_USER_SUCCESS =
  "FETCH_SINGLE_DRAFT_FOR_USER_SUCCESS";
export const FETCH_SINGLE_DRAFT_FOR_USER_FAILED =
  "FETCH_SINGLE_DRAFT_FOR_USER_FAILED";

export const fetchSingleDrarftForUserProgress = () =>
  action(FETCH_SINGLE_DRAFT_FOR_USER_PROGRESS);
export const fetchSingleDrarftForUserSuccess = (data: IDraft) =>
  action(FETCH_SINGLE_DRAFT_FOR_USER_SUCCESS, { data });
export const fetchSingleDrarftForUserFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_DRAFT_FOR_USER_FAILED, { errorMessage });

export const fetchSingleDrarftForUserAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleDrarftForUserProgress());
      const res = await api.get(`/bom/get-drawing?drawing_uuid=${uuid}`);
      const data: IDraft[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleDrarftForUserSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleDrarftForUserFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleDrarftForUserFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleDraftAsync =
  (
    data: IDraft,
    // onCallback: (isSuccess: boolean, Drawing?: IDraft) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...restPayload } = data;
      const res = await api.post("/draft/upsert-draft", restPayload);
      let message = "Draft saved successfully!";
      // onCallback(true, res.data.data);
    } catch (err: any) {
      // onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_DRAFT_STATE = "CLEAR_DRAFT_STATE";
export const CLEAR_SINLE_DRAFT_STATE_STATE = "CLEAR_SINLE_DRAFT_STATE_STATE";
export const clearDraftStateSync = () => action(CLEAR_DRAFT_STATE);
export const clearSingleStateSync = () => action(CLEAR_SINLE_DRAFT_STATE_STATE);
