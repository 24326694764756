import { LoadState } from "../../constants/enums";
import { IOrderDocumentState, ISalesDocument } from "./orderDocument.types";

export type { IOrderDocumentState } from "./orderDocument.types";

export const defaultSalesDocument: ISalesDocument = {
  sales_document_uuid: "",
  sales_document_no: "",
  sales_running_no: null,
  sales_document_type: "",
  file_url: "",
  description: "",
  sales_reference: "",
  create_ts: "",
  insert_ts: "",
};

export const defaultOrderDocumentState: IOrderDocumentState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  document: {
    loading: LoadState.NotLoaded,
    data: defaultSalesDocument,
    error: null,
  },
};
