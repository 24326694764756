import { OrderNoAutoSearchByProjectName } from "../components/AutoCompleteSearches/OrderNoAutoSearchByProjectName";

export const appNavRoutes = {
  dashboard: "",
  planning: {
    root: "/planning",
    bom: "/planning/bom",
    bdl: "/planning/bdl",
  },
  sales: {
    enquiries: "/sales/enquiries",
    orf: "/sales/enquiry-orf",
    proformaInvoice: "/sales/proforma-invoices",
    orders: "/sales/orders",
    approvedOrders: "/sales/approved-orders",
  },
  approvals: {
    orders: "/approvals/order-approvals-requests",
  },
  internal_orders: {
    internal_orders: "/internal-orders",
    internal_order_bom: "/internal-order-bom",
  },
};
