import { ISelectOption } from "../../constants/types";

export const PRODUCTS_ROUTE = "/items/products";
export const PRODUCT_TEMPLATES_ROUTE = "/items/product-templates";
export const UPLOAD_PRODUCTS_ROUTE = "/items/upload-products";
export const PRODUCT_METAL_FINISH_ROUTE = "/items/metal-finishes";
export const PRODUCT_WOOD_FINISH_ROUTE = "/items/wood-finishes";
export const PRODUCT_ACRYLIC_FINISH_ROUTE = "/items/arcylic-finishes";
export const PRODUCT_FIBER_FINISH_ROUTE = "/items/fiber-finishes";

export const product_metal: ISelectOption[] = [
  { label: "PEARL SILVER(FS", value: "PEARL SILVER" },
  { label: "ALPINE WHITE(FW)", value: "ALPINE WHITE" },
  { label: "CHAR COAL(FE)", value: "CHAR COAL" },
  { label: "ARABICA BLACK(FB)", value: "ARABICA BLACK" },
  { label: "MANDARIN ORANGE(FO)", value: "MANDARIN ORANGE" },
  { label: "VIBRANT GREEN(FG)", value: "VIBRANT GREEN" },
  { label: "PEACEFUL BLUE(FU)", value: "PEACEFUL BLUE" },
  { label: "MOUSE GREY(FD)", value: "MOUSE GREY" },
  { label: "MARKER BOARD WHITE(FM)", value: "MARKER BOARD WHITE" },
];

export const product_glass: ISelectOption[] = [
  { label: "GLASS CLEAR(GC)", value: "GLASS CLEAR" },
  { label: "GLASS WHITE(GW)", value: "GLASS WHITE" },
  { label: "GLASS BLACK(GW)", value: "GLASS BLACK" },
  { label: "GLASS FROSTED(GF)", value: "GLASS FROSTED" },
];

export const product_acrylic: ISelectOption[] = [
  { label: "ACRYLIC CLEAR(AC)", value: "ACRYLIC CLEAR" },
  { label: "ACRYLIC WHITE(AW)", value: "ACRYLIC WHITE" },
  { label: "ACRYLIC FROSTED(AF)", value: "ACRYLIC FROSTED" },
];

export const product_polycarbonate: ISelectOption[] = [
  { label: "POLYCARBONATE CLEAR(AC)", value: "POLYCARBONATE CLEAR" },
];

export const product_wood: ISelectOption[] = [
  { label: "HPL LIGHT MAPLE(HP)", value: "HPL LIGHT MAPLE" },
  { label: "HPL PUTTY GREY(HL)", value: "HPL PUTTY GREY" },
  { label: "HPL IVORY WHITE(HW)", value: "HPL IVORY WHITE" },
  { label: "HPL LIGHT BEACH(HB)", value: "HPL LIGHT BEACH" },
  { label: "HPL TULIP BLACK(HK)", value: "HPL TULIP BLACK" },
  { label: "MELAMINE DOUGLAS PINE 483", value: "MELAMINE DOUGLAS PINE" },
  { label: "MELAMINE FUSHION MAPLE 3525", value: "MELAMINE FUSHION MAPLE" },
  { label: "MELAMINE HIGHLAND PINE 3532", value: "MELAMINE HIGHLAND PINE" },
  {
    label: "MELAMINE CLASSIC PLANKED WALNUT 4429",
    value: "MELAMINE CLASSIC PLANKED WALNUT",
  },
  { label: "MELAMINE OFF WHITE 118", value: "MELAMINE OFF WHITE" },
  { label: "MELAMINE SILVER GREY 222", value: "MELAMINE SILVER GREY" },
  { label: "MELAMINE BLACK 293", value: "MELAMINE BLACK" },
  { label: "MELAMINE CHARCOAL(ME)", value: "MELAMINE CHARCOAL" },
  { label: "MELAMINE GLACIER(MS)", value: "MELAMINE GLACIER" },
  { label: "MELAMINE ICE WHITE(MW)", value: "MELAMINE ICE WHITE" },
  { label: "MELAMINE DOLPHIN GREY(ML)", value: "MELAMINE DOLPHIN GREY" },
  { label: "MELAMINE CHERRY OAK(MC)", value: "MELAMINE CHERRY OAK" },
  { label: "MELAMINE FRENCH BEECH(MB)", value: "MELAMINE FRENCH BEECH" },
  { label: "MELAMINE STORM BLACK(MK)", value: "MELAMINE STORM BLACK" },
  { label: "MELAMINE VINTAGE OAK(MD)", value: "MELAMINE VINTAGE OAK" },
  { label: "MELAMINE SUMMER MAPLE(MP)", value: "MELAMINE SUMMER MAPLE" },
  { label: "MELAMINE NATURAL OAK(MO)", value: "MELAMINE NATURAL OAK" },
  { label: "MELAMINE CHERRY CAPUCINO(MU)", value: "MELAMINE CHERRY CAPUCINO" },
  { label: "MELAMINE SHIMO ASH(MH)", value: "MELAMINE " },
  { label: "MELAMINE NOCE NOGARO(MN)", value: "MELAMINE NOCE NOGARO" },
  { label: "MELAMINE AVIGNION(MV)", value: "MELAMINE AVIGNION" },
  { label: "VENEER ZEBRANO(VZ)", value: "VENEER ZEBRANO" },
  { label: "VENEER VANILLA MAPLE(VP)", value: "VENEER VANILLA MAPLE" },
  { label: "VENEER STAINED BEECH(VE)", value: "VENEER STAINED BEECH" },
  { label: "VENEER WHITE OAK(VO)", value: "VENEER WHITE OAK" },
  { label: "VENEER ESPRESSO EBONY(VY)", value: "VENEER ESPRESSO EBONY" },
  { label: "VENEER ROSE-WOOD(VR)", value: "VENEER ROSE-WOOD" },
  { label: "VENEER CINNAMON WALNUT(VM)", value: "VENEER CINNAMON WALNUT" },
  { label: "VENEER RED CHERRY(VC)", value: "VENEER RED CHERRY" },
];
