import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import {
  closeLoaderWithMessage,
  openLoaderWithMessage,
  showMessage,
} from "../messages/messagesActions";
import { ISearchQueryParams } from "../common/common.types";
import { ISalesDocument } from "./orderDocument.types";
import { getSearchQueryV2 } from "../common/helpers";
import { SERVER_GET_SALES_DOCUMENT_ROUTE } from "./orderDocument.constant";

export const FETCH_SALES_DOCUMENT_LIST_PROGRESS =
  "FETCH_SALES_DOCUMENT_LIST_PROGRESS";
export const FETCH_SALES_DOCUMENT_LIST_SUCCESS =
  "FETCH_SALES_DOCUMENT_LIST_SUCCESS";
export const FETCH_SALES_DOCUMENT_LIST_FAILED =
  "FETCH_SALES_DOCUMENT_LIST_FAILED";

export const fetchSalesDocumentListProgress = () =>
  action(FETCH_SALES_DOCUMENT_LIST_PROGRESS);
export const fetchSalesDocumentListSuccess = (
  list: ISalesDocument[],
  totalRecords: number,
) => action(FETCH_SALES_DOCUMENT_LIST_SUCCESS, { list, totalRecords });
export const fetchSalesDocumentListFailed = () =>
  action(FETCH_SALES_DOCUMENT_LIST_FAILED);

export const fetchSalesDocumentListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchSalesDocumentListProgress());
      const res = await api.get(
        `${SERVER_GET_SALES_DOCUMENT_ROUTE}?${searchQuery}`,
      );
      const data: ISalesDocument[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchSalesDocumentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSalesDocumentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_SALES_DOCUMENT_PROGRESS = "FETCH_SALES_DOCUMENT_PROGRESS";
export const FETCH_SALES_DOCUMENT_SUCCESS = "FETCH_SALES_DOCUMENT_SUCCESS";
export const FETCH_SALES_DOCUMENT_FAILED = "FETCH_SALES_DOCUMENT_FAILED";

export const fetchSalesDocumentProgress = () =>
  action(FETCH_SALES_DOCUMENT_PROGRESS);
export const fetchSalesDocumentSuccess = (data: ISalesDocument) =>
  action(FETCH_SALES_DOCUMENT_SUCCESS, { data });
export const fetchSalesDocumentFailed = () =>
  action(FETCH_SALES_DOCUMENT_FAILED);

export const fetchSalesDocumentAsync =
  (documentUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(fetchSalesDocumentProgress());
      const res = await api.get(
        `${SERVER_GET_SALES_DOCUMENT_ROUTE}?sales_document_uuid=${documentUUID}`,
      );
      const data: ISalesDocument = res.data.data[0];

      dispatch(fetchSalesDocumentSuccess(data));
    } catch (err: any) {
      dispatch(fetchSalesDocumentFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_SALES_DOCUMENT = "CLEAR_SALES_DOCUMENT";
export const clearSalesDocumentAsync = () => action(CLEAR_SALES_DOCUMENT);

export const CLEAR_SALES_DOCUMENT_STATE = "CLEAR_SALES_DOCUMENT_STATE";
export const clearSalesDocumentListAsync = () =>
  action(CLEAR_SALES_DOCUMENT_STATE);
