import produce from "immer";
import {
  CLEAR_PURCHASE_ORDER,
  CLEAR_PURCHASE_ORDER_STATE,
  FETCH_APPROVAL_ORDER_LIST_FAILED,
  FETCH_APPROVAL_ORDER_LIST_PROGRESS,
  FETCH_APPROVAL_ORDER_LIST_SUCCESS,
  FETCH_LATEST_PURCHASE_ORDER_FAILED,
  FETCH_LATEST_PURCHASE_ORDER_PROGRESS,
  FETCH_LATEST_PURCHASE_ORDER_SUCCESS,
  FETCH_PURCHASE_ORDER_FAILED,
  FETCH_PURCHASE_ORDER_LIST_FAILED,
  FETCH_PURCHASE_ORDER_LIST_PROGRESS,
  FETCH_PURCHASE_ORDER_LIST_SUCCESS,
  FETCH_PURCHASE_ORDER_PROGRESS,
  FETCH_PURCHASE_ORDER_SUCCESS,
} from "./purchase-order.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultPurchaseOrder,
  defaultPurchaseOrderState,
  PurchaseOrdersActions,
} from ".";

export const purchaseOrderReducer = (
  state: IStoreState["purchaseOrder"] = defaultPurchaseOrderState,
  action: PurchaseOrdersActions,
) => {
  switch (action.type) {
    case FETCH_PURCHASE_ORDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.purchase_order_list.loading = LoadState.InProgress;
        draftState.purchase_order_list.data = [];
        draftState.purchase_order_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PURCHASE_ORDER_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.purchase_order_list.loading = LoadState.Loaded;
        draftState.purchase_order_list.data = data;
        draftState.purchase_order_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PURCHASE_ORDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.purchase_order_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_PURCHASE_ORDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PURCHASE_ORDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.Loaded;
        draftState.single_purchase_order.data = data;
      });
      return newState;
    }
    case FETCH_PURCHASE_ORDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.Failed;
        draftState.single_purchase_order.error = errorMessage;
      });
      return newState;
    }

    case FETCH_LATEST_PURCHASE_ORDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.latest_purchase_order.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_LATEST_PURCHASE_ORDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.latest_purchase_order.loading = LoadState.Loaded;
        draftState.latest_purchase_order.data = data;
      });
      return newState;
    }
    case FETCH_LATEST_PURCHASE_ORDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.latest_purchase_order.loading = LoadState.Failed;
        draftState.latest_purchase_order.error = errorMessage;
      });
      return newState;
    }
    case FETCH_APPROVAL_ORDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_APPROVAL_ORDER_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.Loaded;
        draftState.approval.data = data;
        draftState.approval.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_APPROVAL_ORDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_PURCHASE_ORDER: {
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.NotLoaded;
        draftState.single_purchase_order.data = defaultPurchaseOrder;
        draftState.single_purchase_order.error = null;
      });
      return newState;
    }

    case CLEAR_PURCHASE_ORDER_STATE: {
      return defaultPurchaseOrderState;
    }

    default: {
      return state;
    }
  }
};
