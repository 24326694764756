import { action } from "typesafe-actions";
import {
  IRole,
  ISecurityApproval,
  ISecurityGroup,
  ISecurityNestedGroup,
} from "./security.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { ISelectOption } from "../../constants/types";

export const FETCH_SECURITY_GROUP_PROGRESS = "FETCH_SECURITY_GROUP_PROGRESS";
export const FETCH_SECURITY_GROUP_SUCCESS = "FETCH_SECURITY_GROUP_SUCCESS";
export const FETCH_SECURITY_GROUP_FAILED = "FETCH_SECURITY_GROUP_FAILED";

export const fetchSecurityGroupProgress = () =>
  action(FETCH_SECURITY_GROUP_PROGRESS);
export const fetchSecurityGroupSuccess = (
  data: ISecurityNestedGroup,
  role: string | null,
  roleGroup: string | null,
  status: string | null,
) => action(FETCH_SECURITY_GROUP_SUCCESS, { data, role, roleGroup, status });
export const fetchSecurityGroupFailed = (errorMessage?: string) =>
  action(FETCH_SECURITY_GROUP_FAILED, { errorMessage });

const createNestedSecurityGroup = (groups: ISecurityGroup[]) => {
  const initialNestedGroup: ISecurityNestedGroup = {
    modules: {},
  };
  for (const group of groups) {
    if (initialNestedGroup.modules[group.module_name]) {
      initialNestedGroup.modules[group.module_name].children.push(group);
    } else {
      initialNestedGroup.modules[group.module_name] = {
        children: [group],
      };
    }
  }
  return initialNestedGroup;
};

const parseNestedSecurityGroups = (group: ISecurityNestedGroup) => {
  let list: ISecurityGroup[] = [];
  for (const key in group.modules) {
    const item = group.modules[key];
    let childs: any = [];
    if (item.children.length > 0) {
      childs = [...item.children];
    }
    list = [...list, ...childs];
  }
  return list;
};

export const fetchSecurityGroupAsync =
  (roleId?: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSecurityGroupProgress());
      let url = "/security/get-role-module-content-access-permission";
      if (roleId) {
        url = `/security/get-role-module-content-access-permission?role_uuid=${roleId}`;
      }
      const res = await api.get(url);
      const data: ISecurityGroup[] = roleId
        ? res.data.data.data
        : res.data.data;
      const roleName = roleId ? res.data.data.role_name : null;
      const roleGroup = roleId ? res.data.data.role_group : null;
      const roleStatus = roleId ? res.data.data.status : "ACTIVE";
      if (data.length > 0) {
        const nested = createNestedSecurityGroup(data);
        dispatch(
          fetchSecurityGroupSuccess(nested, roleName, roleGroup, roleStatus),
        );
      } else {
        dispatch(
          fetchSecurityGroupFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSecurityGroupAsync =
  (
    data: ISecurityNestedGroup,
    roleId: string | null,
    role_group: string,
    roleName: string,
    status: string,
    onCallback: (isSuccess: boolean, roleId?: number) => void,
    isDuplicate?: boolean,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const list = parseNestedSecurityGroups(data);
      let finalRoleId = null;

      const res = await api.post("/security/upsert-roles", {
        role_name: roleName,
        role_uuid: isDuplicate ? null : roleId,
        role_group: role_group,
        status: status,
      });
      finalRoleId = res.data.data.role_uuid;

      const finalList: ISecurityGroup[] = [];
      for (let role of list) {
        const { role_module_uuid, ...payload } = role as any;
        const finalRole = isDuplicate ? payload : role;
        finalList.push({
          ...finalRole,
          role_uuid: finalRoleId,
          role_name: roleName,
        });
      }
      await api.post(
        "/security/upsert-role-module-content-access-permission",
        finalList,
      );
      onCallback(true, finalRoleId);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Role saved successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_ROLES_LIST_PROGRESS = "FETCH_ROLES_LIST_PROGRESS";
export const FETCH_ROLES_LIST_SUCCESS = "FETCH_ROLES_LIST_SUCCESS";
export const FETCH_ROLES_LIST_FAILED = "FETCH_ROLES_LIST_FAILED";

export const fetchRolesListProgress = () => action(FETCH_ROLES_LIST_PROGRESS);
export const fetchRolesListSuccess = (list: IRole[], totalRecords: number) =>
  action(FETCH_ROLES_LIST_SUCCESS, { list, totalRecords });
export const fetchRolesListFailed = () => action(FETCH_ROLES_LIST_FAILED);

export const fetchRolesListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRolesListProgress());

      const res = await api.get("/security/get-roles");
      const data: IRole[] = res.data.data;

      dispatch(fetchRolesListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchRolesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchRecordDropdownAsync =
  (
    apiUrl: string,
    columnKey: string,

    columnLabel: string,
    onCallback: (isSuccess: boolean, data: ISelectOption[]) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(`${apiUrl}`);
      const data: any[] = res.data.data;
      const options: ISelectOption[] = [
        { label: "Self", value: columnKey },
        { label: "All", value: "*" },
        { label: "Self Zone", value: "self_zone" },
      ];
      for (let item of data) {
        options.push({ label: item[columnLabel], value: item[columnKey] });
      }
      onCallback(true, options);
    } catch (err: any) {
      onCallback(false, []);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_SECURITY_GROUP = "CLEAR_SECURITY_GROUP";
export const clearSecurityGroup = () => action(CLEAR_SECURITY_GROUP);

export const FETCH_SECURITY_APPROVAL_LIST_PROGRESS =
  "FETCH_SECURITY_APPROVAL_LIST_PROGRESS";
export const FETCH_SECURITY_APPROVAL_LIST_SUCCESS =
  "FETCH_SECURITY_APPROVAL_LIST_SUCCESS";
export const FETCH_SECURITY_APPROVAL_LIST_FAILED =
  "FETCH_SECURITY_APPROVAL_LIST_FAILED";

export const fetchSecurityApprovalListProgress = () =>
  action(FETCH_SECURITY_APPROVAL_LIST_PROGRESS);
export const fetchSecurityApprovalListSuccess = (
  data: ISecurityApproval[],
  totalRecords: number,
) => action(FETCH_SECURITY_APPROVAL_LIST_SUCCESS, { data, totalRecords });
export const fetchSecurityApprovalListFailed = () =>
  action(FETCH_SECURITY_APPROVAL_LIST_FAILED);

export const FETCH_SECURITY_APPROVAL_PROGRESS =
  "FETCH_SECURITY_APPROVAL_PROGRESS";
export const FETCH_SECURITY_APPROVAL_SUCCESS =
  "FETCH_SECURITY_APPROVAL_SUCCESS";
export const FETCH_SECURITY_APPROVAL_FAILED = "FETCH_SECURITY_APPROVAL_FAILED";

export const fetchSecurityApprovalListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSecurityApprovalListProgress());

      const res = await api.get(
        `/approval/get-approval-count?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}`,
      );
      const data: ISecurityApproval[] = res.data.data;

      dispatch(fetchSecurityApprovalListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchSecurityApprovalListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchSecurityApprovalProgress = () =>
  action(FETCH_SECURITY_APPROVAL_PROGRESS);
export const fetchSecurityApprovalSuccess = (data: ISecurityApproval) =>
  action(FETCH_SECURITY_APPROVAL_SUCCESS, { data });
export const fetchSecurityApprovalFailed = (errorMessage: string) =>
  action(FETCH_SECURITY_APPROVAL_FAILED, { errorMessage });

export const fetchSecurityApprovalAsync =
  (
    approval_count_uuid?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSecurityApprovalProgress());
      const url = `/approval/get-approval-count?approval_count_uuid=${approval_count_uuid}&pageNo=1&itemPerPage=10`;

      const res = await api.get(url);
      const data: ISecurityApproval[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSecurityApprovalSuccess(data[0]));
      } else {
        dispatch(
          fetchSecurityApprovalFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSecurityApproval =
  (
    data: ISecurityApproval,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const { create_ts, level, insert_ts, ...rest } = data;
    try {
      dispatch(saveLoaderProgress());
      await api.post("/approval/insert-approval-count", rest);
      let message = "Security approval saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SECURITY_APPROVAL = "CLEAR_SECURITY_APPROVAL";
export const clearSecurityApproval = () => action(CLEAR_SECURITY_APPROVAL);
