import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { debounce } from "lodash";

export const CustomTextFieldStyled = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .MuiOutlinedInput-input": {
    padding: "9px 13px",
    fontSize: "0.8rem",
    color: "rgb(38, 38, 38)",
  },

  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${
      theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.12) " : "#f8f9fb "
    }`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));

export const CustomTextField: React.FC<
  TextFieldProps & { readOnlyMode?: boolean }
> = (props) => {
  const { readOnlyMode = false, ...rest } = props;

  // const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {

  // };

  if (readOnlyMode) {
    return (
      <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
        <>{props.value || "--"}</>
      </Typography>
    );
  }

  return (
    <>
      <CustomTextFieldStyled {...rest} />
    </>
  );
};

export const CustomTextFieldV2: React.FC<
  TextFieldProps & { readOnlyMode?: boolean }
> = React.memo((props) => {
  const { readOnlyMode = false, value, ...rest } = props;
  const [internalValue, setInternalValue] = React.useState<string | undefined>(
    value as string | undefined,
  );

  // Sync state with props.value when it changes
  React.useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value as string | undefined);
    }
  }, [value]);

  // Debounced change handler
  const handleChange = React.useCallback(
    debounce((newValue: string) => {
      setInternalValue(newValue);
    }, 10),
    [],
  );

  if (readOnlyMode) {
    return (
      <Typography variant="body1" padding={1} fontSize={"0.8rem"}>
        {internalValue || "--"}
      </Typography>
    );
  }

  const handleOnBlur = () => {
    let newEvent: any = {
      target: { id: props.id || props.name, value: internalValue },
    };
    if (props.onChange) props.onChange(newEvent);
  };

  return (
    <CustomTextFieldStyled
      {...rest}
      value={internalValue}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={handleOnBlur}
    />
  );
});

interface CustomNumberFieldProps extends Omit<TextFieldProps, "value"> {
  readOnlyMode?: boolean;
  value?: string | number;
}

export const CustomNumberField: React.FC<CustomNumberFieldProps> = (props) => {
  const { readOnlyMode = false, value, onChange, ...rest } = props;

  // Handle Input Change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // Remove leading zeros
    newValue = newValue.replace(/^0+/, "");

    // Allow only numbers
    if (!/^\d*$/.test(newValue)) return;

    // Fire onChange with a new synthetic event
    if (onChange) {
      onChange({
        ...event,
        target: { ...event.target, value: newValue },
      });
    }
  };

  // Process Initial Value
  const processedValue = useCallback(() => {
    if (!value) return "";
    return value.toString().replace(/^0+/, ""); // Remove leading zeros
  }, [value])();

  // ReadOnly Mode: Display text instead of input
  if (readOnlyMode) {
    return (
      <Typography variant="body1" padding={1} fontSize="0.8rem">
        {value || "--"}
      </Typography>
    );
  }

  return (
    <CustomTextFieldStyled
      {...rest}
      value={processedValue} // Use processed value correctly
      onChange={(event) => {
        if (!props.onChange) return;
        let newValue = event.target.value
          .replace(/[^0-9.]/g, "")
          .replace(/(\..*)\./g, "$1"); // Allow only numbers
        newValue = newValue.replace(/^0+/, ""); // Remove leading zeros
        props.onChange({
          ...event,
          target: { ...event.target, value: newValue },
        }); // Fire onChange with a new synthetic event
      }}
      InputProps={{ inputProps: { min: 1 } }} // Prevents negative values in UI
    />
  );
};
