import { LoadState } from "../../constants/enums";
import {
  IDrawingItems,
  IDrawingItemState,
  IDrawingReferrence,
} from "./drawingItem.types";

export const defaultProductReferrence: IDrawingReferrence = {
  product_code: null,
  product_uuid: null,
};
export const defaultDrawingItem: IDrawingItems = {
  combined_drawing_no: "",
  create_ts: "",
  created_by_uuid: "",
  drawing_prefix: "",
  drawing_item_uuid: "",
  drawing_link: "",
  drawing_name: "",
  assembly_drawing_no: "",
  assembly_drawing_uuid: "",
  drawing_no: "",
  dfx_link: "",
  isChecked: false,
  sod_no: "",
  drawing_references: [],
  insert_ts: "",
  revision_no: 0,
  status: "ACTIVE",
  comment: "",
};

export const defaultDrawingItemState: IDrawingItemState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  drawingItem: {
    loading: LoadState.NotLoaded,
    data: defaultDrawingItem,
    error: null,
  },
};
