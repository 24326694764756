import { LoadState } from "../../constants/enums";
import {
  IGatePass,
  IGatepassState,
  IInvoice,
  IItemDetail,
} from "./gatepass.types";

export const defaultitemDetails: IItemDetail = {
  item_code: "",
  item_quantity: "",
  item_description: "",
  item_uuid: null,
  item_price: null,
  item_total: null,
};

export const defaultInvoiceItem: IInvoice = {
  invoice_uuid: "",
};

export const defaultGatePass: IGatePass = {
  gate_pass_uuid: "",
  gate_pass_type: "RETURNABLE",
  gate_pass_option: null,
  gate_pass_no: "",
  gate_pass_prefix: null,
  branch_name: "",
  customer_name: null,
  customer_uuid: null,
  vendors_name: null,
  vendors_uuid: null,
  item_detail: [defaultitemDetails],
  invoice: [defaultInvoiceItem],
  truck_no: null,
  container_no: null,
  UOM: null,
  combined_gate_pass_no: "",
  total_boxes: null,
  total_packages: null,
  driver_detail: null,
  contact_no: null,
  office_no: null,
  price: null,
  total_amount: null,
  purpose: null,
  approved_by_uuid: null,
  approved_by_name: null,
  modified_by_uuid: null,
  modified_by_name: null,
  return_ts: null,
  created_by_name: "",
  created_by_uuid: "",
  create_ts: "",
  insert_ts: "",
};

export const defaultGatepassState: IGatepassState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_gate_pass: {
    data: defaultGatePass,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
