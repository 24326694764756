import { isNotNull } from "../../helpers/isNotNull";
import {
  IGroupedProformaInvoices,
  IProformaInvoice,
} from "./proformaInvoice.types";

function getGroupedProformaInvoicesbyRecords(
  data: IProformaInvoice[],
): Record<string, Record<string, IProformaInvoice[]>> {
  return data.reduce(
    (result: Record<string, Record<string, IProformaInvoice[]>>, item) => {
      const { enquiry_no, orf_no } = item;

      // If the enquiry_no group doesn't exist, create it
      if (!result[enquiry_no]) {
        result[enquiry_no] = {};
      }

      // If the orf_no group doesn't exist within the enquiry_no, create it
      if (!result[enquiry_no][orf_no]) {
        result[enquiry_no][orf_no] = [];
      }

      // Push the item into the respective group
      result[enquiry_no][orf_no].push(item);

      return result;
    },
    {},
  );
}

export function getGroupedProformaInvoicesbyArray(
  responseData: IProformaInvoice[],
): IGroupedProformaInvoices[] {
  const result: IGroupedProformaInvoices[] = [];

  responseData.forEach((item) => {
    let enquiryGroup = result.find((e) => e.enquiry_no === item.enquiry_no);
    if (!enquiryGroup) {
      enquiryGroup = { enquiry_no: item.enquiry_no, total_amount: 0, orfs: [] };
      result.push(enquiryGroup);
    }

    let orfGroup = enquiryGroup.orfs.find((o) => o.orf_no === item.orf_no);
    if (!orfGroup) {
      orfGroup = {
        enquiry_no: item.enquiry_no,
        orf_no: item.orf_no,
        total_amount: 0,
        proformaInvoices: [],
      };
      enquiryGroup.orfs.push(orfGroup);
    }

    orfGroup.proformaInvoices.push(item);
    orfGroup.total_amount += isNotNull(item.total_amount_after_tax); // Update total amount for ORF
    enquiryGroup.total_amount += isNotNull(item.total_amount_after_tax); // Update total amount for Enquiry
  });

  return result;
}
