import { LoadState } from "../../constants/enums";
import {
  IStoreTransfer,
  IStoreTransferItem,
  IStoreTransferState,
} from "./store-transfer.types";

export const defaultStoreTransferItem: IStoreTransferItem = {
  part_item_code: "",
  part_item_uuid: null,
  transfer_quantity: 0,
  location_to: "",
  location_transfer: null,
  quantity_in_current_location: 0,
  updated_quanity_in_current_location: 0,
  quantity_in_destination_location: 0,
  updated_quantity_in_destination: 0,
  unique_uuid: null,
};

export const defaultStoreTransfer: IStoreTransfer = {
  store_transfer_uuid: "",
  store_transfer_prefix: "",
  store_transfer_no: 0,
  current_store_uuid: null,
  current_store_name: "",
  store_to_uuid: null,
  store_to_name: "",
  branch_name: "",
  items: [defaultStoreTransferItem],
  approved_by_uuid: null,
  approved_by_name: "",
  status: "ACTIVE",
  created_by_uuid: "",
  created_by_name: "",
  modified_by_uuid: "",
  modified_by_name: "",
  create_ts: "",
  insert_ts: "",
  combined_store_transfer_code: "",
};

export const defaultStoreTransferState: IStoreTransferState = {
  store_transfer_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_store_transfer: {
    data: defaultStoreTransfer,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
