import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../../constants/enums";

import { APP_STORE_TRANSFER_ROUTE } from "./StoreTransfer.constants";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { lazy } from "react";

export const SERVER_GET_STORE_TRANSFER_ROUTE = "/warehouse/get-store-transfer";
export const SERVER_UPSERT_STORE_TRANSFER_ROUTE =
  "/warehouse/upsert-store-transfer";

const StoreTransferList = Loadable(
  lazy(() =>
    import("./StoreTransferList").then(({ StoreTransferList }) => ({
      default: StoreTransferList,
    })),
  ),
);
const ManageStoreTransfer = Loadable(
  lazy(() =>
    import("./ManageSingleStoreTransfer").then(
      ({ ManageSingleStoreTransfer }) => ({
        default: ManageSingleStoreTransfer,
      }),
    ),
  ),
);

export const storeTransferRoutes = [
  {
    path: APP_STORE_TRANSFER_ROUTE,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.STORE]}>
        <StoreTransferList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_STORE_TRANSFER_ROUTE}/manage`,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.STORE]}>
        <ManageStoreTransfer />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_STORE_TRANSFER_ROUTE}/manage/:uuid`,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.STORE]}>
        <ManageStoreTransfer />
      </AuthorizedRoute>
    ),
  },
];
