import produce from "immer";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultBatch,
  defaultBatchState,
  defaultVerifyBatch,
} from "./defaultState";
import {
  CLEAR_BATCH,
  CLEAR_BATCH_STATE,
  FETCH_BATCH_FAILED,
  FETCH_BATCH_LIST_FAILED,
  FETCH_BATCH_LIST_PROGRESS,
  FETCH_BATCH_LIST_SUCCESS,
  FETCH_BATCH_PROGRESS,
  FETCH_BATCH_SUCCESS,
  FETCH_VERIFY_BATCH_FAILED,
  FETCH_VERIFY_BATCH_PROGRESS,
  FETCH_VERIFY_BATCH_SUCCESS,
} from "./batch.actions";
import { BatchActions } from "./";

export const BatchReducer = (
  state: IStoreState["batch"] = defaultBatchState,
  action: BatchActions,
) => {
  switch (action.type) {
    case FETCH_BATCH_LIST_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
    }
    case FETCH_BATCH_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      return produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
    }
    case FETCH_BATCH_LIST_FAILED: {
      return produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
    }
    case FETCH_BATCH_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.batch.loading = LoadState.InProgress;
        draftState.batch.data = defaultBatch;
      });
    }
    case FETCH_BATCH_SUCCESS: {
      const { data } = action.payload;
      return produce(state, (draftState) => {
        draftState.batch.loading = LoadState.Loaded;
        draftState.batch.data = data;
      });
    }
    case FETCH_BATCH_FAILED: {
      return produce(state, (draftState) => {
        draftState.batch.loading = LoadState.Failed;
      });
    }
    case FETCH_VERIFY_BATCH_PROGRESS: {
      return produce(state, (draftState) => {
        draftState.verifyBatch.loading = LoadState.InProgress;
        draftState.verifyBatch.data = defaultVerifyBatch;
      });
    }
    case FETCH_VERIFY_BATCH_SUCCESS: {
      const { data } = action.payload;
      return produce(state, (draftState) => {
        draftState.verifyBatch.loading = LoadState.Loaded;
        draftState.verifyBatch.data = data;
      });
    }
    case FETCH_VERIFY_BATCH_FAILED: {
      return produce(state, (draftState) => {
        draftState.batch.loading = LoadState.Loaded;
        // draftState.batch.data = data;
      });
    }
    case CLEAR_BATCH: {
      return produce(state, (draftState) => {
        draftState.batch.loading = LoadState.NotLoaded;
        draftState.batch.data = defaultBatch;
        draftState.batch.error = null;
      });
    }
    case CLEAR_BATCH_STATE: {
      return defaultBatchState;
    }
    default: {
      return state;
    }
  }
};
