import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams, ISearchQueryParams } from "../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  closeLoaderWithMessage,
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { convertToUpdatableFormat } from "./drawing-uploads.helpers";
import {
  IDrawingUpload,
  IDrawingUploadState,
  IOcrJob,
  IUpdateDrawingInfo,
} from "./drawing-uploads.types";

export const FETCH_DRAWING_UPLOAD_LIST_PROGRESS =
  "FETCH_DRAWING_UPLOAD_LIST_PROGRESS";
export const FETCH_DRAWING_UPLOAD_LIST_SUCCESS =
  "FETCH_DRAWING_UPLOAD_LIST_SUCCESS";
export const FETCH_DRAWING_UPLOAD_LIST_FAILED =
  "FETCH_DRAWING_UPLOAD_LIST_FAILED";

export const fetchDrawingUploadListProgress = () =>
  action(FETCH_DRAWING_UPLOAD_LIST_PROGRESS);

export const fetchDrawingUploadListSuccess = (
  data: IDrawingUploadState["drawing_upload_list"]["data"],
  totalRecords: number,
) => action(FETCH_DRAWING_UPLOAD_LIST_SUCCESS, { data, totalRecords });
export const fetchDrawingUploadListFailed = () =>
  action(FETCH_DRAWING_UPLOAD_LIST_FAILED);

const modifyOcrJobArr = (arr: any[]) => {
  return arr.map((item) => {
    const { create_ts, accuracy_description, accuracy_drawing_no, ...rest } =
      item;

    // create_ts ===> create_date, create_time
    const date = new Date(create_ts);
    const create_date = date.toISOString().split("T")[0];
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    const create_time = `${hours}:${minutes}:${seconds} ${ampm}`;

    // accuracy fields ==> to percentage
    const accuracy_description_percent =
      accuracy_description !== null
        ? (accuracy_description * 100).toFixed(2)
        : 0;

    const accuracy_drawing_no_percent =
      accuracy_drawing_no !== null ? (accuracy_drawing_no * 100).toFixed(2) : 0;

    return {
      ...rest,
      create_date,
      create_time,
      accuracy_description: accuracy_description_percent,
      accuracy_drawing_no: accuracy_drawing_no_percent,
    };
  });
};

export const fetchDrawingUploadListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchDrawingUploadListProgress());
      const res = await api.get(`/bom/get-ocr-job?${searchQuery}`);
      const data: IDrawingUploadState["drawing_upload_list"]["data"] =
        res.data.data;
      const totalRecords = res.data.totalRecords;
      const finalData = modifyOcrJobArr(data);
      const uniqueArray = finalData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.transaction_uuid === item.transaction_uuid),
      );
      dispatch(fetchDrawingUploadListSuccess(uniqueArray, totalRecords));
    } catch (err: any) {
      dispatch(fetchDrawingUploadListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_DRAWING_UPLOAD_PROGRESS = "FETCH_DRAWING_UPLOAD_PROGRESS";
export const FETCH_DRAWING_UPLOAD_SUCCESS = "FETCH_DRAWING_UPLOAD_SUCCESS";
export const FETCH_DRAWING_UPLOAD_FAILED = "FETCH_DRAWING_UPLOAD_FAILED";

export const fetchDrawingUploadProgress = () =>
  action(FETCH_DRAWING_UPLOAD_PROGRESS);
export const fetchDrawingUploadSuccess = (data: IOcrJob[]) =>
  action(FETCH_DRAWING_UPLOAD_SUCCESS, { data });
export const fetchDrawingUploadFailed = (errorMessage: string) =>
  action(FETCH_DRAWING_UPLOAD_FAILED, { errorMessage });

export const fetchDrawingUploadAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDrawingUploadProgress());
      const res = await api.get(`/bom/get-ocr-job?transaction_uuid=${uuid}`);
      const data: IOcrJob[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDrawingUploadSuccess(data));
      } else {
        dispatch(
          fetchDrawingUploadFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchDrawingUploadFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const uploadDrawingFileAsync =
  (
    { file, sodNo }: { file: File; sodNo: string },
    onCallback: (isSuccess: boolean, data?: IUpdateDrawingInfo) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("sod_no", sodNo);
      // dispatch(openLoaderWithMessage("File is being processed"));
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message:
            "File upload in progress. You will be notified once it's done",
        }),
      );
      const res = await api.post("/bom/upload-drawing-file", formData);
      let message = "Drawing Processed successfully!";
      onCallback(true, convertToUpdatableFormat(res.data.data));
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(closeLoaderWithMessage());
    }
  };

export const upsertDrawingItemsAsync =
  (
    payload: IUpdateDrawingInfo,
    onCallback: (isSuccess: boolean, data?: IUpdateDrawingInfo) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const drawingItems = payload.drawing_items.map((item) => {
        const { dr_no_accuracy, description_accuracy, ...restItem } = item;
        return restItem;
      });
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/renamed-drawing-files", {
        drawing_items: drawingItems,
      });
      let message = "Drawing Updated successfully!";
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_DRAWING_UPLOAD = "CLEAR_DRAWING_UPLOAD";
export const CLEAR_DRAWING_UPLOAD_STATE = "CLEAR_DRAWING_UPLOAD_STATE";
export const clearDrawingUploadSync = () => action(CLEAR_DRAWING_UPLOAD);
export const clearDrawingUploadStateSync = () =>
  action(CLEAR_DRAWING_UPLOAD_STATE);
