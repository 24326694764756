import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import {
  PRODUCT_FIBER_FINISH_ROUTE,
  PRODUCT_METAL_FINISH_ROUTE,
} from "../Product.constants";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";

const FiberFinishTableView = Loadable(
  lazy(() =>
    import("./FiberFinishTableView").then(({ FiberFinishTableView }) => ({
      default: FiberFinishTableView,
    })),
  ),
);

export const productsFiberFinishRoutes = [
  {
    path: PRODUCT_FIBER_FINISH_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.FIBER_FINISH]}>
        <FiberFinishTableView />
      </AuthorizedRoute>
    ),
  },
];
