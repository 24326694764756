import { LoadState } from "../../constants/enums";
import {
  IPartItem,
  IPartItemState,
  IProductReferrence,
} from "./partItem.types";

export const defaultProductReferrence: IProductReferrence = {
  product_code: null,
  product_uuid: null,
};
export const defaultPartItem: IPartItem = {
  part_item_uuid: null,
  unit_of_measurement: "",
  description: "",
  tolerance: "",
  comment: "",
  sod_no: "",
  part_references: [],
  origin: "DOMESTIC",
  drawing_no: "",
  part_code_prefix: "",
  isChecked: false,
  part_name: "",
  part_type: "",
  status: "ACTIVE",
};

export const defaultPartItemState: IPartItemState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  partItem: {
    loading: LoadState.NotLoaded,
    data: defaultPartItem,
    error: null,
  },
};
