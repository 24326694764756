import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import { showMessage } from "../../messages/messagesActions";
import { action } from "typesafe-actions";
import { IEnquiryCheckList } from "./enquiry-checklist.types";

export const FETCH_ENQUIRY_CHECKLIST_PROGRESS =
  "FETCH_ENQUIRY_CHECKLIST_PROGRESS";
export const FETCH_ENQUIRY_CHECKLIST_SUCCESS =
  "FETCH_ENQUIRY_CHECKLIST_SUCCESS";
export const FETCH_ENQUIRY_CHECKLIST_FAILED = "FETCH_ENQUIRY_CHECKLIST_FAILED";

export const fetchEnquiryCheckListProgress = () =>
  action(FETCH_ENQUIRY_CHECKLIST_PROGRESS);
export const fetchEnquiryCheckListSuccess = (
  list: IEnquiryCheckList[],
  totalRecords?: number,
) => action(FETCH_ENQUIRY_CHECKLIST_SUCCESS, { list, totalRecords });
export const fetchEnquiryCheckListFailed = (errorMessage: string) =>
  action(FETCH_ENQUIRY_CHECKLIST_FAILED, { errorMessage });

export const fetchEnquiryCheckListAsync =
  (orf_uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchEnquiryCheckListProgress());
      const res = await api.get(
        `/enquiry/get-enquiry-checklist?orf_uuid=${orf_uuid}`,
      );
      const data: IEnquiryCheckList[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchEnquiryCheckListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchEnquiryCheckListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertEnquiryCheckListAsync =
  (
    changedValues: IEnquiryCheckList[],
    onCallback: (isSuccess: boolean) => void,
    checkListData?: IEnquiryCheckList[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      if (checkListData) {
        const updatedChecklist = checkListData.map((existingItem) => {
          const changedItemIndex = changedValues.findIndex(
            (item) =>
              item.enquiry_checklist_uuid ===
              existingItem.enquiry_checklist_uuid,
          );
          if (changedItemIndex !== -1) {
            return changedValues[changedItemIndex];
          }
          return existingItem;
        });
        dispatch(fetchEnquiryCheckListSuccess(updatedChecklist));
      }

      for (let i = 0; i < changedValues.length; i++) {
        let { insert_ts, create_ts, ...payload } = changedValues[i];
        await api.post(`/enquiry/upsert-enquiry-checklist`, payload);
      }

      dispatch(
        showMessage({
          type: "success",
          message: "Enquiry checklist saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleEnquiryCheckListItem =
  (
    changedItem: IEnquiryCheckList,
    checkListData: IEnquiryCheckList[],
    onCallback: (isSuccess: boolean, changedItem?: IEnquiryCheckList) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = changedItem;
      const updatedChecklist = [];
      for (const item of checkListData) {
        if (
          item.enquiry_checklist_type_uuid ===
          changedItem.enquiry_checklist_type_uuid
        ) {
          updatedChecklist.push(changedItem);
        } else {
          updatedChecklist.push(item);
        }
      }

      dispatch(fetchEnquiryCheckListSuccess(updatedChecklist));

      const res = await api.post(`/enquiry/upsert-enquiry-checklist`, payload);
      const data = res.data.data;
      dispatch(
        showMessage({
          type: "success",
          message: "Enquiry checklist item saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ENQUIRY_CHECKLIST = "CLEAR_ENQUIRY_CHECKLIST";
export const clearEnquiryCheckList = () => action(CLEAR_ENQUIRY_CHECKLIST);
