import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_BATCH_ROUTE } from "./Batch.constants";

const BatchList = Loadable(
  lazy(() =>
    import("./BatchList").then(({ BatchList }) => ({
      default: BatchList,
    })),
  ),
);

const ManageBatch = Loadable(
  lazy(() =>
    import("./ManageBatch").then(({ ManageBatch }) => ({
      default: ManageBatch,
    })),
  ),
);

export const batchRoutes = [
  {
    path: APP_BATCH_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BATCH]}>
        <BatchList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_BATCH_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BATCH]}>
        <ManageBatch />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_BATCH_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BATCH]}>
        <ManageBatch />
      </AuthorizedRoute>
    ),
  },
];
