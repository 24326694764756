export const approvalPath = {
  approval: {
    bom: "/approval/bom",
    costingSheet: {
      costingSheet: "/approval/costing-sheet",
      costingSheetRaise: "/approval/costing-sheet-raise",
      costingSheetAcknowledgement: "/approval/costing-sheet-acknowledgement",
    },
    orf: "/approval/orf",
    item: "/approval/item",
    drawing: "/approval/drawing",
    masterBom: "/approval/master-bom",
    purchaseOrder: "/approval/purchase-order",
    purchaseRequisition: "/approval/purchase-requisition",
    mrpAllocation: "/approval/mrp-allocations",
    routing: "/approval/routing",
    quote: "/approval/quote",
  },
};
