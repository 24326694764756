import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import {
  PRODUCT_ACRYLIC_FINISH_ROUTE,
  PRODUCT_WOOD_FINISH_ROUTE,
} from "../Product.constants";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";

const AcrylicFinishTableView = Loadable(
  lazy(() =>
    import("./AcrylicFinishTableView").then(({ AcrylicFinishTableView }) => ({
      default: AcrylicFinishTableView,
    })),
  ),
);

export const productsAcrylicFinishRoutes = [
  {
    path: PRODUCT_ACRYLIC_FINISH_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.ACRYLIC_FINISH]}>
        <AcrylicFinishTableView />
      </AuthorizedRoute>
    ),
  },
];
