import { LoadState } from "../../constants/enums";
import { defaultProductAcrylicFinishState } from "./acrylic-finish";
import { defaultProductFiberFinishState } from "./fiber-finish";
import { defaultProductMetalFinishState } from "./metal-finish/metal-finish.state";
import { defaultProductAddOnState } from "./product-addon";
import { defaultProductTemplateState } from "./product-templates";
import { IProduct, IProductsState } from "./products.types";
import { defaultProductWoodFinishState } from "./wood-finish";

export const defaultProduct: IProduct = {
  product_uuid: "",
  product_name: "Default Product",
  costing_sheet_for: "STANDARD",
  creation_costing_sheet_uuid: null,
  layout_location: "",
  fabric_colour: "",
  metal: "",
  wood: "",
  glass: "",
  acrylic: "",
  polycarbonate: "",

  product_code: "",
  product_code_prefix: "",
  product_code_suffix: null,
  product_description: "",
  eni_no: "",
  product_height: null,
  product_depth: null,
  product_width: null,
  quantity: null,
  product_price: 0,
  total: null,
  weight: null,
  total_weight: null,
  volume_m3: null,
  total_volume_m3: null,
  category: [],
  design: null,
  status: "ACTIVE",
  expiry_date: "",
  currency: null,
  area: null,
};

export const defaultProductsState: IProductsState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  product: defaultProduct,
  productLoading: LoadState.NotLoaded,
  error: null,
  ...defaultProductTemplateState,
  ...defaultProductAddOnState,
  ...defaultProductMetalFinishState,
  ...defaultProductWoodFinishState,
  ...defaultProductAcrylicFinishState,
  ...defaultProductFiberFinishState,
};
