import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

import { IQueryParams, ISearchQueryParams } from "../../common/common.types";
import { getSearchQuery, getSearchQueryV2 } from "../../common/helpers";
import { IApprovedOrder, IOrder } from "./orders.types";
import { IHandleApproval } from "../../approval/quote";
import { IOrderApprovedComment } from "../../../views/sales/ApprovedOrders/components/OrderComment/OrderComment";

//****************************** Fetch Single Comment ******************************//
export const FETCH_ORDERS_LIST_PROGRESS = "FETCH_ORDERS_LIST_PROGRESS";
export const FETCH_ORDERS_LIST_SUCCESS = "FETCH_ORDERS_LIST_SUCCESS";
export const FETCH_ORDERS_LIST_FAILED = "FETCH_ORDERS_LIST_FAILED";

export const fetchOrdersListProgress = () => action(FETCH_ORDERS_LIST_PROGRESS);
export const fetchOrdersListSuccess = (data: IOrder[], count: number) =>
  action(FETCH_ORDERS_LIST_SUCCESS, { data, count });
export const fetchOrdersListFailed = (errorMessage: string) =>
  action(FETCH_ORDERS_LIST_FAILED, { errorMessage });

export const fetchOrdersListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchOrdersListProgress());
      const res = await api.get(`/enquiry/get-orders${searchQuery}`); // need to be replaced
      const data = res.data.data.result;
      dispatch(fetchOrdersListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchOrdersListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//****************************** Fetch Single Comment ******************************//
export const FETCH_SINGLE_ORDER_PROGRESS = "FETCH_SINGLE_ORDER_PROGRESS";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_SINGLE_ORDER_FAILED = "FETCH_SINGLE_ORDER_FAILED";

export const fetchSingleOrderProgress = () =>
  action(FETCH_SINGLE_ORDER_PROGRESS);
export const fetchSingleOrderSuccess = (data: IOrder) =>
  action(FETCH_SINGLE_ORDER_SUCCESS, data);
export const fetchSingleOrderFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_ORDER_FAILED, { errorMessage });

export const fetchSingleOrderAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleOrderProgress());
      const res = await api.get(`/enquiry/get-orders?order_uuid=${uuid}`); // need to be replaced
      const data: IOrder[] = res.data.data;
      dispatch(fetchSingleOrderSuccess(data[0]));
    } catch (err: any) {
      dispatch(fetchSingleOrderFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_ORDERS_LIST = "CLEAR_ORDERS_LIST";
export const clearMultipleOrdersListSync = () => action(CLEAR_ORDERS_LIST);

export const CLEAR_SINGLE_ORDER = "CLEAR_SINGLE_ORDER";
export const clearSingleOrderSync = () => action(CLEAR_SINGLE_ORDER);

export interface IHandleApprovalForOrder {
  approval_uuid: string | null;
  remark: string;
  status: "ROLLBACK" | "APPROVED" | "REJECTED";
  pi_info: {
    proforma_invoice_uuid: string;
    sod_type: string | null;
    target_dispatch_date: string | null;
  }[];
}

export const handleOrderApprovalAsync =
  (
    payload: IHandleApprovalForOrder,
    onCallback: (isSuccess: boolean, data?: IHandleApprovalForOrder) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post("/enquiry/approve-order", payload);
      let message = "Action success!";
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: res.data.message ? res.data.message : message,
        }),
      );
      const responseData = res.data.data;
      onCallback(true, responseData);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const createOrderRevisionAsync =
  (
    order_uuid: string | null,
    combined_sod_no: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/enquiry/create-order-revision", {
        order_uuid,
        combined_sod_no,
      });
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "PI Revised successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//****************************** Fetch Single Comment ******************************//
export const FETCH_APPROVED_ORDERS_LIST_PROGRESS =
  "FETCH_APPROVED_ORDERS_LIST_PROGRESS";
export const FETCH_APPROVED_ORDERS_LIST_SUCCESS =
  "FETCH_APPROVED_ORDERS_LIST_SUCCESS";
export const FETCH_APPROVED_ORDERS_LIST_FAILED =
  "FETCH_APPROVED_ORDERS_LIST_FAILED";

export const fetchApprovedOrdersListProgress = () =>
  action(FETCH_APPROVED_ORDERS_LIST_PROGRESS);
export const fetchApprovedOrdersListSuccess = (
  data: IApprovedOrder[],
  count: number,
) => action(FETCH_APPROVED_ORDERS_LIST_SUCCESS, { data, count });
export const fetchApprovedOrdersListFailed = (errorMessage: string) =>
  action(FETCH_APPROVED_ORDERS_LIST_FAILED, { errorMessage });

export const fetchApprovedOrdersListAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchApprovedOrdersListProgress());
      const res = await api.get(
        `/enquiry/get-approved-orders-stage-wise-status?${searchQuery}`,
      ); // need to be replaced
      const data = res.data.data;
      dispatch(fetchApprovedOrdersListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovedOrdersListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const setApprovedOrderPriorityAsync =
  (
    payload: {
      approved_order_uuid: string;
      priority: string;
    },
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/enquiry/set-approved-order-priority", payload);
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Priority updated successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
export const upsertOrderApprovedComment =
  (
    payload: IOrderApprovedComment,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/enquiry/add-comment-for-approved-order", payload);
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          message: "Comment updated successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
