import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  closeLoaderWithMessage,
  openLoaderWithMessage,
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IGroupedMr,
  IMaterialRequest,
  IMaterialRequestState,
} from "./material-request.types";
import {
  SERVER_GET_MATERIAL_REQUEST_ROUTE,
  SERVER_UPSERT_ISSUED_QTY_ROUTE,
  SERVER_UPSERT_MATERIAL_REQUEST_ROUTE,
} from "./material-request.constants";
import { IIssuedQty } from "../../views/MaterialRequests/component/IssuedQtyTextField/IssuedQtyTextField";
import { IGroupedBom } from "../bom/bom.types";
import { IBom } from "../bom/bom.types";

export const FETCH_MATERIAL_REQUEST_LIST_PROGRESS =
  "FETCH_MATERIAL_REQUEST_LIST_PROGRESS";
export const FETCH_MATERIAL_REQUEST_LIST_SUCCESS =
  "FETCH_MATERIAL_REQUEST_LIST_SUCCESS";
export const FETCH_MATERIAL_REQUEST_LIST_FAILED =
  "FETCH_MATERIAL_REQUEST_LIST_FAILED";

export const fetchMaterialRequestListProgress = () =>
  action(FETCH_MATERIAL_REQUEST_LIST_PROGRESS);

export const fetchMaterialRequestListSuccess = (
  data: IMaterialRequestState["material_request_list"]["data"],
  totalRecords: number,
) => action(FETCH_MATERIAL_REQUEST_LIST_SUCCESS, { data, totalRecords });
export const fetchMaterialRequestListFailed = () =>
  action(FETCH_MATERIAL_REQUEST_LIST_FAILED);

const execGroupData = (data: IMaterialRequest[]): IGroupedMr[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.batch_no]) {
        //@ts-ignore
        acc[obj.batch_no] = {
          id: obj.batch_no,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.batch_no].childs.push(obj);
      }
      return acc;
    }, {}),
  );

  return groupedData as IGroupedMr[];
};

export const fetchMaterialRequestListAsync =
  (
    queryParams: IQueryParams,
    origin?: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      let finalUrl = `${SERVER_GET_MATERIAL_REQUEST_ROUTE}${searchQuery}`;
      dispatch(fetchMaterialRequestListProgress());
      const res = await api.get(finalUrl);
      const data: IMaterialRequestState["material_request_list"]["data"] =
        res.data.data;
      const finalData = execGroupData(data);

      const totalRecords = res.data.totalRecords;

      dispatch(fetchMaterialRequestListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchMaterialRequestListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_MATERIAL_REQUEST_PROGRESS =
  "FETCH_MATERIAL_REQUEST_PROGRESS";
export const FETCH_MATERIAL_REQUEST_SUCCESS = "FETCH_MATERIAL_REQUEST_SUCCESS";
export const FETCH_MATERIAL_REQUEST_FAILED = "FETCH_MATERIAL_REQUEST_FAILED";

export const fetchMaterialRequestProgress = () =>
  action(FETCH_MATERIAL_REQUEST_PROGRESS);
export const fetchMaterialRequestSuccess = (data: IMaterialRequest) =>
  action(FETCH_MATERIAL_REQUEST_SUCCESS, { data });
export const fetchMaterialRequestFailed = (errorMessage: string) =>
  action(FETCH_MATERIAL_REQUEST_FAILED, { errorMessage });

export const fetchSingleMaterialRequestAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchMaterialRequestProgress());
      const res = await api.get(
        `${SERVER_GET_MATERIAL_REQUEST_ROUTE}?mr_uuid=${uuid}`,
      );
      const data: IMaterialRequest[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchMaterialRequestSuccess(data[0]));
      } else {
        dispatch(
          fetchMaterialRequestFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchMaterialRequestFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleMaterialRequestAsync =
  (
    order_no: string,
    onCallback: (isSuccess: boolean, mr?: IMaterialRequest) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(openLoaderWithMessage("Requesting New MR Request...!"));
      const res = await api.post(SERVER_UPSERT_MATERIAL_REQUEST_ROUTE, {
        order_no,
      });
      let message = "MR saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(closeLoaderWithMessage());
    }
  };
export const upsertIssuedQtyAsync =
  (
    data: IIssuedQty,
    onCallback: (isSuccess: boolean, mr?: IIssuedQty) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(openLoaderWithMessage("Requesting New MR Request...!"));
      const res = await api.post(SERVER_UPSERT_ISSUED_QTY_ROUTE, data);
      let message = "Issued Quantity saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(closeLoaderWithMessage());
    }
  };

export const CLEAR_MATERIAL_REQUEST = "CLEAR_MATERIAL_REQUEST";
export const CLEAR_MATERIAL_REQUEST_STATE = "CLEAR_MATERIAL_REQUEST_STATE";
export const clearSingleMaterialRequestSync = () =>
  action(CLEAR_MATERIAL_REQUEST);
export const clearMaterialRequestsState = () =>
  action(CLEAR_MATERIAL_REQUEST_STATE);
