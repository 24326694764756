import { String } from "lodash";
import { LoadState } from "../../constants/enums";
import {
  IInternalOrderBom,
  IInternalOrderBomState,
} from "./internal-orders-bom.types";

export const defaultInternalOrderBom: IInternalOrderBom = {
  internal_order_bom_uuid: null,
  dtz_bom_product_code: null,
  dtz_bom_uuid: null,
  dtz_part_code: "",
  dtz_part_code_uuid: "",
  sez_part_code: null,
  sez_part_code_uuid: null,
  sez_bom_uuid: null,
  status: "ACTIVE",
};

export const defaultInternalOrderBomState: IInternalOrderBomState = {
  multiple_internal_orders_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_internal_order_bom: {
    data: defaultInternalOrderBom,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
