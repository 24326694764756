import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_INTERNAL_ORDER_ROUTE } from "./InternalOrderBom.constants";
import { appNavRoutes } from "../../constants/appNavRoutes";

const InternalOrderBomTableView = Loadable(
  lazy(() =>
    import("./InternalOrderBomTableView").then(
      ({ InternalOrderBomTableView }) => ({
        default: InternalOrderBomTableView,
      }),
    ),
  ),
);

const ManageSingleInternalOrderBom = Loadable(
  lazy(() =>
    import("./ManageSingleInternalOrderBom").then(
      ({ ManageSingleInternalOrderBom }) => ({
        default: ManageSingleInternalOrderBom,
      }),
    ),
  ),
);

export const internalOrderBomRoutes = [
  {
    path: appNavRoutes.internal_orders.internal_order_bom,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.INTERNAL_ORDER_BOM]}>
        <InternalOrderBomTableView />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${appNavRoutes.internal_orders.internal_order_bom}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.INTERNAL_ORDER_BOM]}>
        <ManageSingleInternalOrderBom />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${appNavRoutes.internal_orders.internal_order_bom}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.INTERNAL_ORDER_BOM]}>
        <ManageSingleInternalOrderBom />
      </AuthorizedRoute>
    ),
  },
];
