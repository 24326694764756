import { IDate } from "../../components/Table/hooks/useDateFilter";
import { LoadState } from "../../constants/enums";

export interface ICommonState {
  policies: {
    list: IPolicies[];
    loadState: LoadState;
  };
  generalHistory: {
    list: IGeneralHistory[];
    loading: LoadState;
  };
  recordCounts: {
    list: IRecordCount[];
    loading: LoadState;
  };
  routingProcessTime: {
    list: IRoutingProcessTime[];
    loading: LoadState;
  };
}

export interface IRecordCount {
  status: string;
  count: number;
}

export interface IMatchedColumn {
  tableName: string;
  columnName: string;
  matchedValue: string;
}

//routing process time
export interface IRoutingProcessTime {
  routing_process: string;
  line: string;
  line_stage: number;
  total_remaining_time: number;
  process_count: number;
}

export interface IPolicies {
  type: string;
  subTypes: {
    policy_id: number;
    name: string;
  }[];
}

export interface IGeneralHistory {
  insert_ts: string;
  [key: string]: any;
}

export const defaultCommonState: ICommonState = {
  policies: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  generalHistory: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  recordCounts: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  routingProcessTime: {
    list: [],
    loading: LoadState.NotLoaded,
  },
};

export interface IMail {
  emails: string[];
  subject: string;
  body: string;
  templateName: string | null;
  objectVariables: any;
  cc: string[];
  bcc: string[];
  reply_to?: {
    email: string;
    name: string;
  };
  attachments?: {
    content: string;
    filename: string;
  }[];
}

export interface IQueryParams {
  status: string;
  page: number;
  rowsPerPage: number;
  columns: string[];
  value: string;
  fromDate?: string;
  toDate?: string;
}

export interface ISearchQueryParams {
  status?: string;
  page: number;
  rowsPerPage: number;
  limit: number;
  columns: string[];
  value: string;
  date?: IDate;
  advancedFilters?: IDatatableV2AdvancedSearchFilter;
}

export type IActiveStatus = "ACTIVE" | "INACTIVE";

export interface IDataTableV2SearchFilterSearchItem {
  column: string[];
  operator: string;
  value: string;
  logicalOperator: "AND" | "OR";
}

export type IDatatableV2AdvancedSearchFilter =
  IDataTableV2SearchFilterSearchItem[];
