import { LoadState } from "../../constants/enums";
import { IPriceUpdation, IPriceUpdationState } from "./priceUpdation.types";

export const defaultPriceUpdation: IPriceUpdation = {
  price_updation_uuid: "",
  price_updation_prefix: "",
  part_item_uuid: "",
  part_item_code: "",
  price_updation_code: "",
  running_no: 0,
  description: "",
  minimum_order_quantity: 0,
  vendors_name: "",
  vendors_uuid: null,
  payment_term: [{ key: null, value: null }],
  price: null,
  remarks: null,
  currency: "",
  attachments: [],
  updated_price: null,
  updated_currency: "",
  branch_name: "Bangalore",
  updated_date: "",
  updated_minimum_order_quantity: null,
  updated_vendors_name: "",
  updated_vendors_uuid: null,
  updated_payment_term: [{ key: null, value: null }],
  status: "ACTIVE",
  created_by_uuid: "",
  created_by_name: "",
  modified_by_uuid: "",
  modified_by_name: "",
  create_ts: "",
  insert_ts: "",
  combined_part_code: "",
};

export const defaultPriceUpdationState: IPriceUpdationState = {
  list: {
    data: [],
    totalRecord: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  priceUpdation: {
    data: defaultPriceUpdation,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
